import { Box, Tab, Tabs } from '@mui/material'
import HeaderTwoRows from 'components/Header/variants/HeaderTwoRows'
import React from 'react'
import { Party } from 'types/party'

function a11yProps(index: number) {
  return {
    'aria-controls': `simple-tabpanel-${index}`,
    id: `simple-tab-${index}`,
  }
}

const HeaderParty = ({
  allowedQuestionnaire,
  handleChange,
  party,
  tabsValue,
}: {
  allowedQuestionnaire: boolean
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  party: Party
  tabsValue: number
}) => {
  const row2 = (
    <Box ml={2}>
      <Tabs aria-label="Tabs parties" value={tabsValue} onChange={handleChange}>
        <Tab label="Information" {...a11yProps(0)} />
        {allowedQuestionnaire && (
          <Tab label="Maturité numérique" {...a11yProps(1)} />
        )}
      </Tabs>
    </Box>
  )

  return (
    <HeaderTwoRows
      arrowBack
      row1LeftDescription={party.category}
      row1LeftTitle={party.name}
      row2={row2}
    />
  )
}

export default HeaderParty
