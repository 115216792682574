import type { SelectChangeEvent } from '@mui/material'

import { Grid } from '@mui/material'
import { find } from 'lodash'
import {
  questionnaireState,
  questionnaireStateSelector,
} from 'pages/Core/Questionnaire/atoms'
import * as React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAxios } from 'services/axios.service'
import { Questionnaire } from 'types/questionnaire'

import Chart from './Chart'
import Progress from './Progress/index'

const Maturity = ({ partyId }: { partyId: string }) => {
  //They are best to do this whith recoil
  const setQuestionnaireState = useSetRecoilState(questionnaireState)
  const currQuestionnaireState = useRecoilValue(questionnaireStateSelector)
  const [currQuestionnaire, setCurrQuestionnaire] =
    React.useState<Questionnaire | null>(currQuestionnaireState)

  const { loading: loadingQuestionnaire, response: questionnaires } = useAxios({
    url: `/questionnaires`,
  })

  const { refresh: refreshInfos, response: infos } = useAxios({
    noFetchInit: true,
    url: `questionnaires/${currQuestionnaire?.id}/parties/${partyId}/infos`,
  })

  const handleChangeQuestionnaire = (event: SelectChangeEvent) => {
    const newQuestionnaire = find(questionnaires, {
      id: event.target.value,
    }) as Questionnaire
    setCurrQuestionnaire(newQuestionnaire)
    setQuestionnaireState(newQuestionnaire)
  }

  React.useEffect(() => {
    if (!loadingQuestionnaire && questionnaires && !currQuestionnaire) {
      setCurrQuestionnaire(questionnaires[0])
      setQuestionnaireState(questionnaires[0])
    }
  }, [loadingQuestionnaire])

  React.useEffect(() => {
    if (currQuestionnaire?.id) {
      refreshInfos()
    }
  }, [currQuestionnaire?.id])

  return (
    <Grid container justifyContent="center" pl={2} pr={2} spacing={3}>
      {!loadingQuestionnaire && questionnaires && currQuestionnaire && (
        <>
          <Grid item sx={{ maxWidth: 360, width: 360 }}>
            <Progress
              currQuestionnaireId={currQuestionnaire?.id}
              infos={infos}
              partyId={partyId}
              questionnaires={questionnaires}
              handleChangeQuestionnaire={handleChangeQuestionnaire}
            />
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Chart
              currQuestionnaireId={currQuestionnaire?.id}
              infos={infos}
              partyId={partyId}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default Maturity
