import {
  Button,
  Checkbox,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import * as React from 'react'

import type { selectOptions } from './type'

import { IconCheckbox } from '.'

const SelectSelections = ({
  onChangeSelectionSelect,
  optionsSelected,
  setTabIndex,
}: {
  onChangeSelectionSelect: any
  optionsSelected: selectOptions[]
  setTabIndex: any
}) => {
  return (
    <>
      {optionsSelected?.map((option: selectOptions, i: number) => (
        <MenuItem
          selected
          key={option.value + i}
          sx={{ p: 0 }}
          onClick={() => {
            onChangeSelectionSelect([option])
            optionsSelected.length === 1 && setTabIndex(0)
          }}
        >
          <Checkbox
            checked={true}
            checkedIcon={IconCheckbox}
            icon={IconCheckbox}
            style={{ marginRight: 8 }}
          />
          <Tooltip placement="right-end" title={option.label}>
            <Typography noWrap sx={{ width: '80%' }}>
              {option.label}
            </Typography>
          </Tooltip>
        </MenuItem>
      ))}
      {optionsSelected.length > 0 && (
        <Stack m={1} mt={2}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={() => {
              onChangeSelectionSelect(optionsSelected, true)
              setTabIndex(0)
            }}
          >
            Tout déselectionner
          </Button>
        </Stack>
      )}
    </>
  )
}
export default SelectSelections
