import type { MapRef, ViewState } from 'react-map-gl'

import LegendControl, { LegendControlOptions } from 'mapboxgl-legend'
import { mapHoverInfoState } from 'pages/Core/atoms'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import MapGL from 'react-map-gl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { getHeadersAuth } from 'services/axios.service'

import ControlPanel from './ControlPanel'
import MAP_STYLE from './mapStyle'
import './style.css'

const legendOptions = {
  layers: {
    apl: {
      attributes: ['fill-color'],
      highlight: false,
    },
  },
} as LegendControlOptions

const initialViewState = {
  latitude: 46.75,
  longitude: 2.85,
  zoom: 5,
} as ViewState

const MapDiagnostic = ({ ifAreaSelected }: { ifAreaSelected: boolean }) => {
  const mapRef = useRef<MapRef>(null)

  const [viewState, setViewState] = useState(initialViewState)
  const refLegend = useRef(new LegendControl(legendOptions))

  useEffect(() => {
    if (!ifAreaSelected) {
      setViewState(initialViewState)
    }
  }, [ifAreaSelected])

  mapRef.current?.getMap().addControl(refLegend.current, 'bottom-right')

  const hoverInfo = useRecoilValue(mapHoverInfoState)
  const setHoverInfo = useSetRecoilState(mapHoverInfoState)
  const [mapStyle, setMapStyle] = React.useReducer(
    (state: any, updates: any) => ({ ...state, ...updates }),
    MAP_STYLE,
  )

  const onMouseMove = useCallback((event: any) => {
    const geo = event.features && event.features[0]

    if (geo?.layer?.id) {
      const { lat, lng } = event.lngLat
      setHoverInfo({
        coordinates: [lng, lat],
        layer: geo?.layer?.id,
        properties: geo?.properties,
      })
    }
  }, [])

  const onMouseLeave = useCallback(() => {
    setHoverInfo({})
  }, [])

  const interactiveLayerIds = ['parties-selected', 'apl']
  return (
    <MapGL
      {...viewState}
      doubleClickZoom
      attributionControl={false}
      cursor="auto"
      interactiveLayerIds={interactiveLayerIds}
      mapboxAccessToken="pk.eyJ1IjoiYnNvZ2hpZ2lhbiIsImEiOiJjazBhOTUxam4wMDNtM2RvNXJzbjQ5aGV6In0.eL8NJ0-ikx_5Dl49994bGw"
      mapStyle={mapStyle || undefined}
      minZoom={3}
      ref={mapRef}
      style={{ height: '100%', width: '100%' }}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onMove={(evt) => setViewState(evt.viewState)}
      transformRequest={(url, resourceType) => {
        if (
          resourceType === 'Tile' &&
          !url.startsWith('https://api.mapbox.com')
        ) {
          return {
            headers: getHeadersAuth(),
            url: url,
          }
        }
        return { url }
      }}
    >
      <ControlPanel
        hoverInfo={hoverInfo}
        mapRef={mapRef}
        setMapStyle={setMapStyle}
      />
    </MapGL>
  )
}

export default MapDiagnostic
