import { Menu, MenuItem, MenuList, Paper, Stack } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { ReactComponent as LogoFullLight } from 'assets/images/logoFullLight.svg'
import axios from 'axios'
import IconSvg from 'components-ui/IconSvg'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CircleUserIcon,
  FileTextIcon,
  KanbanSquareIcon,
  LogOutIcon,
  MessageSquareMore,
  SearchIcon,
  WrenchIcon,
} from 'lucide-react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import authService from 'services/auth.service'
import permissionsService, {
  ALLOWED_ACTION_MANAGE_ROLE,
  ALLOWED_ACTION_MANAGE_USER,
  ALLOWED_ACTION_READ_DIAGNOSTIC,
  ALLOWED_ACTION_READ_PROJECT,
  ALLOWED_ACTION_READ_USER,
} from 'services/permissions.service'
import primary from 'themes/colors/primary'

import packageJson from '../../../../../package.json'
import { FooterStyled, ListItemStyled, LogoContainer } from './styles'

export const drawerWidth = 250

export default function MenuFullMain({
  openDrawerFeedback,
}: {
  openDrawerFeedback: any
}) {
  const [versionApi, setVersionApi] = React.useState('loading')
  const navigate = useNavigate()
  const user = authService.getCurrentUser()

  const canReadUser = permissionsService.getPermission(ALLOWED_ACTION_READ_USER)
  const [openAdmin, setOpenAdmin] = React.useState(
    window.location.pathname.includes('/admin'),
  )

  const [anchorProfileMenu, setAnchorProfileMenu] =
    React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorProfileMenu)
  const handleClickProfile = (event: any) => {
    setAnchorProfileMenu(event.currentTarget)
  }
  const handleCloseProfile = () => {
    setAnchorProfileMenu(null)
  }

  const handleClickAdmin = () => {
    setOpenAdmin(!openAdmin)
  }

  useQuery({
    queryFn: async () => {
      try {
        const res = await axios.get(`/version`)
        setVersionApi(res.data)
        return res.data
      } catch (error: any) {
        if (error?.statusCode === 401) {
          authService.logout()
        }
      }
    },
    queryKey: ['menu-version'],
  })

  const getItems = () => [
    {
      Icon: SearchIcon,
      disabled: false,
      name: 'Etablissement',
      onClick: () => navigate('party'),
      selected: window.location.pathname.includes('/party'),
    },
    {
      Icon: KanbanSquareIcon,
      disabled: !permissionsService.getPermission(
        ALLOWED_ACTION_READ_DIAGNOSTIC,
      ),
      name: 'Diagnostics',
      onClick: () => navigate('diagnostics'),
      selected: window.location.pathname.includes('/diagnostics'),
    },
    {
      Icon: FileTextIcon,
      disabled: !permissionsService.getPermission(ALLOWED_ACTION_READ_PROJECT),
      name: 'Projets',
      onClick: () => navigate('projects'),
      selected: window.location.pathname.includes('/projects'),
    },
    {
      Icon: WrenchIcon,
      disabled:
        !permissionsService.getPermission(ALLOWED_ACTION_MANAGE_ROLE) &&
        !permissionsService.getPermission(ALLOWED_ACTION_MANAGE_USER),
      name: 'Administration',
      onClick: () => handleClickAdmin(),
      selected: window.location.pathname.includes('/admin'),
      subItems: [
        {
          disabled: !permissionsService.getPermission(
            ALLOWED_ACTION_MANAGE_USER,
          ),
          name: 'Utilisateurs',
          onClick: () => navigate('admin/users'),
          selected: window.location.pathname.includes('/admin/users'),
        },
        {
          disabled: !permissionsService.getPermission(
            ALLOWED_ACTION_MANAGE_ROLE,
          ),
          name: 'Rôles & droits',
          onClick: () => navigate('admin/roles'),
          selected: window.location.pathname.includes('/admin/roles'),
        },
      ],
      subItemsOpen: openAdmin,
    },
    {
      Icon: MessageSquareMore,
      name: 'Faire un retour',
      onClick: () => openDrawerFeedback(true),
      selected: false,
    },
  ]

  const renderItemsMenu = () => {
    const items = getItems()
    return items.map((item) => {
      const {
        Icon,
        disabled,
        name,
        onClick,
        selected,
        subItems,
        subItemsOpen,
      } = item
      if (disabled) {
        return <></>
      }
      return (
        <ListItemStyled disablePadding key={name}>
          <Stack
            direction="column"
            flexGrow={1}
            sx={{
              backgroundColor:
                selected || subItemsOpen ? primary[700] : 'inherit',
            }}
          >
            <ListItemButton
              selected={selected || subItemsOpen}
              onClick={onClick}
            >
              <Stack direction="row" display="flex" flexGrow={1}>
                <ListItemIcon sx={{ m: 'auto' }}>
                  <Icon color="white" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={name} />
                {subItems && (
                  <ListItemIcon
                    sx={{
                      ml: 'auto',
                    }}
                  >
                    {subItemsOpen ? (
                      <ChevronUpIcon color="white" />
                    ) : (
                      <ChevronDownIcon color="white" />
                    )}
                  </ListItemIcon>
                )}
              </Stack>
            </ListItemButton>
            {subItems && (
              <Collapse unmountOnExit in={subItemsOpen} timeout="auto">
                {subItems.map((subItem) => {
                  return !subItem.disabled ? (
                    <ListItemButton
                      selected={subItem.selected}
                      sx={{ ml: 3 }}
                      onClick={subItem.onClick}
                    >
                      <ListItemText primary={subItem.name} />
                    </ListItemButton>
                  ) : (
                    <></>
                  )
                })}
              </Collapse>
            )}
          </Stack>
        </ListItemStyled>
      )
    })
  }

  const renderMenuProfile = (
    <Menu
      anchorEl={anchorProfileMenu}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      id="profile-menu"
      open={open}
      onClose={handleCloseProfile}
    >
      <Paper sx={{ maxWidth: '100%' }}>
        <MenuList>
          {canReadUser && (
            <MenuItem
              onClick={() => {
                navigate('profile')
                handleCloseProfile()
              }}
            >
              <ListItemIcon>
                <IconSvg Icon={CircleUserIcon} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="subtitle2">Profile</Typography>
              </ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={authService.logout}>
            <ListItemIcon>
              <IconSvg Icon={LogOutIcon} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2">Deconnexion</Typography>
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    </Menu>
  )

  return (
    <>
      <LogoContainer sx={{ display: { sm: 'block', xs: 'none' } }}>
        <LogoFullLight />
        <Typography align="center" fontSize={10} fontWeight={700}>
          Lutter contre les déserts médicaux
        </Typography>
      </LogoContainer>
      <List>
        <ListItemStyled disablePadding sx={{ mb: 4 }}>
          <Stack
            direction="column"
            flexGrow={1}
            sx={{
              backgroundColor: primary[700],
            }}
          >
            <ListItemButton
              aria-haspopup="true"
              id="profile-menu"
              onClick={handleClickProfile}
            >
              <Stack direction="row">
                <ListItemIcon>
                  <CircleUserIcon color="white" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={`${user?.firstname} ${user?.name}`} />
              </Stack>
            </ListItemButton>
            {renderMenuProfile}
          </Stack>
        </ListItemStyled>
        {renderItemsMenu()}
      </List>
      <FooterStyled>
        <Typography align="center" fontSize={10} fontWeight={700}>
          API version - {versionApi}
        </Typography>
        <Typography align="center" fontSize={10} fontWeight={700}>
          APP version - {packageJson.version}
        </Typography>
        <Typography align="center" fontSize={10}>
          Copyright © {new Date().getFullYear()} Medtrucks
        </Typography>
      </FooterStyled>
    </>
  )
}
