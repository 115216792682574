import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AutorenewIcon from '@mui/icons-material/AutorenewRounded'
import CheckIcon from '@mui/icons-material/CheckCircle'
import LensIcon from '@mui/icons-material/LensOutlined'
import { Box, IconButton, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import secondary from 'themes/colors/secondary'

import { myProjectState } from '../../atoms'
import {
  STATE_AREA,
  STATE_FOCUS,
  STATE_OVERVIEW,
  STATE_PARTY,
  STATE_SPECIALITY,
  stateOfInProgress,
} from '../constants'
import { currentStateType } from '../type'

const renderIcon = (
  currentState: currentStateType,
  ownState: currentStateType,
) => {
  if (currentState === ownState) {
    return (
      <AutorenewIcon
        fontSize="small"
        sx={{
          '@keyframes spin': {
            '0%': {
              transform: 'rotate(0deg)',
            },
            '100%': {
              transform: 'rotate(360deg)',
            },
          },
          animation: 'spin 2s linear infinite',
        }}
      />
    )
  }
  const ownStateIndex = stateOfInProgress.indexOf(ownState)
  const currentStateIndex = stateOfInProgress.indexOf(currentState)

  if (ownStateIndex < currentStateIndex) {
    return <CheckIcon color="success" fontSize="small" />
  }

  return <LensIcon fontSize="small" />
}

export default function Aside({ currentState }: { currentState: any }) {
  const currentStateIndex = stateOfInProgress.indexOf(currentState)
  const navigate = useNavigate()
  const myProject = useRecoilValue(myProjectState)

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        onClick={() => navigate('/projects')}
        sx={{
          cursor: 'pointer',
          mb: 3,
          mt: 3,
        }}
      >
        <IconButton sx={{ color: 'white' }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography fontWeight={700} sx={{ ml: 1 }}>
          Retour aux projets
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Box>
          <Typography variant="body2">Nouveau projet</Typography>
          <Typography fontWeight={700} variant="h4">
            {myProject.name}
          </Typography>
        </Box>
        <Typography color={secondary[500]} fontWeight={700} variant="subtitle2">
          Etapes {currentStateIndex + 1}/4
        </Typography>
        <Stack alignItems="center" direction="row">
          {renderIcon(currentState, STATE_AREA)}
          <Typography sx={{ ml: 1 }} variant="subtitle2">
            Territoire
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row">
          {renderIcon(currentState, STATE_SPECIALITY)}
          <Typography sx={{ ml: 1 }} variant="subtitle2">
            Spécialité
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row">
          {renderIcon(currentState, STATE_FOCUS)}
          <Typography sx={{ ml: 1 }} variant="subtitle2">
            Focus
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row">
          {renderIcon(currentState, STATE_PARTY)}
          <Typography sx={{ ml: 1 }} variant="subtitle2">
            Etablissement & Personnel de santé
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row">
          {renderIcon(currentState, STATE_OVERVIEW)}
          <Typography sx={{ ml: 1 }} variant="subtitle2">
            Synthèse
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}
