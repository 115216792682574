import { HOCAllowed } from 'components/Ability/hoc'
import CorePage from 'pages/Core'
import RolesAdminCreateEdit from 'pages/Core/Admin/Roles/CreateEdit'
import RolesAdminList from 'pages/Core/Admin/Roles/List'
import UsersAdminCreateEdit from 'pages/Core/Admin/Users/CreateEdit'
import UsersAdminList from 'pages/Core/Admin/Users/List'
import UsersAdminProfile from 'pages/Core/Admin/Users/Profile'
import Diagnostics from 'pages/Core/Diagnostics'
import PartyOnePage from 'pages/Core/Party'
import ProjectDataSheetPage from 'pages/Core/Projects/DataSheet'
import ProjectCreatePage from 'pages/Core/Projects/InProgress'
import ProjectListPage from 'pages/Core/Projects/List'
import QuestionnairePage from 'pages/Core/Questionnaire'
import PartyListPage from 'pages/Core/Search'
import ErrorPage from 'pages/Error'
import ForgotPasswordPage from 'pages/ForgotPassword'
import LoginPage from 'pages/Login'
import ResetPasswordPage from 'pages/ResetPassword'
import React from 'react'
import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { UserAccessType } from 'types/user'

const ProtectedRoute = ({
  isAllowed,
  needResetPassword,
  redirectPath = '/login',
}: {
  isAllowed: boolean
  needResetPassword: boolean
  redirectPath?: string
}) => {
  if (needResetPassword) {
    return <Navigate replace to={'/reset-password'} />
  }
  if (!isAllowed) {
    return <Navigate replace to={redirectPath} />
  }

  return <Outlet />
}

const router = ({ userState }: { userState: UserAccessType | undefined }) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorPage />}>
        <Route element={<LoginPage />} path="/login" />
        <Route
          element={<ResetPasswordPage />}
          path="/password/:mode/:resetPasswordToken"
        />
        <Route element={<ForgotPasswordPage />} path="/forgot-password" />

        <Route
          errorElement={<ErrorPage />}
          element={
            <ProtectedRoute
              isAllowed={!!userState}
              needResetPassword={!!userState?.resetPasswordToken}
            />
          }
        >
          <Route element={<CorePage />} path="/">
            <Route index element={<Navigate to="/party" />} />
            <Route element={<PartyListPage />} path="party" />
            <Route element={<PartyOnePage />} path="party/:partyId" />
            <Route
              element={HOCAllowed(QuestionnairePage, 'read', 'Questionnaire')}
              path="party/:partyId/questionnaire/:idQuestionnaire"
            />
            <Route
              element={HOCAllowed(Diagnostics, 'read', 'Diagnostic')}
              path="diagnostics"
            />
            <Route
              element={HOCAllowed(ProjectListPage, 'read', 'Project')}
              path="projects"
            ></Route>
            <Route
              element={HOCAllowed(ProjectCreatePage, 'create', 'Project')}
              path="projects/create"
            />
            <Route
              element={HOCAllowed(ProjectDataSheetPage, 'read', 'Project')}
              path="projects/:id"
            />
            <Route path="/admin">
              <Route
                element={HOCAllowed(UsersAdminList, 'manage', 'User')}
                path="users"
              />
              <Route
                element={HOCAllowed(UsersAdminCreateEdit, 'manage', 'User')}
                path="users/create"
              />
              <Route
                element={HOCAllowed(UsersAdminCreateEdit, 'manage', 'User')}
                path="users/:userId"
              />
              <Route
                element={HOCAllowed(UsersAdminProfile, 'read', 'User')}
                path="users/profile/:userId"
              />

              <Route
                element={HOCAllowed(RolesAdminList, 'manage', 'Role')}
                path="roles"
              />
              <Route
                element={HOCAllowed(RolesAdminCreateEdit, 'manage', 'Role')}
                path="roles/create"
              />
              <Route
                element={HOCAllowed(RolesAdminCreateEdit, 'manage', 'Role')}
                path="roles/:roleId"
              />
            </Route>
            <Route
              element={HOCAllowed(UsersAdminProfile, 'read', 'User')}
              path="profile"
            />
            <Route
              element={HOCAllowed(UsersAdminCreateEdit, 'update', 'User')}
              path="profile/edit"
            />
          </Route>
        </Route>
      </Route>,
    ),
  )

export default router
