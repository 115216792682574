import type { Question } from 'types/questionnaire'

import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAltRounded'
import { Box, Button, Grid, Paper, Stack, ToggleButton } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import secondary from 'themes/colors/secondary'
import { SurveyAnswer } from 'types/survey'

import { StyledToggleButtonGroup } from './styles'

const Survey = ({
  currentAnswer,
  currentQuestion,
  handleAnswerQuestion,
  handleNextQuestion,
  handlePreviousQuestion,
  isFirstQuestion,
  isLastQuestion,
}: {
  currentAnswer: SurveyAnswer | null
  currentQuestion: Question | null
  handleAnswerQuestion: any
  handleNextQuestion: any
  handlePreviousQuestion: any
  isFirstQuestion: boolean
  isLastQuestion: boolean
}) => {
  const [idAnswer, setIdAnswer] = React.useState<null | number>(
    currentAnswer?.questionAnswerId || null,
  )

  React.useEffect(() => {
    setIdAnswer(currentAnswer?.questionAnswerId || null)
  }, [currentAnswer])

  const handleNote = (
    event: React.MouseEvent<HTMLElement>,
    value: null | number,
  ) => {
    if (value !== null) setIdAnswer(value)
  }

  const onSubmit = React.useCallback(async () => {
    await handleAnswerQuestion(idAnswer)
    setIdAnswer(null)
  }, [handleAnswerQuestion, idAnswer])

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      spacing={5}
      onKeyUp={(e) => {
        e.persist()
        if (e.key === 'Enter' && idAnswer !== null) onSubmit()
      }}
    >
      <Grid item xs={8}>
        <Typography fontWeight={700} my={1} sx={{ color: secondary[800] }}>
          {currentQuestion?.subcategory?.category?.label} &gt;{' '}
          {currentQuestion?.subcategory?.label}
        </Typography>
        <Typography fontWeight={700} mb={6} variant="subtitle1">
          {currentQuestion?.label}
        </Typography>
        <StyledToggleButtonGroup
          exclusive
          aria-label="note survey"
          value={idAnswer}
          onChange={handleNote}
        >
          {currentQuestion?.answers.map((answer) => (
            <ToggleButton
              aria-label={`note ${answer.questionId} ${answer.sequence}`}
              key={`note ${answer.questionId} ${answer.sequence}`}
              selected={answer.id === idAnswer}
              value={answer.id}
            >
              {answer.sequence}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
        <Box mb={3} mt={3}>
          {currentQuestion?.answers.map((answer, key) => {
            return (
              <Typography
                key={key}
                overflow="hidden"
                textAlign="center"
                sx={{
                  height: answer.id === idAnswer ? '100px' : '0px',
                  transitionDuration: '0.3s',
                }}
              >
                {answer.label}
              </Typography>
            )
          })}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Box display="flex" flexGrow={1} justifyContent="space-between" p={1}>
            <Stack direction="row">
              <Button
                disabled={isFirstQuestion}
                startIcon={<ArrowBackIcon />}
                variant="outlined"
                onClick={handlePreviousQuestion}
              >
                Précedent
              </Button>
            </Stack>
            <Stack direction="row">
              <Button
                disabled={isLastQuestion}
                variant="outlined"
                onClick={handleNextQuestion}
              >
                Passer
              </Button>
              <Button
                disabled={!idAnswer}
                endIcon={!isLastQuestion && <ArrowRightAltIcon />}
                sx={{ ml: 2 }}
                variant="contained"
                onClick={onSubmit}
              >
                {isLastQuestion ? 'Terminer' : 'Suivant'}
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Survey
