import ExploreIcon from '@mui/icons-material/ExploreOutlined'
import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'

const BackdropMap = ({
  ifAreaSelected,
  isLoading,
}: {
  ifAreaSelected: boolean
  isLoading: boolean
}) => {
  if (!isLoading && ifAreaSelected) {
    return <></>
  }
  return (
    <Backdrop
      open={true}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        position: 'absolute',
        zIndex: (theme) => theme.zIndex.appBar - 1,
      }}
    >
      <Stack alignItems="center" color="white" textAlign="center">
        {!ifAreaSelected && !isLoading && (
          <>
            <ExploreIcon color="inherit" fontSize="large" />
            <Typography
              color="white"
              display="inline-block"
              fontWeight={700}
              variant="h6"
            >
              Sélectionner votre territoire pour
            </Typography>
            <Typography
              color="white"
              display="inline-block"
              fontWeight={700}
              variant="h6"
            >
              commencer l’analyse
            </Typography>
          </>
        )}
        {isLoading && (
          <>
            <CircularProgress color="inherit" size={30} />
            <Typography color="white" fontWeight={700} mt={2} variant="h6">
              Génération de la carte
            </Typography>
          </>
        )}
      </Stack>
    </Backdrop>
  )
}

export default BackdropMap
