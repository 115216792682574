import { Divider, Skeleton } from '@mui/material'
import { Typography } from '@mui/material'
import React from 'react'

const CountResults = ({
  count,

  loading,
}: {
  count: number

  loading: boolean
}) => {
  const text =
    count === 0 || !count
      ? "Il n'y a pas établissements / PS"
      : `${count?.toLocaleString()} établissements / PS`

  if (loading)
    return (
      <>
        <Skeleton
          animation="wave"
          height={38}
          sx={{ mb: 1, mt: 1 }}
          width="80%"
        />
        <Divider />
      </>
    )

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          alignItems: 'center',
          display: 'flex',
          fontWeight: 700,
          height: '38px',
          pl: 2,
        }}
      >
        {text}
      </Typography>
      <Divider />
    </>
  )
}

export default CountResults
