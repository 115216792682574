import { Box, Stack, Tab, Tabs } from '@mui/material'
import { ListIcon, MapIcon } from 'lucide-react'
import { MRT_PaginationState } from 'material-react-table'
import * as React from 'react'

import CountResults from './CountResults'
import List from './List'
import Map from './Map'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
function a11yProps(index: number) {
  return {
    'aria-controls': `simple-tabpanel-${index}`,
    id: `simple-tab-${index}`,
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, index, value, ...other } = props
  return (
    <Box
      aria-labelledby={`simple-tab-${index}`}
      flexGrow={1}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      sx={{ display: +value !== index ? 'none' : 'flex', overflow: 'auto' }}
      {...other}
    >
      <Box flexGrow={1} position="relative">
        {children}
      </Box>
    </Box>
  )
}

const TabsSearch = ({
  count,
  isLoading,
  pagination,
  response,
  setPagination,
  setTabState,
  tabState,
}: {
  count: number
  isLoading: boolean
  pagination: MRT_PaginationState
  response: any
  setPagination: any
  setTabState: any
  tabState: number
}) => {
  return (
    <Stack flex={1} sx={{ overflow: 'hidden', position: 'relative' }}>
      <Tabs
        aria-label="Tabs search"
        value={tabState}
        onChange={(e, value) => setTabState(value)}
      >
        <Tab
          icon={<MapIcon />}
          iconPosition="start"
          label="CARTE"
          {...a11yProps(0)}
          sx={{ p: 2 }}
        />
        <Tab
          icon={<ListIcon />}
          iconPosition="start"
          label="LISTE"
          {...a11yProps(1)}
          sx={{ p: 2 }}
        />
      </Tabs>
      <CountResults count={count} loading={isLoading} />
      <TabPanel index={0} value={tabState}>
        <Map isLoading={isLoading} />
      </TabPanel>
      <TabPanel index={1} value={tabState}>
        <List
          isLoading={isLoading}
          pagination={pagination}
          response={response}
          setPagination={setPagination}
        />
      </TabPanel>
    </Stack>
  )
}

export default TabsSearch
