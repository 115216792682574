import { Button } from '@mui/material'
import Header from 'components/Header'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

export const validationSchema = z.object({
  search: z.string(),
})

export type ValidationSchema = z.infer<typeof validationSchema>

const HeaderList = () => {
  const navigate = useNavigate()

  const rowRight = (
    <Button variant="contained" onClick={() => navigate('/admin/roles/create')}>
      Nouveau rôle
    </Button>
  )

  return <Header rowLeftTitle="Rôles et droits" rowRight={rowRight} />
}
export default HeaderList
