import { selectOptions } from 'components-ui/SelectMultiple/type'
import { myProjectType } from 'types/recoils/projects'

export const getAreaInfos = (myProject: myProjectType) => {
  const { options } = myProject
  let areaInfosLength = 0

  let areaInfosValue: selectOptions[] = []

  if (options.cityCode.length > 0) {
    areaInfosLength = options.cityCode.length
    areaInfosValue = options.cityCode
  }
  if (options.regionCode.length > 0) {
    areaInfosLength = options.regionCode.length
    areaInfosValue = options.regionCode
  }
  if (options.countyCode.length > 0) {
    areaInfosLength = options.countyCode.length
    areaInfosValue = options.countyCode
  }
  if (options.cptsCode.length > 0) {
    areaInfosLength = options.cptsCode.length
    areaInfosValue = options.cptsCode
  }
  if (options.dacCode.length > 0) {
    areaInfosLength = options.dacCode.length
    areaInfosValue = options.dacCode
  }
  if (options.tvsCode.length > 0) {
    areaInfosLength = options.tvsCode.length
    areaInfosValue = options.tvsCode
  }
  if (options.epciCode.length > 0) {
    areaInfosLength = options.epciCode.length
    areaInfosValue = options.epciCode
  }

  return { areaInfosLength, areaInfosValue }
}
