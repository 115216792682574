import { Box, ListItem } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  '& .MuiListItemButton-root': {
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '35px',
  },
  '& .MuiTypography-root': {
    fontSize: '1rem',
    fontWeight: 700,
    transition: theme.transitions.create(['color', 'transform']),
  },
  '& svg': {
    transition: theme.transitions.create(['stroke', 'transform']),
  },
}))
export const LogoContainer = styled(Box)(() => ({
  '& svg': {
    height: 'auto',
  },
  height: 50,
  margin: '40px auto 25px',
  width: 162,
}))

export const FooterStyled = styled('footer')(() => ({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
  padding: '16px',
}))
