import { Button, Grid, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ReactComponent as SurveyCompleteImage } from 'assets/images/surveyComplete.svg'
import * as React from 'react'

export default (
  action: React.MouseEventHandler<HTMLButtonElement> | undefined,
  infos: { answersCount: number; questionCount: number },
) => ({
  completed: {
    body: (
      <Grid item>
        <Stack>
          <SurveyCompleteImage />
          <Typography
            color="primary"
            fontWeight={700}
            ml={1}
            mr={1}
            mt={3}
            textAlign="center"
            variant="body1"
          >
            Bravo votre questionnaire est entièrement rempli !
          </Typography>
        </Stack>
      </Grid>
    ),
    footer: (
      <Grid item display="flex" justifyContent="space-between">
        <Button
          fullWidth
          sx={{ height: 46 }}
          variant="contained"
          onClick={action}
        >
          Voir détails
        </Button>
      </Grid>
    ),
  },
  empty: {
    body: <></>,
    footer: (
      <Grid item display="flex" justifyContent="space-between">
        <Button
          fullWidth
          sx={{ height: 46 }}
          variant="contained"
          onClick={action}
        >
          Commencer
        </Button>
      </Grid>
    ),
  },
  inProgress: {
    body: (
      <Grid item>
        <Typography
          color="primary"
          fontWeight={700}
          ml={1}
          mr={1}
          textAlign="center"
          variant="body1"
        >
          Plus que quelques questions pour avoir une analyse complète de votre
          établissement !
        </Typography>
      </Grid>
    ),
    footer: (
      <>
        <Grid item display="flex" justifyContent="space-between" sx={{ my: 1 }}>
          <Typography fontWeight={700} variant="body1">
            Questions terminées
          </Typography>
          <Typography variant="body1">
            {infos?.answersCount} / {infos?.questionCount}
          </Typography>
        </Grid>
        <Grid item display="flex" justifyContent="space-between">
          <Button
            fullWidth
            sx={{ height: 46 }}
            variant="contained"
            onClick={action}
          >
            Continuer
          </Button>
        </Grid>
      </>
    ),
  },
})
