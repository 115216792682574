import { Box, styled } from '@mui/material'

export const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0,0,0,0.9)',
  height: '100vh',
  left: '400px',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  transition: 'width 0.2s ease-out',
  zIndex: theme.zIndex.appBar,
}))
