import { z } from 'zod'

const CONST_ERROR_DIGIT = 'DIGIT'
const CONST_ERROR_LENGTH = 'LENGTH'
const CONST_ERROR_LOWER = 'LOWER'
const CONST_ERROR_SPECIAL = 'SPECIAL'
const CONST_ERROR_UPPER = 'UPPER'

export const schemaDisplayErrors = [
  { key: CONST_ERROR_LENGTH, label: 'Entre 8 et 16 caractères' },
  { key: CONST_ERROR_LOWER, label: 'Une minuscule' },
  { key: CONST_ERROR_UPPER, label: 'Une majuscule' },
  { key: CONST_ERROR_DIGIT, label: 'Un chiffre' },
  { key: CONST_ERROR_SPECIAL, label: 'Un caractère spécial' },
]

export const validationSchema = z
  .object({
    password: z
      .string()
      .min(8, CONST_ERROR_LENGTH)
      .max(16, CONST_ERROR_LENGTH)
      .regex(/[a-z]/, CONST_ERROR_LOWER)
      .regex(/[A-Z]/, CONST_ERROR_UPPER)
      .regex(/[0-9]/, CONST_ERROR_DIGIT)
      .regex(/[#?!@$%^&*-]/, CONST_ERROR_SPECIAL),
    passwordConfirm: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: 'Vos mots de passe ne correspondent pas',
    path: ['passwordConfirm'],
  })

export type ValidationSchema = z.infer<typeof validationSchema>
