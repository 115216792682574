import { Stack } from '@mui/material'
import AreaFilterComponent from 'components/Filters/AsideFilters/GeoFilters/AreaFilters'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { myProjectState } from '../../atoms'

const SelectionAreaInProgressProject = () => {
  const setMyProject = useSetRecoilState(myProjectState)
  const myProject = useRecoilValue(myProjectState)
  const navigate = useNavigate()

  if (!myProject.name) {
    navigate('/projects')
  }

  const onChangeRadioEvent = async () => {
    return myProject
  }

  return (
    <Stack p={2} spacing={1}>
      <AreaFilterComponent
        myData={myProject}
        setMyData={setMyProject}
        onChangeRadioEvent={onChangeRadioEvent}
      />
    </Stack>
  )
}
export default SelectionAreaInProgressProject
