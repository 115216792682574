import React from 'react'
import { Helmet } from 'react-helmet'

import type { HelmetProps } from './types'

const HelmetCustom = ({ description, ogImage, title, url }: HelmetProps) => {
  // website Url
  const pageUrl = 'https://medtrucks.com/'
  // when you share this page on facebook you'll see this image
  const ogImg = 'https://medtrucks.com/img/MedTrucks_banniere_big.png'

  return (
    <Helmet>
      <title>{title ? title : 'MedTrucks'}</title>
      <meta
        key="description"
        name="description"
        content={
          description ? description : 'Medtrucks description of the page'
        }
      />
      <meta
        content={title ? title : 'Medtrucks og : title of the page'}
        key="og:title"
        property="og:title"
      />
      <meta content={url ? url : pageUrl} key="og:url" property="og:url" />
      <meta
        content={ogImage ? ogImage : ogImg}
        key="og:image"
        property="og:image"
      />
      <meta
        key="og:description"
        property="og:description"
        content={
          description ? description : 'Medtrucks og : description of the page'
        }
      />
    </Helmet>
  )
}

export default HelmetCustom
