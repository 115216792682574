import type { TextFieldProps } from '@mui/material/TextField'

import { Icon as MuiIcon, type SxProps, type Theme } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import React, { ElementType } from 'react'

export default function InputStartIcon({
  Icon,
  sxContainer,
  ...props
}: {
  Icon: ElementType
  sxContainer?: SxProps<Theme>
} & TextFieldProps) {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex', ...sxContainer }}>
      <MuiIcon sx={{ mr: 1, my: 0.5 }}>
        <Icon />
      </MuiIcon>
      <TextField variant="standard" {...props} />
    </Box>
  )
}
