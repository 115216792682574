import { isEmpty } from 'lodash'
import { CityType } from 'types/city'
import { CountyType } from 'types/county'
import { CptsType } from 'types/cpts'
import { DacType } from 'types/dac'
import { Party } from 'types/party'
import { ProjectType } from 'types/project'
import { Aggs } from 'types/recoils/parties-search'
import { RegionType } from 'types/region'
import { SpecialityType } from 'types/speciality'

import {
  CONST_CITIES,
  CONST_COUNTIES,
  CONST_CPTS,
  CONST_DAC,
  CONST_EPCI,
  CONST_REGIONS,
  CONST_TVS,
} from './InProgress/constants'
import { areaType } from './InProgress/type'

export const formatAggsToSelectOptions = (aggs: Aggs[]) => {
  return aggs.map((agg: Aggs) => ({
    label: agg.key,
    value: agg.key,
  }))
}

export const formatAreasToSelectOptions = (
  list: CityType[] | CountyType[] | CptsType[] | DacType[] | RegionType[],
) => {
  return (
    list?.map(
      (agg: CityType | CountyType | CptsType | DacType | RegionType) => ({
        label: agg.name,
        value: agg.code,
      }),
    ) || []
  )
}
export const formatSpecialitiesToSelectOptions = (list: SpecialityType[]) => {
  return (
    list?.map((agg: SpecialityType) => ({
      label: agg.name,
      value: agg.code,
    })) || []
  )
}
export const formatPartiesToSelectOptions = (list: Party[]) => {
  return (
    list?.map((party: Party) => ({
      category: party.category,
      label: party.name,
      value: party.id,
    })) || []
  )
}

export const getAreaType = (project: ProjectType): areaType => {
  if (!isEmpty(project.cities)) {
    return CONST_CITIES
  }
  if (!isEmpty(project.counties)) {
    return CONST_COUNTIES
  }
  if (!isEmpty(project.regions)) {
    return CONST_REGIONS
  }
  if (!isEmpty(project.cpts)) {
    return CONST_CPTS
  }
  if (!isEmpty(project.dac)) {
    return CONST_DAC
  }
  if (!isEmpty(project.tvs)) {
    return CONST_TVS
  }
  if (!isEmpty(project.epci)) {
    return CONST_EPCI
  }
  return 'regionCode'
}
