import { atom } from 'recoil'

import {
  defaultMyDiagnosticExtraState,
  defaultMyDiagnosticState,
} from '../Diagnostics/atoms'

/*** STATES ***/

export const defaultMyProjectState = {
  ...defaultMyDiagnosticState,
  ...defaultMyDiagnosticExtraState,
}

export const myProjectState = atom({
  default: defaultMyProjectState,
  key: 'myProjectState',
})
