import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import AbilityProvider from 'components/Ability'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'

import App from './App'
import Snackbar from './SnackBar'
import theme from './themes'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement as HTMLElement)
const queryClient = new QueryClient()

root.render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <RecoilRoot>
        <Snackbar>
          <QueryClientProvider client={queryClient}>
            <AbilityProvider>
              <App />
            </AbilityProvider>
          </QueryClientProvider>
        </Snackbar>
      </RecoilRoot>
    </ThemeProvider>
  </RecoilRoot>,
)
