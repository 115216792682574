import { createTheme } from '@mui/material/styles'
import { heightTab } from 'constants/ui'

import alert from './colors/alert'
import neutral from './colors/neutral'
import primary from './colors/primary'
import secondary from './colors/secondary'
import success from './colors/success'
import warning from './colors/warning'

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: '4px 4px 4px 0px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          ':hover': {
            backgroundColor: neutral[100],
            boxShadow: 'none',
            color: primary[500],
          },
          backgroundColor: neutral[100],
          boxShadow: 'none',
          color: primary[500],
        },
        outlined: {
          '&:hover': {
            border: `2px solid ${primary[500]}`,
          },
          border: `2px solid ${primary[500]}`,
        },
        root: {
          '&.Mui-disabled': {
            color: neutral[300],
          },
          fontWeight: 700,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:not(.Mui-checked)': {
            color: neutral[900],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorInfo: {
          '&:hover': {
            backgroundColor: secondary[700],
            color: 'white',
          },
          backgroundColor: neutral[100],
          color: neutral[900],
        },
        root: {
          fontWeight: 700,
          transitionProperty: 'background-color, box-shadow, color',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'unset',
          color: neutral[200],
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[100],
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: neutral[900],
          fontWeight: 700,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: neutral[800],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiFilledInput-root': {
            '&.Mui-focused': {
              backgroundColor: neutral[50],
            },
            '&:hover': {
              backgroundColor: neutral[100],
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: neutral[800],
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '& svg:first-child': { stroke: secondary[400] },
            backgroundColor: primary[700],
            color: secondary[400],
          },
          '&:hover': {
            '& svg': { stroke: secondary[400] },
            backgroundColor: 'inherit',
            color: secondary[400],
          },
          borderRadius: 4,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        elevation2: {
          boxShadow: '0px 0px 4px 0px #00000029',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: neutral[900],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.MuiSelect-select:focus': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: primary[50],
            border: 'none',
          },
          borderRadius: 4,
          color: neutral[900],
          fontSize: '1rem',
          fontWeight: 700,
          lineHeight: '23px',
          marginRight: 5,
          minHeight: heightTab,
          minWidth: 0,
          padding: 8,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontSize: 14,
        },
        head: {
          backgroundColor: neutral[300],
          fontSize: 14,
          fontWeight: 700,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-displayedRows': {
            fontSize: '0.889rem',
          },
          backgroundColor: neutral[50],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // hide last border
          '& td, & th': {
            border: 0,
          },
          '&:nth-of-type(odd)': {
            backgroundColor: neutral[50],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          height: '45px',
          minHeight: '45px',
        },
        root: {
          borderBottom: `1px solid ${neutral[200]}`,
          height: '45px',
          minHeight: '45px',
        },
        // indicator: {
        //   display: 'none',
        // },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: primary[500],
            },
            backgroundColor: primary[900],
            color: 'white',
          },
          color: primary[900],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#FFFFFF',
        },
        tooltip: {
          backgroundColor: 'white',
          borderRadius: 4,
          boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.1)',
          color: neutral[800],
          fontSize: '0.889rem',
          padding: 8,
        },
      },
    },
  },
  palette: {
    error: {
      main: alert[500],
    },
    neutral: {
      dark: neutral[900],
      light: neutral[100],
      main: neutral[500],
    },
    primary: {
      contrastText: '#fff',
      dark: primary[900],
      light: primary[100],
      main: primary[500],
    },
    secondary: {
      contrastText: neutral[900],
      dark: secondary[900],
      light: secondary[100],
      main: secondary[500],
    },
    success: {
      main: success[500],
    },
    text: {
      disabled: '#A7ACB1',
      primary: neutral[900],
      secondary: '#001D73',
    },
    warning: {
      main: warning[500],
    },
  },

  typography: {
    body1: {
      fontSize: '0.889rem',
      lineHeight: 1.4,
    },
    body2: {
      fontSize: '0.702rem',
      lineHeight: 1.4,
    },
    button: {
      textTransform: 'inherit',
    },
    fontFamily: "'Nunito'",
    fontSize: 14,
    h1: {
      fontFamily: "'Open Sans'",
      fontSize: '1.802rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: "'Open Sans'",
      fontSize: '1.602rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: "'Open Sans'",
      fontSize: '1.424rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: "'Open Sans'",
      fontSize: '1.266rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: '1.125rem',
      lineHeight: 1.4,
    },
    subtitle2: {
      fontSize: '1rem',
      lineHeight: 1.4,
    },
  },
})

export default theme

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color']
    }
  }

  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }
}
