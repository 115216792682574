export default [
  {
    filter: ['==', 'class', 'national_park'],
    id: 'landuse_overlay_national_park',
    interactive: true,
    paint: {
      'fill-color': '#d2edae',
      'fill-opacity': 0.75,
    },
    source: 'mapbox',
    'source-layer': 'landuse_overlay',
    type: 'fill',
  },
  {
    filter: ['==', 'class', 'park'],
    id: 'landuse_park',
    interactive: true,
    paint: {
      'fill-color': '#d2edae',
    },
    source: 'mapbox',
    'source-layer': 'landuse',
    type: 'fill',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['in', 'class', 'river', 'canal'],
    ],
    id: 'waterway',
    interactive: true,
    paint: {
      'line-color': '#a0cfdf',
      'line-width': {
        base: 1.4,
        stops: [
          [8, 0.5],
          [20, 15],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'waterway',
    type: 'line',
  },
  {
    id: 'water',
    interactive: true,
    paint: {
      'fill-color': '#a0cfdf',
    },
    source: 'mapbox',
    'source-layer': 'water',
    type: 'fill',
  },
  {
    id: 'building',
    interactive: true,
    paint: {
      'fill-color': '#d6d6d6',
    },
    source: 'mapbox',
    'source-layer': 'building',
    type: 'fill',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      [
        'all',
        [
          'in',
          'class',
          'motorway_link',
          'street',
          'street_limited',
          'service',
          'track',
          'pedestrian',
          'path',
          'link',
        ],
        ['==', 'structure', 'tunnel'],
      ],
    ],
    id: 'tunnel_minor',
    interactive: true,
    layout: {
      'line-cap': 'butt',
      'line-join': 'miter',
    },
    paint: {
      'line-color': '#efefef',
      'line-dasharray': [0.36, 0.18],
      'line-width': {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'road',
    type: 'line',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      [
        'all',
        [
          'in',
          'class',
          'motorway',
          'primary',
          'secondary',
          'tertiary',
          'trunk',
        ],
        ['==', 'structure', 'tunnel'],
      ],
    ],
    id: 'tunnel_major',
    interactive: true,
    layout: {
      'line-cap': 'butt',
      'line-join': 'miter',
    },
    paint: {
      'line-color': '#fff',
      'line-dasharray': [0.28, 0.14],
      'line-width': {
        base: 1.4,
        stops: [
          [6, 0.5],
          [20, 30],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'road',
    type: 'line',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      [
        'all',
        [
          'in',
          'class',
          'motorway_link',
          'street',
          'street_limited',
          'service',
          'track',
          'pedestrian',
          'path',
          'link',
        ],
        ['in', 'structure', 'none', 'ford'],
      ],
    ],
    id: 'road_minor',
    interactive: true,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#efefef',
      'line-width': {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'road',
    type: 'line',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      [
        'all',
        [
          'in',
          'class',
          'motorway',
          'primary',
          'secondary',
          'tertiary',
          'trunk',
        ],
        ['in', 'structure', 'none', 'ford'],
      ],
    ],
    id: 'road_major',
    interactive: true,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fff',
      'line-width': {
        base: 1.4,
        stops: [
          [6, 0.5],
          [20, 30],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'road',
    type: 'line',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      [
        'all',
        [
          'in',
          'class',
          'motorway_link',
          'street',
          'street_limited',
          'service',
          'track',
          'pedestrian',
          'path',
          'link',
        ],
        ['==', 'structure', 'bridge'],
      ],
    ],
    id: 'bridge_minor case',
    interactive: true,
    layout: {
      'line-cap': 'butt',
      'line-join': 'miter',
    },
    paint: {
      'line-color': '#dedede',
      'line-gap-width': {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
      'line-width': {
        base: 1.6,
        stops: [
          [12, 0.5],
          [20, 10],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'road',
    type: 'line',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      [
        'all',
        [
          'in',
          'class',
          'motorway',
          'primary',
          'secondary',
          'tertiary',
          'trunk',
        ],
        ['==', 'structure', 'bridge'],
      ],
    ],
    id: 'bridge_major case',
    interactive: true,
    layout: {
      'line-cap': 'butt',
      'line-join': 'miter',
    },
    paint: {
      'line-color': '#dedede',
      'line-gap-width': {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
      'line-width': {
        base: 1.6,
        stops: [
          [12, 0.5],
          [20, 10],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'road',
    type: 'line',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      [
        'all',
        [
          'in',
          'class',
          'motorway_link',
          'street',
          'street_limited',
          'service',
          'track',
          'pedestrian',
          'path',
          'link',
        ],
        ['==', 'structure', 'bridge'],
      ],
    ],
    id: 'bridge_minor',
    interactive: true,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#efefef',
      'line-width': {
        base: 1.55,
        stops: [
          [4, 0.25],
          [20, 30],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'road',
    type: 'line',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      [
        'all',
        [
          'in',
          'class',
          'motorway',
          'primary',
          'secondary',
          'tertiary',
          'trunk',
        ],
        ['==', 'structure', 'bridge'],
      ],
    ],
    id: 'bridge_major',
    interactive: true,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fff',
      'line-width': {
        base: 1.4,
        stops: [
          [6, 0.5],
          [20, 30],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'road',
    type: 'line',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['all', ['<=', 'admin_level', 2], ['==', 'maritime', 0]],
    ],
    id: 'admin_country',
    interactive: true,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#8b8a8a',
      'line-width': {
        base: 1.3,
        stops: [
          [3, 0.5],
          [22, 15],
        ],
      },
    },
    source: 'mapbox',
    'source-layer': 'admin',
    type: 'line',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['all', ['==', 'scalerank', 1], ['==', 'localrank', 1]],
    ],
    id: 'poi_label',
    interactive: true,
    layout: {
      'icon-image': '{maki}-11',
      'icon-size': 1,
      'text-anchor': 'top',
      'text-field': '{name_en}',
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-max-width': 8,
      'text-offset': [0, 0.5],
      'text-size': 11,
    },
    minzoom: 5,
    paint: {
      'text-color': '#666',
      'text-halo-blur': 1,
      'text-halo-color': 'rgba(255,255,255,0.75)',
      'text-halo-width': 1,
    },
    source: 'mapbox',
    'source-layer': 'poi_label',
    type: 'symbol',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['in', 'class', 'motorway', 'primary', 'secondary', 'tertiary', 'trunk'],
    ],
    id: 'road_major_label',
    interactive: true,
    layout: {
      'symbol-placement': 'line',
      'text-field': '{name_en}',
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-letter-spacing': 0.1,
      'text-size': {
        base: 1.4,
        stops: [
          [10, 8],
          [20, 14],
        ],
      },
      'text-transform': 'uppercase',
    },
    paint: {
      'text-color': '#666',
      'text-halo-color': 'rgba(255,255,255,0.75)',
      'text-halo-width': 2,
    },
    source: 'mapbox',
    'source-layer': 'road_label',
    type: 'symbol',
  },
  {
    filter: [
      'all',
      ['==', '$type', 'Point'],
      [
        'in',
        'type',
        'town',
        'village',
        'hamlet',
        'suburb',
        'neighbourhood',
        'island',
      ],
    ],
    id: 'place_label_other',
    interactive: true,
    layout: {
      'text-field': '{name_en}',
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-max-width': 6,
      'text-size': {
        stops: [
          [6, 12],
          [12, 16],
        ],
      },
    },
    minzoom: 8,
    paint: {
      'text-color': '#666',
      'text-halo-blur': 1,
      'text-halo-color': 'rgba(255,255,255,0.75)',
      'text-halo-width': 1,
    },
    source: 'mapbox',
    'source-layer': 'place_label',
    type: 'symbol',
  },
  {
    filter: ['all', ['==', '$type', 'Point'], ['==', 'type', 'city']],
    id: 'place_label_city',
    interactive: true,
    layout: {
      'text-field': '{name_en}',
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-max-width': 10,
      'text-size': {
        stops: [
          [3, 12],
          [8, 16],
        ],
      },
    },
    maxzoom: 16,
    paint: {
      'text-color': '#666',
      'text-halo-blur': 1,
      'text-halo-color': 'rgba(255,255,255,0.75)',
      'text-halo-width': 1,
    },
    source: 'mapbox',
    'source-layer': 'place_label',
    type: 'symbol',
  },
  {
    filter: ['==', '$type', 'Point'],
    id: 'country_label',
    interactive: true,
    layout: {
      'text-field': '{name_en}',
      'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-max-width': 10,
      'text-size': {
        stops: [
          [3, 14],
          [8, 22],
        ],
      },
    },
    maxzoom: 12,
    paint: {
      'text-color': '#666',
      'text-halo-blur': 1,
      'text-halo-color': 'rgba(255,255,255,0.75)',
      'text-halo-width': 1,
    },
    source: 'mapbox',
    'source-layer': 'country_label',
    type: 'symbol',
  },
]
