import type { selectOptions } from 'components-ui/SelectMultiple/type'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { myProjectState } from '../../atoms'

const rowsPerPage = 10

const OverviewOrganizationInProgressProject = () => {
  const myProject = useRecoilValue(myProjectState)

  const [pageNumber, setPageNumber] = React.useState(0)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage)
  }

  if (!myProject.party.organization?.length) {
    return <></>
  }

  const arrayPaginate =
    myProject.party.organization.slice(
      pageNumber * rowsPerPage,
      (pageNumber + 1) * rowsPerPage,
    ) || []

  return (
    <>
      <Typography color="primary" mb={2} variant="h3">
        Etablissement(s)
      </Typography>
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Profession</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arrayPaginate?.map(
              (
                organization: selectOptions & { category?: string },
                id: number,
              ) => (
                <TableRow key={organization.value + id}>
                  <TableCell>
                    <Link to={`/party/${organization.value}`}>
                      {organization.label}
                    </Link>
                  </TableCell>
                  <TableCell>{organization.category}</TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={myProject.party.organization?.length || 0}
        page={pageNumber}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[-1]}
        onPageChange={handleChangePage}
        labelDisplayedRows={({ count, from, to }) =>
          `${from}–${to} sur ${count !== -1 ? count : `plus que ${to}`}`
        }
      />
    </>
  )
}
export default OverviewOrganizationInProgressProject
