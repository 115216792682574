import axios from 'axios'

class PasswordService {
  forgotPassword(email: string) {
    return axios.post('/auth/passwords/forgot-password', {
      email,
    })
  }

  getCurrentUser() {
    const userStr = localStorage.getItem('user')
    return userStr ? JSON.parse(localStorage.getItem('user') || '{}') : ''
  }
}

export default new PasswordService()
