import type { SelectChangeEvent } from '@mui/material'

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAxios } from 'services/axios.service'

import { myProjectState } from '../../atoms'
import { formatSpecialitiesToSelectOptions } from '../../utils'

const SelectionSpecialityInProgressProject = () => {
  const setMyProject = useSetRecoilState(myProjectState)
  const myProject = useRecoilValue(myProjectState)

  const paramsUrl = new URLSearchParams({
    crudQuery: JSON.stringify({
      page: 1,
      pageSize: 150,
    }),
  })

  const { loading, response: specialities } = useAxios({
    url: `/speciality?${paramsUrl}`,
  })

  React.useEffect(() => {
    const firstSpe = specialities?.data?.[0]
    if (firstSpe && !myProject.speciality.value) {
      setMyProject((curr) => ({
        ...curr,
        speciality: {
          label: firstSpe.name,
          value: firstSpe.code,
        },
      }))
    }
  }, [loading])

  const onChangeSelect = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event

    const speciality = specialities?.data.find(
      (speciality: any) => speciality.code === value,
    )
    setMyProject((curr) => ({
      ...curr,
      speciality: {
        label: speciality.name,
        value: speciality.code,
      },
    }))
  }

  const selectConfig = {
    label: 'Spécialité',
    optionsWanted: formatSpecialitiesToSelectOptions(specialities?.data),
    placeholder: 'Ex : Omnipraticiens',
  }

  return (
    <Stack p={2} spacing={1}>
      <Typography fontWeight={700}>Sélectionner une spécialité</Typography>
      <FormControl fullWidth variant="filled">
        <InputLabel id="SelectSpecialityLabel">{selectConfig.label}</InputLabel>
        <Select
          defaultValue={myProject.speciality.value}
          id="SelectSpeciality-standard"
          labelId="SelectSpecialityLabel"
          placeholder={selectConfig.placeholder}
          value={myProject.speciality.value}
          onChange={(event: any) => onChangeSelect(event)}
        >
          {selectConfig.optionsWanted.map((spe) => (
            <MenuItem key={spe.label} value={spe.value}>
              {spe.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  )
}
export default SelectionSpecialityInProgressProject
