export default {
  50: '#E6FEF7',
  100: '#CAFDED',
  200: '#96FBE4',
  300: '#61F4DF',
  400: '#3AEADF',
  500: '#00D8DD',
  600: '#00A9BE',
  700: '#00809F',
  800: '#005C80',
  900: '#00436A',
}
