import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Divider, Grid, Paper } from '@mui/material'
import SearchInput from 'components-ui/SearchInput'
import { heightHeader } from 'constants/ui'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

export const validationSchema = z.object({
  search: z.string(),
})

export type ValidationSchema = z.infer<typeof validationSchema>

const HeaderProject = ({
  onSubmitFetch,
  openCreateProjectModal,
}: {
  onSubmitFetch: any
  openCreateProjectModal: any
}) => {
  const { control, handleSubmit } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  })

  return (
    <Paper
      sx={{
        borderRadius: 0,
        position: 'relative',
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <form onSubmit={handleSubmit(onSubmitFetch)}>
        <Grid
          container
          alignItems="center"
          height={`${heightHeader}px`}
          pl={2}
          pr={2}
        >
          <Grid item flexGrow={1}>
            <Controller
              control={control}
              name="search"
              render={({ field }) => (
                <SearchInput
                  fullWidth
                  action={handleSubmit(onSubmitFetch)}
                  placeholder="Rechercher par nom d'utilisateur"
                  {...field}
                  inputRef={field.ref}
                  ref={null}
                />
              )}
            />
          </Grid>
          <Divider
            flexItem
            orientation="vertical"
            sx={{ marginRight: '16px' }}
          />
          <Grid item>
            <Button
              variant="contained"
              onClick={() => openCreateProjectModal(true)}
            >
              Nouveau
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default HeaderProject
