import { FormControlLabel, Stack, Switch, Typography } from '@mui/material'
import SelectMultiple from 'components-ui/SelectMultiple'
import { selectOptions } from 'components-ui/SelectMultiple/type'
import { PartyTypeLabel } from 'constants/enum'
import { map } from 'lodash'
import * as React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { myProjectState } from '../../atoms'
import { partyType } from '../type'
import { useQueriesCustom } from './queries'
import { formatOutputOS } from './utils'

const maxTotalItem = 100
const nbItemsLoadMore = 10

const SelectionPartyInProgressProject = () => {
  const setMyProject = useSetRecoilState(myProjectState)
  const myProject = useRecoilValue(myProjectState)
  const [paramsQuery, setParamsQuery] = React.useState({
    searchText: '',
    size: nbItemsLoadMore,
  })

  const { responseOrganization, responsePerson } = useQueriesCustom({
    myProject,
    ...paramsQuery,
  })

  const listPropsOfSelect = {
    organization: {
      key: 'organization' as partyType,
      label: PartyTypeLabel.ORGANIZATION,
      optionsWanted: formatOutputOS(responseOrganization?.data) || [],
      placeholder: PartyTypeLabel.ORGANIZATION,
      totalValue: responseOrganization?.totalValue,
    },
    person: {
      key: 'person' as partyType,
      label: PartyTypeLabel.PERSON,
      optionsWanted: formatOutputOS(responsePerson?.data) || [],
      placeholder: PartyTypeLabel.PERSON,
      totalValue: responsePerson?.totalValue,
    },
  }

  const onChangeSelect = (
    newSelectedOptions: selectOptions[],
    key: partyType,
  ) => {
    if (newSelectedOptions) {
      setMyProject((curr) => ({
        ...curr,
        party: {
          ...curr.party,
          [key]: newSelectedOptions,
        },
      }))
    }
  }

  const loadMore = ({
    morePageSize,
    reset,
  }: {
    morePageSize: number
    reset: boolean
  }) => {
    const newPageSize = reset ? morePageSize : paramsQuery.size + morePageSize
    setParamsQuery((old) => ({ ...old, size: newPageSize }))
  }

  return (
    <Stack p={2} spacing={1}>
      <Typography fontWeight={700}>Choisissiez votre ciblage</Typography>
      {map(listPropsOfSelect, (props) => {
        return (
          <Stack direction="row" key={props.label}>
            <SelectMultiple
              disabled={props.optionsWanted.length === 0}
              helperText="Max 100 élements"
              label={props.label}
              loadMore={loadMore}
              nbItemsLoadMore={nbItemsLoadMore}
              options={props.optionsWanted}
              optionsSelected={myProject.party[props.key] || []}
              placeholder={props.placeholder}
              totalRecords={props.totalValue}
              onChangeSelect={(newSelectedOptions: selectOptions[]) =>
                onChangeSelect(newSelectedOptions, props.key)
              }
              updateOptionsWanted={(searchText: string) =>
                setParamsQuery({ searchText, size: nbItemsLoadMore })
              }
            />
            <FormControlLabel
              label="Tous"
              labelPlacement="end"
              sx={{ m: 1 }}
              control={
                <Switch
                  onChange={(event) => {
                    setMyProject((curr) => ({
                      ...curr,
                      party: {
                        ...curr.party,
                        [props.key]: event.target.checked
                          ? props.optionsWanted
                          : [],
                      },
                    }))
                  }}
                  checked={
                    props.totalValue !== 0 &&
                    props.totalValue === myProject.party[props.key]?.length
                  }
                />
              }
              disabled={
                props.totalValue === 0 || props.totalValue > maxTotalItem
              }
            />
          </Stack>
        )
      })}
    </Stack>
  )
}
export default SelectionPartyInProgressProject
