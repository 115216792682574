import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material'
import neutral from 'themes/colors/neutral'

export const StyledAccordion = styled(Accordion)(() => ({
  '& .MuiAccordionSummary-root .MuiSvgIcon-root': {
    color: neutral[900],
  },
  '& .MuiButtonBase-root': {
    '& .MuiAccordionSummary-content': {
      borderBottom: `1px solid ${neutral[300]}`,
      margin: 0,
    },
    minHeight: '35px',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '&:before': {
    display: 'none',
  },
  backgroundColor: 'transparent',
}))

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: 0,
}))

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
}))
