import { Stack, Typography } from '@mui/material'
import { IconButtonOutlined } from 'components-ui/IconButton/styles'
import { UserStatus } from 'constants/enum'
import {
  CirclePlayIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from 'lucide-react'
import { DateTime } from 'luxon'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import authService from 'services/auth.service'
import permissionsService, {
  ALLOWED_ACTION_MANAGE_USER,
  ALLOWED_ACTION_UPDATE_USER,
} from 'services/permissions.service'
import { UserType } from 'types/user'

import withDrawer, {
  TYPE_CONFIRM_DRAWER_DELETE,
  TYPE_CONFIRM_DRAWER_STATUS_DISABLED,
  TYPE_CONFIRM_DRAWER_STATUS_ENABLED,
} from '../../hoc-drawer'
import { useMutationsCustom } from '../../mutations'

const AsideUsersAdminProfile = ({
  resetStateConfirmDrawer,
  setConfirmDrawerState,
  user,
}: {
  resetStateConfirmDrawer: any
  setConfirmDrawerState: any
  user: UserType | undefined
}) => {
  const allowedUpdate = permissionsService.getPermission(
    ALLOWED_ACTION_UPDATE_USER,
  )
  const isAdmin = permissionsService.getPermission(ALLOWED_ACTION_MANAGE_USER)
  const currentUser = authService.getCurrentUser()

  const itsMe = currentUser?.id === user?.id
  const navigate = useNavigate()

  const { mutateDelete, mutateUpdate } = useMutationsCustom({})

  const handleUpdateUser = () => {
    isAdmin ? navigate(`/admin/users/${user?.id}`) : navigate(`/profile/edit`)
  }

  const handleUserEnabled = () => {
    if (user?.id) {
      setConfirmDrawerState({
        onSubmit: async () => {
          await mutateUpdate({
            data: { status: UserStatus.ENABLED },
            id: user?.id,
          })
          resetStateConfirmDrawer()
        },
        open: true,
        type: TYPE_CONFIRM_DRAWER_STATUS_ENABLED,
        userName: `${user.firstname} ${user.name}`,
      })
    }
  }

  const handleUserDisabled = () => {
    if (user?.id) {
      setConfirmDrawerState({
        onSubmit: async () => {
          await mutateUpdate({
            data: { status: UserStatus.DISABLED },
            id: user?.id,
          })
          resetStateConfirmDrawer()
        },
        open: true,
        type: TYPE_CONFIRM_DRAWER_STATUS_DISABLED,
        userName: `${user.firstname} ${user.name}`,
      })
    }
  }

  const handleUserDeleted = () => {
    if (user?.id) {
      setConfirmDrawerState({
        onSubmit: async () => {
          await mutateDelete({ id: user?.id })
          await resetStateConfirmDrawer()
          navigate(`/admin/users`)
        },
        open: true,
        type: TYPE_CONFIRM_DRAWER_DELETE,
        userName: `${user.firstname} ${user.name}`,
      })
    }
  }

  return (
    <Stack
      direction="column"
      display="flex"
      flexGrow={1}
      maxWidth={350}
      p={3}
      width={350}
    >
      <Stack spacing={2}>
        <Stack>
          <Typography>Prénom</Typography>
          <Typography variant="subtitle1">{user?.firstname}</Typography>
        </Stack>
        <Stack>
          <Typography>Nom</Typography>
          <Typography variant="subtitle1">{user?.name}</Typography>
        </Stack>
        <Stack>
          <Typography>Rôle</Typography>
          <Typography variant="subtitle1">{user?.role?.name}</Typography>
        </Stack>
        <Stack>
          <Typography>Adresse mail</Typography>
          <Typography variant="subtitle1">{user?.email}</Typography>
        </Stack>
        {user?.createdAt && (
          <Stack>
            <Typography>Créé {user?.creator ? 'par' : 'le'}</Typography>
            <Typography variant="subtitle1">
              {user?.creator?.firstname} {user?.creator?.name}
            </Typography>
            <Typography variant="subtitle1">
              Le{' '}
              {DateTime.fromISO(user.createdAt.toString()).toFormat(
                'dd/MM/yyyy HH:mm:ss',
              )}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        mb={2}
        mt="auto"
        spacing={2}
      >
        {allowedUpdate && (
          <IconButtonOutlined color="primary" onClick={handleUpdateUser}>
            <PencilIcon />
          </IconButtonOutlined>
        )}
        {isAdmin && (
          <>
            {user?.status === UserStatus.ENABLED && !itsMe && (
              <IconButtonOutlined color="primary" onClick={handleUserDisabled}>
                <XCircleIcon />
              </IconButtonOutlined>
            )}
            {user?.status === UserStatus.DISABLED && !itsMe && (
              <IconButtonOutlined color="primary" onClick={handleUserEnabled}>
                <CirclePlayIcon />
              </IconButtonOutlined>
            )}
            {!user?.role.lock && !itsMe && (
              <IconButtonOutlined color="error" onClick={handleUserDeleted}>
                <TrashIcon />
              </IconButtonOutlined>
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default withDrawer(AsideUsersAdminProfile)
