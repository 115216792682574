import { Typography } from '@mui/material'
import { ReactComponent as DeleteImage } from 'assets/images/delete.svg'
import ConfirmDrawer from 'components/ConfirmDrawer'
import React, { ElementType } from 'react'

export const TYPE_CONFIRM_DRAWER_DELETE = 'DELETE'

const initialStateConfirmDrawer = {
  email: '' as string | string[],
  name: '' as string | string[],
  onSubmit: null as any,
  open: false,
  type: '',
}

const withDrawer = (BaseComponent: ElementType) => {
  return function EnhancedComponent(props: any) {
    const [confirmDrawerState, setConfirmDrawerState] = React.useState(
      initialStateConfirmDrawer,
    )
    const resetStateConfirmDrawer = () =>
      setConfirmDrawerState(initialStateConfirmDrawer)

    return (
      <>
        <BaseComponent
          {...props}
          resetStateConfirmDrawer={resetStateConfirmDrawer}
          setConfirmDrawerState={setConfirmDrawerState}
        />
        <ConfirmDrawer
          modeTwoButtons
          bodySecondaryText="Cette action est irréversible !"
          Image={<DeleteImage />}
          titleText="Supprimer rôle"
          onSubmit={confirmDrawerState.onSubmit}
          onSubmitColor="error"
          onSubmitText="Supprimer"
          handleCloseOpenDrawer={resetStateConfirmDrawer}
          bodyPrimaryText={
            <Typography>
              Le rôle <b>{confirmDrawerState.name}</b> sera supprimé
            </Typography>
          }
          isOpenDrawer={
            confirmDrawerState.open &&
            confirmDrawerState.type === TYPE_CONFIRM_DRAWER_DELETE
          }
        />
      </>
    )
  }
}

export default withDrawer
