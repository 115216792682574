import Header from 'components/Header'
import { PartyType } from 'constants/enum'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { useAxios } from 'services/axios.service'

import OverviewInProgressProject from '../InProgress/Overview'
import { myProjectState } from '../atoms'
import {
  formatAreasToSelectOptions,
  formatPartiesToSelectOptions,
  getAreaType,
} from '../utils'

const OverviewDataSheetProject = () => {
  const { id } = useParams()
  const setMyProject = useSetRecoilState(myProjectState)

  const { response: project } = useAxios({
    url: `/projects/${id}?${new URLSearchParams({
      crudQuery: JSON.stringify({
        joins: [
          'regions',
          'counties',
          'cities',
          'parties',
          'speciality',
          'cpts',
          'dac',
          'tvs',
          'epci',
        ],
        where: {
          id,
        },
      }),
    })}`,
  })

  if (!project) {
    return <></>
  }

  const organizationList = project.parties.filter(
    (project: any) => project.partyType === PartyType.ORGANIZATION,
  )
  const personList = project.parties.filter(
    (project: any) => project.partyType === PartyType.PERSON,
  )

  setMyProject({
    areaType: getAreaType(project),
    name: project.name,
    options: {
      category: [],
      categoryOrganization: [],
      categoryPerson: [],
      cityCode: formatAreasToSelectOptions(project.cities),
      countryCode: [],
      countyCode: formatAreasToSelectOptions(project.counties),
      cptsCode: formatAreasToSelectOptions(project.cpts),
      dacCode: formatAreasToSelectOptions(project.dac),
      epciCode: formatAreasToSelectOptions(project.epci),
      expertise: [],
      journey: [],
      regionCode: formatAreasToSelectOptions(project.regions),
      tvsCode: formatAreasToSelectOptions(project.tvs),
    },
    party: {
      organization: formatPartiesToSelectOptions(organizationList),
      person: formatPartiesToSelectOptions(personList),
    },
    speciality: {
      label: project.speciality.name,
      value: project.speciality.code,
    },
  })

  const description = `${
    organizationList.length + personList.length
  } établissements/PS`

  return (
    <>
      <Header
        arrowBack
        rowLeftDescription={description}
        rowLeftTitle={project.name}
      />
      <OverviewInProgressProject />
    </>
  )
}
export default OverviewDataSheetProject
