import type { AxiosRequestConfig } from 'axios'

import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import authService from './auth.service'

export const baseURL = process.env.REACT_APP_API_URI
export const getHeadersAuth = () => {
  const user = authService.getCurrentUser()

  if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken }
  } else {
    return { Authorization: 'Bearer none' }
  }
}

axios.defaults.baseURL = baseURL
axios.defaults.headers.common = {
  ...getHeadersAuth(),
  'Api-Version': 1,
}

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    throw error.response.data
  },
)

type extraParams = { expend: boolean }

export const useAxios = (
  axiosParams: AxiosRequestConfig & { delay?: number; noFetchInit?: boolean },
): {
  error: any
  loadMoreContent: any
  loading: boolean
  loadingMoreContent: boolean
  page: number
  refresh: any
  response: any
} => {
  const response = useRef() as any
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [loadingMoreContent, setLoadingMoreContent] = useState(false)
  const [page, setPage] = useState(1)

  const fetchData = async (
    params: AxiosRequestConfig & { delay?: number; noFetchInit?: boolean },
    extraParams?: extraParams,
  ) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, params.delay))

      setError(undefined)
      const result = await axios.request({ ...params, ...extraParams })
      let newResult = result?.data

      if (extraParams?.expend && response?.current?.data) {
        newResult.data = [
          ...(response?.current.data || []),
          ...(result?.data?.data || []),
        ]
      }
      const size = params?.params?.size
      const from = params?.params?.from || 0
      if (size) {
        setPage((from + size) / size)
      }

      if (!result?.data) newResult = result

      response.current = newResult
    } catch (error: any) {
      response.current = undefined
      setError(error.response?.data)
    }
  }

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      await fetchData(axiosParams)
      setLoading(false)
    }
    if (!axiosParams.noFetchInit) {
      fetch()
    } else {
      setLoading(false)
    }
  }, [])

  const refresh = async (axiosParamsExtra: any, extraParams: any) => {
    setLoading(true)
    await fetchData({ ...axiosParams, ...axiosParamsExtra }, extraParams)
    setLoading(false)
    return { error, response: response.current }
  }

  const loadMoreContent = async (axiosParamsExtra: any, extraParams: any) => {
    const axiosParamsCombined = { ...axiosParams, ...axiosParamsExtra }
    const { params } = axiosParamsCombined

    if (
      response?.current?.totalValue + params.size >
      params.from + params.size
    ) {
      setLoadingMoreContent(true)
      await fetchData({ ...axiosParams, ...axiosParamsExtra }, extraParams)
      setLoadingMoreContent(false)
    }
  }

  return {
    error,
    loadMoreContent,
    loading,
    loadingMoreContent,
    page,
    refresh,
    response: response.current,
  }
}

export const fecthAxios = async (url: string, config?: AxiosRequestConfig) => {
  const result = await axios(url, config)
  return result.data
}
