import CloseIcon from '@mui/icons-material/Close'
import { Drawer, IconButton, Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import neutral from 'themes/colors/neutral'

const DrawerStandard = ({
  children,
  handleCloseOpenDrawer,
  isOpenDrawer,
  titleText,
}: {
  children: ReactNode
  handleCloseOpenDrawer: any
  isOpenDrawer: boolean
  titleText: string
}) => {
  return (
    <Drawer
      anchor="bottom"
      open={isOpenDrawer}
      onClose={handleCloseOpenDrawer}
      PaperProps={{
        sx: {
          borderRadius: '5px 5px 0 0',
          margin: 'auto',
          maxWidth: 420,
          minHeight: 300,
        },
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        p={1.5}
        sx={{ backgroundColor: neutral[100] }}
      >
        <Typography fontWeight={700} variant="subtitle1">
          {titleText}
        </Typography>
        <IconButton aria-label="remove popup" onClick={handleCloseOpenDrawer}>
          <CloseIcon />
        </IconButton>
      </Stack>

      {children}
    </Drawer>
  )
}

export default DrawerStandard
