import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/material'
import { ReactComponent as LogoFullDark } from 'assets/images/logoFullDark.svg'
import SplitScreenLayout from 'components/Layout/SplitScreen'
import PasswordCongrat from 'components/PasswordCongrat'
import InputStartIcon from 'components-ui/InputStartIcon'
import { AtSign } from 'lucide-react'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import passwordService from 'services/password.service'
import { sleep } from 'utils/sleep'
import { z } from 'zod'

const validationSchema = z.object({
  email: z
    .string()
    .email("L'adresse email est invalide")
    .min(1, "L'adresse email est obligatoire"),
})
type ValidationSchema = z.infer<typeof validationSchema>

const ForgotPasswordPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const rightElementFinished = (
    <PasswordCongrat text="Un lien vous à été envoyé à l’adresse mail si celle-ci existe." />
  )

  const { control, formState, handleSubmit } = useForm<ValidationSchema>({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'all',
    resolver: zodResolver(validationSchema),
  })

  const onSubmit = async (data: ValidationSchema) => {
    await sleep(700)
    try {
      await passwordService.forgotPassword(data.email)
    } catch (err: any) {
      enqueueSnackbar(err?.message, {
        variant: 'error',
      })
    }
  }

  const rightElement = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={4}
        sx={{
          width: 430,
        }}
      >
        <Box sx={{ margin: 'auto!important', width: 230 }}>
          <LogoFullDark />
          <Typography
            align="center"
            color="primary"
            sx={{ fontWeight: 700 }}
            variant="body1"
          >
            Lutter contre les déserts médicaux
          </Typography>
        </Box>
        <Stack>
          <Typography fontWeight={700} variant="subtitle1">
            Mot de passe oublié
          </Typography>
          <Typography>
            Entrer votre adresse mail liée au compte afin de vous envoyer le
            lien de réinitialisation de mot de passe.
          </Typography>
        </Stack>
        <Box sx={{ mb: '3rem', mt: '2.5rem', mx: 'auto' }}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <InputStartIcon
                fullWidth
                error={
                  !!formState.errors.email && formState.touchedFields.email
                }
                {...field}
                Icon={AtSign}
                inputRef={field.ref}
                label={'Adresse mail'}
                variant="filled"
              ></InputStartIcon>
            )}
          />
        </Box>

        <Stack>
          <LoadingButton
            fullWidth
            disabled={!formState.isValid || formState.isSubmitting}
            loading={formState.isSubmitting}
            type="submit"
            variant="contained"
          >
            Envoyer le lien
          </LoadingButton>
          <Button
            fullWidth
            sx={{ mt: 1 }}
            variant="text"
            onClick={() => navigate(-1)}
          >
            Retour
          </Button>
        </Stack>
      </Stack>
    </form>
  )

  return (
    <SplitScreenLayout
      description="Login"
      title="MedTrucks"
      rightElement={
        formState.isSubmitted && !formState.isLoading
          ? rightElementFinished
          : rightElement
      }
    ></SplitScreenLayout>
  )
}

export default ForgotPasswordPage
