import type { Question, QuestionCategory } from 'types/questionnaire'

import AddIcon from '@mui/icons-material/AddRounded'
import AutorenewIcon from '@mui/icons-material/AutorenewRounded'
import CheckIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/InfoRounded'
import LensIcon from '@mui/icons-material/LensOutlined'
import RemoveIcon from '@mui/icons-material/RemoveRounded'
import { Box, Divider, Grid, Paper, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { map } from 'lodash'
import * as React from 'react'
import { useAxios } from 'services/axios.service'
import neutral from 'themes/colors/neutral'
import { SurveyAnswer } from 'types/survey'

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './styles'

const QuestionsList = ({
  answers,
  currentQuestion,
  handleSelectQuestion,
  idQuestionnaire,
  list,
  partyId,
}: {
  answers: SurveyAnswer[]
  currentQuestion: Question | null
  handleSelectQuestion: any
  idQuestionnaire: string | undefined
  list: QuestionCategory[]
  partyId: string | undefined
}) => {
  const [expanded, setExpanded] = React.useState<{ [key: string]: boolean }>({})

  const { refresh: refreshInfos, response: infos } = useAxios({
    url: `questionnaires/${idQuestionnaire}/parties/${partyId}/infos`,
  })

  /* Open accordion category when we switch of the question
     Update infos when we switch of current question */
  React.useEffect(() => {
    if (currentQuestion) {
      refreshInfos()
      setExpanded({
        ...expanded,
        [currentQuestion?.subcategory?.category?.label]: true,
      })
    }
  }, [currentQuestion])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded({ ...expanded, [panel]: isExpanded ? !!panel : false })
    }

  const returnIcon = (id: number) => {
    if (currentQuestion?.id === id) {
      return (
        <AutorenewIcon
          color="primary"
          fontSize="small"
          sx={{
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
            animation: 'spin 2s linear infinite',
          }}
        />
      )
    }
    if (answers[id]) {
      return <CheckIcon color="success" fontSize="small" />
    }
    return <LensIcon color="primary" fontSize="small" />
  }

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: neutral[100],
        maxHeight: 'calc(100vh - 120px);',
        ml: 2,
        overflowY: 'auto',
        p: 2,
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item display="flex" justifyContent="space-between">
          <Typography fontWeight={700} variant="subtitle1">
            Progression
          </Typography>
          <Typography fontWeight={700} variant="subtitle1">
            {infos?.progressValue}%
          </Typography>
        </Grid>
        <Divider />
        <Grid item display="flex" justifyContent="space-between">
          <Typography fontWeight={700} variant="body2">
            Questions terminées
          </Typography>
          <Typography variant="body2">
            {infos?.answersCount} / {infos?.questionCount}
          </Typography>
        </Grid>
        <Grid item mt={1}>
          {map(list, (category, key) => {
            return (
              <StyledAccordion
                disableGutters
                elevation={0}
                expanded={expanded[category?.label] === true}
                key={key}
                onChange={handleChange(category?.label)}
              >
                <StyledAccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ mt: 1.5 }}
                  expandIcon={
                    expanded[category?.label] ? (
                      <RemoveIcon fontSize="small" />
                    ) : (
                      <AddIcon fontSize="small" />
                    )
                  }
                >
                  <Typography fontWeight={700} variant="subtitle1">
                    {category?.label}
                  </Typography>
                </StyledAccordionSummary>
                {map(category?.subcategories, (subcategory, key) => {
                  return (
                    <StyledAccordionDetails key={key}>
                      <Typography
                        color="secondary"
                        fontWeight={700}
                        sx={{ my: 0.5 }}
                        variant="body2"
                      >
                        {subcategory?.label}
                      </Typography>
                      {map(subcategory?.questions, (question, key) => {
                        return (
                          <Box
                            key={key}
                            onClick={() =>
                              handleSelectQuestion({
                                ...question,
                                subcategory: { ...subcategory, category },
                              })
                            }
                            sx={{
                              alignItems: 'center',
                              cursor: 'pointer',
                              display: 'grid',
                              gridAutoFlow: 'column',
                              justifyContent: 'space-between',
                            }}
                          >
                            {returnIcon(question.id)}
                            <Tooltip disableInteractive title={question?.label}>
                              <Typography
                                noWrap
                                mx={1}
                                my={0.5}
                                textOverflow="ellipsis"
                              >
                                {question?.label}
                              </Typography>
                            </Tooltip>
                            <Tooltip arrow title={question?.description}>
                              <InfoIcon color="primary" fontSize="small" />
                            </Tooltip>
                          </Box>
                        )
                      })}
                    </StyledAccordionDetails>
                  )
                })}
              </StyledAccordion>
            )
          })}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default QuestionsList
