import { Button, Divider, Stack } from '@mui/material'
import { isEqual } from 'lodash'
import React from 'react'
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { mySearchType } from 'types/recoils/parties-search'

import Clear from './Clear'
import GeoFilters from './GeoFilters'
import OtherFilters from './OtherFilters'
import SearchFilter from './SearchFilter'
import TypeFilters from './TypeFilters'

const Filters = ({
  defaultMyFiltersState,
  myFiltersState,
  nextMyFiltersState,
  refetchSubmit,
}: {
  defaultMyFiltersState: mySearchType
  myFiltersState: RecoilState<mySearchType>
  nextMyFiltersState: RecoilState<mySearchType>
  refetchSubmit: any
}) => {
  const setFilters = useSetRecoilState<mySearchType>(myFiltersState)
  const nextFilters = useRecoilValue(nextMyFiltersState)
  const filtersParams = useRecoilValue(myFiltersState)

  return (
    <Stack
      sx={{
        height: 'calc(100% - 85px)',
        overflow: 'auto',
      }}
    >
      <Clear
        defaultMyFiltersState={defaultMyFiltersState}
        myFiltersState={myFiltersState}
        nextMyFiltersState={nextMyFiltersState}
        refetchSubmit={refetchSubmit}
      />
      <Divider />
      <SearchFilter
        myFiltersState={myFiltersState}
        nextMyFiltersState={nextMyFiltersState}
        refetchSubmit={refetchSubmit}
      />
      <Divider />
      <Stack direction="column">
        <GeoFilters
          defaultMyFiltersState={defaultMyFiltersState}
          myFiltersState={myFiltersState}
          nextMyFiltersState={nextMyFiltersState}
          refetchSubmit={refetchSubmit}
        />
        <Divider />
        <TypeFilters nextMyFiltersState={nextMyFiltersState} />
        <OtherFilters nextMyFiltersState={nextMyFiltersState} />
      </Stack>

      <Button
        fullWidth
        disabled={isEqual(nextFilters.options, filtersParams.options)}
        variant="contained"
        onClick={() => {
          setFilters(nextFilters)
          refetchSubmit()
        }}
        sx={{
          borderRadius: 0,
          bottom: 0,
          height: '50px',
          position: 'absolute',
        }}
      >
        Valider
      </Button>
    </Stack>
  )
}

export default Filters
