import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'

export const maxTotalItem = 150

export const useQueriesPassword = () => {
  const {
    isError: isErrorMutateResetPassword,
    isPending: isPendingMutateResetPassword,
    mutate: mutateResetPassword,
  } = useMutation({
    mutationFn: ({
      password,
      passwordConfirm,
      resetPasswordToken,
    }: {
      password: string
      passwordConfirm: string
      resetPasswordToken: string
    }) =>
      axios(`/auth/passwords/reset-password/${resetPasswordToken}`, {
        data: {
          password,
          passwordConfirm,
        },
        method: 'POST',
      }),
    mutationKey: ['users-password-reset'],
    onError: (error: any) => {
      let errorMessage
      if (error?.statusCode === 401) {
        errorMessage = 'Ce lien a expiré'
      } else {
        errorMessage = `Une erreur s'est produite`
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    },
  })

  return {
    isErrorMutateResetPassword,
    isPendingMutateResetPassword,
    mutateResetPassword,
  }
}
