import { Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material'
import { bbox } from '@turf/turf'
import { cloneDeep, compact, get, isEmpty, omit, set } from 'lodash'
import { formattedFilterParams, getIfAreaSelected } from 'pages/Core/helper'
import { useEffect, useState } from 'react'
import * as React from 'react'
import { MapRef, MapStyleDataEvent } from 'react-map-gl'
import { useRecoilValue } from 'recoil'
import { myPartyListType, mySearchType } from 'types/recoils/parties-search'

import { myPartyListState, mySearchState } from '../../atoms'
import MAP_STYLE from './mapStyle'

const defaultMapStyle = cloneDeep(MAP_STYLE)
const defaultLayers = defaultMapStyle.layers

const schemaLayerSelectedByAreaType = {
  cityCode: { id: 'cities', source: 'cities' },
  countryCode: { id: 'country', source: 'country' },
  countyCode: { id: 'counties', source: 'counties' },
  cptsCode: { id: 'cpts', source: 'cpts' },
  dacCode: { id: 'dac', source: 'dac' },
  epciCode: { id: 'epci', source: 'epci' },
  regionCode: { id: 'regions', source: 'regions' },
  tvsCode: { id: 'tvs', source: 'tvs' },
}
const layersTerritory = [
  'regions',
  'counties',
  'cities',
  'cpts',
  'dac',
  'tvs',
  'epci',
]

const categories = ['Etablissements / PS', 'Zonage ARS', 'APL Omnipraticiens']

// Layer id patterns by category
const layerSelector = {
  'APL Omnipraticiens': /apl/,
  'Etablissements / PS': /parties/,
  'Zonage ARS': /zonage-ars/,
} as any

const layersToBeFilteredSelectedInfos = {
  apl: {
    matchOptionsWithProperties: {
      cityCode: 'cityCode',
      countyCode: 'countyCode',
      cptsCode: 'cptsCode',
      dacCode: 'dacCode',
      epciCode: 'epciCode',
      regionCode: 'regionCode',
      tvsCode: 'tvsCode',
    },
    nameLayer: 'apl',
  },
  'cities-selected': {
    matchOptionsWithProperties: { cityCode: 'code' },
    nameLayer: 'cities-selected',
  },
  'counties-selected': {
    matchOptionsWithProperties: { countyCode: 'code' },
    nameLayer: 'counties-selected',
  },
  'cpts-selected': {
    matchOptionsWithProperties: { cptsCode: 'code' },
    nameLayer: 'cpts-selected',
  },
  'dac-selected': {
    matchOptionsWithProperties: { dacCode: 'code' },
    nameLayer: 'dac-selected',
  },
  'epci-selected': {
    matchOptionsWithProperties: { epciCode: 'code' },
    nameLayer: 'epci-selected',
  },
  parties: {
    matchOptionsWithProperties: {
      category: 'category',
      cityCode: 'cityCode',
      countyCode: 'countyCode',
      cptsCode: 'cptsCode',
      dacCode: 'dacCode',
      epciCode: 'epciCode',
      expertise: 'expertise',
      journey: 'journey',
      name: 'name',
      partyType: 'partyType',
      regionCode: 'regionCode',
      tvsCode: 'tvsCode',
    },
    nameLayer: 'parties',
  },
  'regions-selected': {
    matchOptionsWithProperties: { regionCode: 'code' },
    nameLayer: 'regions-selected',
  },
  'tvs-selected': {
    matchOptionsWithProperties: { tvsCode: 'code' },
    nameLayer: 'tvs-selected',
  },
} as any

const getMapStyle = ({
  myPartyList,
  mySearch,
  visibility,
}: {
  myPartyList: myPartyListType
  mySearch: mySearchType
  visibility: any
}) => {
  const filterApplied = {
    ...omit(formattedFilterParams(mySearch.options), 'search'),
    ...myPartyList,
  } as any
  const areaType = mySearch.areaType

  const layers = compact(
    defaultLayers
      .map((layer: any) => {
        // on affiche uniquement le layer geo en fonction du select areaType
        const id = get(layer, 'id')
        const metadata = get(layer, 'metadata')
        if (
          !layersTerritory.includes(id) ||
          (layersTerritory.includes(id) && areaType === metadata)
        ) {
          return layer
        }
      })
      .filter((layer: any) => {
        const id = get(layer, 'id')
        return categories.every(
          (name: string) => visibility[name] || !layerSelector[name].test(id),
        )
      })
      .map((layer: any) => {
        if (!layer) return
        const id = get(layer, 'id')

        //on veut display les features de la liste layersToBeFilteredSelectedInfos
        const layerToFiltered = Object.keys(layersToBeFilteredSelectedInfos)
        if (layerToFiltered && layerToFiltered.includes(id)) {
          const layersInfos = layersToBeFilteredSelectedInfos[id]

          if (layersInfos.nameLayer === id) {
            const { matchOptionsWithProperties } = layersInfos
            const options = Object.keys(matchOptionsWithProperties)

            const matches = options.reduce((acc: any, option: string) => {
              const propertie = matchOptionsWithProperties[option]
              const value = filterApplied[option]

              if (!isEmpty(value))
                return [...acc, ['any', ['in', propertie, ...value]]]

              return acc
            }, [])

            if (isEmpty(matches)) return layer

            return {
              ...layer,
              filter: ['all', ...matches],
            }
          }
        }
        return layer
      }),
  )

  const newLayers = set(defaultMapStyle, 'layers', layers)

  return newLayers
}

const zoomOnFeaturesAreaSelected = (
  mySearch: mySearchType,
  mapRef: React.RefObject<MapRef> | null,
  hasAreaSelected: boolean,
) => {
  const zoom = (event: MapStyleDataEvent & any) => {
    const layerAreaSelected = schemaLayerSelectedByAreaType[mySearch.areaType]
    const filter = event.target.getFilter(`${layerAreaSelected.id}-selected`)

    const featuresAreaSelected =
      event.target.querySourceFeatures(layerAreaSelected.id, {
        filter,
        sourceLayer: layerAreaSelected.source,
      }) || []

    if (
      hasAreaSelected &&
      featuresAreaSelected &&
      featuresAreaSelected?.length > 0
    ) {
      const poly = bbox({
        features: featuresAreaSelected?.map((feature: any) => feature) || [],
        type: 'FeatureCollection',
      }) as [number, number, number, number]
      setTimeout(() => {
        event.target?.fitBounds(poly, { maxZoom: 13 })
      }, 300)
    }
  }

  mapRef?.current?.on('styledata', zoom)
  mapRef?.current?.on('load', zoom)
}

const StyleControls = ({
  mapRef,
  setMapStyle,
  updateLegend,
}: {
  mapRef: React.RefObject<MapRef> | null
  setMapStyle: any
  updateLegend: any
}) => {
  const [visibility, setVisibility] = useState({
    'APL Omnipraticiens': false,
    'Etablissements / PS': true,
    'Zonage ARS': false,
  }) as any

  const mySearch = useRecoilValue(mySearchState)
  const myPartyList = useRecoilValue(myPartyListState)
  const hasAreaSelected = getIfAreaSelected(mySearch.options)

  useEffect(() => {
    const newStyle = getMapStyle({
      myPartyList,
      mySearch,
      visibility,
    })

    setMapStyle(newStyle)
    zoomOnFeaturesAreaSelected(mySearch, mapRef, hasAreaSelected)
    updateLegend()
  }, [visibility, mySearch, myPartyList])

  const onVisibilityChange = (e: any) => {
    setVisibility({ ...visibility, [e.target.value]: e.target.checked })
  }

  return (
    <Paper sx={{ margin: 2, padding: 2, position: 'absolute', zIndex: 1 }}>
      <FormGroup onChange={onVisibilityChange}>
        {categories.map((name) => (
          <FormControlLabel
            control={<Checkbox checked={visibility[name]} />}
            key={name}
            label={name}
            value={name}
          />
        ))}
      </FormGroup>
    </Paper>
  )
}

export default StyleControls
