import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { UserType } from 'types/user'

export const maxTotalItem = 150

export const useMutationsCustom = ({
  successCallback,
}: {
  successCallback?: any
}) => {
  const { isPending: isPendingCreate, mutate: mutateCreate } = useMutation({
    mutationFn: ({ data }: { data: Partial<UserType> }) =>
      axios(`/users/create-password-from-mail`, { data, method: 'POST' }),
    mutationKey: ['users-create'],
    onError: (error: any, { data }) => {
      let errorMessage
      if (error?.statusCode === 409) {
        errorMessage = 'Un utilisateur existe déjà avec cette adresse mail'
      } else {
        errorMessage = `Erreur lors de la création de l'utilisateur ${data.firstname} ${data.name}`
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
      return error
    },
    onSuccess: (success, { data }) => {
      const successMessage = `Utilisateur ${data.firstname} ${data.name} à été créé`
      enqueueSnackbar(successMessage, {
        variant: 'success',
      })
      if (successCallback) successCallback()
      return success
    },
  })
  const { isPending: isPendingUpdate, mutate: mutateUpdate } = useMutation({
    mutationFn: ({ data, id }: { data: Partial<UserType>; id: number }) =>
      axios(`/users/${id}`, { data, method: 'PATCH' }),
    mutationKey: ['users-patch'],
    onError: (error, { id }) => {
      const errorMessage = `Erreur lors de la mise a jour de l'utilisateur n° ${id}`
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    },
    onSuccess: (data, { id }) => {
      const successMessage = `Utilisateur n° ${id} mis à jour`
      enqueueSnackbar(successMessage, {
        variant: 'success',
      })
      if (successCallback) successCallback()
    },
  })

  const { isPending: isPendingDelete, mutate: mutateDelete } = useMutation({
    mutationFn: async ({ id, ids }: { id?: number; ids?: Array<number> }) => {
      if (id) return axios(`/users/${id}`, { method: 'DELETE' })
      if (ids) return axios(`/users`, { data: { ids }, method: 'DELETE' })
    },
    mutationKey: ['users-list-delete'],
    onError: (error, { id, ids }) => {
      const errorMessage = `Erreur lors de la suppression de l'utilsateur n° ${id || ids}`
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    },
    onSuccess: (data, { id, ids }) => {
      const successMessage = `Suppression de l'utilisateur n° ${id || ids} réussi`
      enqueueSnackbar(successMessage, {
        variant: 'success',
      })
      if (successCallback) successCallback()
    },
  })

  return {
    isPendingCreate,
    isPendingDelete,
    isPendingUpdate,
    mutateCreate,
    mutateDelete,
    mutateUpdate,
  }
}
