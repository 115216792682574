import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import { isNumber } from 'lodash'
import React from 'react'
import primary from 'themes/colors/primary'

const widthCard = '250px'

const CardSummaryInprogressProject = ({
  icon,
  isEditabled,
  label,
  onEdit,
  value,
}: {
  icon: any
  isEditabled: boolean
  label: string
  onEdit: any
  value: number | string
}) => {
  return (
    <>
      <Box p={2} sx={{ width: '100%' }}>
        <Grid container alignItems="center" justifyContent="left" wrap="nowrap">
          <Grid item pr={1.5}>
            {icon}
          </Grid>
          <Grid item flexGrow={1} sx={{ textAlign: 'left' }}>
            <Typography
              noWrap
              flexGrow={1}
              fontWeight="bold"
              sx={{ textTransform: 'Uppercase' }}
              variant="body1"
              width={widthCard}
            >
              {label}
            </Typography>
            <Typography
              noWrap
              color={primary[500]}
              flexGrow={1}
              variant="body2"
              width={widthCard}
            >
              {isNumber(value) ? `${value} élément(s)` : value}
            </Typography>
          </Grid>
          {isEditabled && (
            <Grid item>
              <IconButton onClick={onEdit}>
                <EditNoteOutlinedIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <Divider />
    </>
  )
}

export default CardSummaryInprogressProject
