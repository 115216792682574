import axios from 'axios'
import { UserAccessType } from 'types/user'

class AuthService {
  login = async (email: string, password: string) => {
    const response = await axios.post('/auth/login', {
      email,
      password,
    })
    if (response?.data?.accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data))
      return response.data
    }

    return response
  }

  getCurrentUser(): UserAccessType | undefined {
    const userStr = localStorage.getItem('user')
    return userStr ? JSON.parse(localStorage.getItem('user') || '{}') : ''
  }

  logout() {
    localStorage.removeItem('user')
    window.location.reload()
  }
}

export default new AuthService()
