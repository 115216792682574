export default {
  50: '#FFFCF0',
  100: '#FFF8D8',
  200: '#FFF0B1',
  300: '#FFE58A',
  400: '#FFDA6D',
  500: '#FFC93D',
  600: '#DBA52C',
  700: '#B7841E',
  800: '#936513',
  900: '#7A4E0B',
}
