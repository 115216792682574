import { Box, Divider, Stack, Typography } from '@mui/material'
import ChipFilter from 'components-ui/ChipFilter'
import { selectOptions } from 'components-ui/SelectMultiple/type'
import { filter, isEmpty, map } from 'lodash'
import { labelChipsFilter } from 'pages/Core/Projects/InProgress/constants'
import React from 'react'
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import secondary from 'themes/colors/secondary'
import { FieldParam, mySearchType } from 'types/recoils/parties-search'

const Chips = ({
  myFiltersState,
  nextMyFiltersState,
  refetchSubmit,
}: {
  myFiltersState: RecoilState<mySearchType>
  nextMyFiltersState: RecoilState<mySearchType>
  refetchSubmit: any
}) => {
  const mySearch = useRecoilValue(myFiltersState)
  const { options: filtersParamsOptions } = mySearch
  const setMySearch = useSetRecoilState(myFiltersState)
  const setNextMySearch = useSetRecoilState(nextMyFiltersState)

  const onDeleteFilters = (
    fieldParams: FieldParam,
    filterParam: selectOptions,
  ) => {
    const arrayFieldParams = filtersParamsOptions?.[fieldParams]?.filter(
      (filter: selectOptions) => filter.value !== filterParam.value,
    )

    const newParams = {
      ...mySearch,
      options: {
        ...mySearch.options,
        [fieldParams]: arrayFieldParams,
      },
    }

    setNextMySearch(newParams)
    setMySearch(newParams)
    refetchSubmit()
  }

  return (
    !isEmpty(filter(filtersParamsOptions, (o: any) => o?.length > 0)) && (
      <Box>
        <Stack useFlexGap direction="row" flexWrap="wrap" p={1} spacing={1}>
          {map(
            filtersParamsOptions,
            (arrayFilterParams: selectOptions[], fieldParams: FieldParam) =>
              map(arrayFilterParams, (filterParams, index) => {
                return (
                  filterParams.label && (
                    <ChipFilter
                      index={index}
                      key={index}
                      sx={{ maxWidth: '450px' }}
                      onDelete={() =>
                        onDeleteFilters(fieldParams, filterParams)
                      }
                      title={
                        <Stack direction="row">
                          <Typography color={secondary[800]} fontWeight={700}>
                            {labelChipsFilter[fieldParams]} :
                          </Typography>
                          <Typography fontWeight={700}>
                            &nbsp;{filterParams.label}
                          </Typography>
                        </Stack>
                      }
                    />
                  )
                )
              }),
          )}
        </Stack>
        <Divider />
      </Box>
    )
  )
}

export default Chips
