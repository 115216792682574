import React from 'react'
import { RouterProvider } from 'react-router-dom'
import authService from 'services/auth.service'

import router from './router'

const App = () => {
  const userState = authService.getCurrentUser()

  return <RouterProvider router={router({ userState })} />
}

export default App
