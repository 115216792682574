import { Box, Divider, Grid, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Filters from 'components/Filters/AsideFilters'
import Chips from 'components/Filters/Chips'
import { MRT_PaginationState } from 'material-react-table'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'

import { CONST_COUNTRY } from '../Projects/InProgress/constants'
import { formattedFilterParams } from '../helper'
import Header from './Header'
import TabsDiagnostic from './Tabs'
import {
  defaultMyDiagnosticState,
  myDiagnosticState,
  nextMyDiagnosticState,
} from './atoms'

export const pageSize = 30

const Diagnostics = () => {
  const myDiagnostic = useRecoilValue(myDiagnosticState)

  const [tabState, setTabState] = useState(0)
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize,
  })

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    enabled: myDiagnostic.areaType !== CONST_COUNTRY,
    queryFn: async () => {
      const { data } = await axios.post('/opensearch/search/party', {
        from: pagination.pageSize * pagination.pageIndex,
        myFilters: formattedFilterParams(myDiagnostic.options),
        size: pagination.pageSize,
      })
      return data
    },
    queryKey: [`opensearch-search-party`, myDiagnostic.options, pagination],
  })

  return (
    <Stack height="100vh">
      <Header />
      <Box display="flex" flexGrow={1} sx={{ overflow: 'hidden' }}>
        <Grid container flexDirection="row" m={0} wrap="nowrap">
          <Grid
            item
            direction="column"
            display="flex"
            minWidth="400px"
            position="relative"
            width="400px"
          >
            <Filters
              defaultMyFiltersState={defaultMyDiagnosticState}
              myFiltersState={myDiagnosticState}
              nextMyFiltersState={nextMyDiagnosticState}
              refetchSubmit={refetch}
            />
          </Grid>
          <Divider orientation="vertical" />
          <Grid
            item
            direction="column"
            display="flex"
            flexGrow={1}
            sx={{ overflow: 'hidden', padding: '0px' }}
          >
            <Chips
              myFiltersState={myDiagnosticState}
              nextMyFiltersState={nextMyDiagnosticState}
              refetchSubmit={refetch}
            />
            <TabsDiagnostic
              isLoading={isLoading}
              pagination={pagination}
              response={response}
              setPagination={setPagination}
              setTabState={setTabState}
              tabState={tabState}
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

export default Diagnostics
