import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import axios from 'axios'
import DrawerStandard from 'components-ui/Drawer'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

const typeFeedback = [
  'Bug de l’application',
  'Erreur de donnée',
  "Proposition d'évolution",
  'Amélioration',
  'Autres',
]

const validationSchema = z.object({
  message: z
    .string({ required_error: 'Message obligatoire' })
    .min(10, 'Le message doit contenir au moins 10 caractère(s)'),
  type: z.string(),
})
type ValidationSchema = z.infer<typeof validationSchema>

const DrawerFeedback = ({
  handleCloseOpenDrawer,
  isOpenDrawer,
}: {
  handleCloseOpenDrawer: any
  isOpenDrawer: boolean
}) => {
  const { control, formState, handleSubmit, resetField } =
    useForm<ValidationSchema>({
      criteriaMode: 'all',
      mode: 'all',
      resolver: zodResolver(validationSchema),
    })

  const onSubmit = async (data: ValidationSchema) => {
    try {
      await axios.post('/feedback', data)
      enqueueSnackbar(
        'Merci pour votre retour ! Nous allons en prendre connaissance très prochainement',
        { variant: 'success' },
      )
      resetField('message')
      resetField('type')
      handleCloseOpenDrawer()
    } catch (err: any) {
      enqueueSnackbar(err?.message, {
        variant: 'error',
      })
    }
  }

  return (
    <DrawerStandard
      isOpenDrawer={isOpenDrawer}
      titleText="Faire un retour"
      handleCloseOpenDrawer={handleCloseOpenDrawer}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          display="flex"
          flexDirection="column"
          flexGrow={1}
          m={2}
          spacing={3}
        >
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <FormControl fullWidth variant="filled">
                <InputLabel id="type-select-feedback-label">Type</InputLabel>
                <Select
                  id="standard-type-feedback-input"
                  labelId="type-select-feedback-label"
                  placeholder="Selectionner votre type de retour"
                  {...field}
                  inputRef={field.ref}
                >
                  {typeFeedback.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <TextField
                focused
                multiline
                id="standard-message-feedback-input"
                label="Message"
                placeholder="Votre message"
                rows={6}
                variant="filled"
                {...field}
                inputRef={field.ref}
              />
            )}
          />
          {formState?.errors.message?.message && (
            <Typography color="error">
              {formState?.errors.message?.message}
            </Typography>
          )}
        </Stack>
        <LoadingButton
          fullWidth
          disabled={!formState.isValid || formState.isSubmitting}
          loading={formState.isSubmitting}
          sx={{ borderRadius: 0, height: '50px', marginTop: 'auto' }}
          type="submit"
          variant="contained"
        >
          Valider
        </LoadingButton>
      </form>
    </DrawerStandard>
  )
}

export default DrawerFeedback
