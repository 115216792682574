import { Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'
import primary from 'themes/colors/primary'

import { drawerWidth } from './constants'

export const DrawerStyled = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    backgroundColor: primary[900],
    boxSizing: 'border-box',
    color: 'white',
    height: '100vh',
    padding: '0px 16px',
    width: drawerWidth,
  },
}))
