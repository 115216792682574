import { Box, Theme } from '@mui/material'
import { UserStatus, UserStatusLabel } from 'constants/enum'
import { MRT_ColumnDef } from 'material-react-table'
import { UserStatusType } from 'types/enums'
import { UserType } from 'types/user'

const getColorByUserStatus = (theme: Theme) => ({
  [UserStatus.DISABLED]: theme.palette.error.main,
  [UserStatus.ENABLED]: theme.palette.success.main,
  [UserStatus.WAIT_PASSWORD]: theme.palette.warning.main,
})

export const columns: MRT_ColumnDef<UserType>[] = [
  {
    accessorFn: (row) => `${row.firstname} ${row.name}`,
    header: 'Utilisateur',
  },
  {
    accessorKey: 'role.name',
    header: 'Rôle',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          backgroundColor:
            getColorByUserStatus(theme)[cell.getValue<UserStatusType>()],
          borderRadius: '1rem',
          color: '#fff',
          fontWeight: 700,
          p: '0.4rem',
        })}
      >
        {UserStatusLabel[cell.getValue<UserStatusType>()]}
      </Box>
    ),
    accessorKey: 'status',
    filterFn: 'between',
    header: 'Statut',
  },
]
