export default {
  50: '#EBFFEF',
  100: '#D1FED9',
  200: '#A4FDBD',
  300: '#76F9A8',
  400: '#54F3A0',
  500: '#1EEB95',
  600: '#15CA90',
  700: '#0FA987',
  800: '#098879',
  900: '#05706E',
}
