import { atom } from 'recoil'
import { MapHoverInfos } from 'types/recoils/map'
import { AggsListState } from 'types/recoils/parties-search'

export const aggsListState = atom({
  default: {
    categoryOrganization: [],
    categoryPerson: [],
    city: [],
    county: [],
    expertise: [],
    journey: [],
    region: [],
  } as AggsListState,
  key: 'aggsState',
})

export const initialMapHoverInfoState = {
  coordinates: [],
  layer: '',
  properties: undefined,
} as MapHoverInfos

export const mapHoverInfoState = atom({
  default: initialMapHoverInfoState as MapHoverInfos,
  key: 'mapHoverInfoState',
})
