export default {
  50: '#FFF5F0',
  100: '#FFE5D8',
  200: '#FFC5B1',
  300: '#FF9E8A',
  400: '#FF7A6D',
  500: '#FF3D3D',
  600: '#DB2C3B',
  700: '#B71E39',
  800: '#931334',
  900: '#7A0B32',
}
