import ErrorIcon from '@mui/icons-material/ErrorRounded'
import { Paper, Typography } from '@mui/material'
import React from 'react'
import warning from 'themes/colors/warning'

const Alert = ({ text }: { text: string }) => {
  return (
    <Paper elevation={2} sx={{ backgroundColor: warning[100], p: 1 }}>
      <Typography
        alignItems="center"
        display="flex"
        fontWeight={700}
        textAlign="left"
        variant="body1"
      >
        <ErrorIcon color="warning" sx={{ mr: 1 }} />
        {text}
      </Typography>
    </Paper>
  )
}

export default Alert
