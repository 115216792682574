import { Typography } from '@mui/material'
import { ReactComponent as ClearImage } from 'assets/images/clear.svg'
import ConfirmDrawer from 'components/ConfirmDrawer'
import React, { ElementType } from 'react'

export const TYPE_CONFIRM_DRAWER_CLEAR = 'CLEAR'

const initialStateConfirmDrawer = {
  onSubmit: null as any,
  open: false,
  type: '',
}

const withDrawer = (BaseComponent: ElementType) => {
  return function EnhancedComponent(props: any) {
    const [confirmDrawerState, setConfirmDrawerState] = React.useState(
      initialStateConfirmDrawer,
    )
    const resetStateConfirmDrawer = () =>
      setConfirmDrawerState(initialStateConfirmDrawer)

    return (
      <>
        <BaseComponent
          {...props}
          resetStateConfirmDrawer={resetStateConfirmDrawer}
          setConfirmDrawerState={setConfirmDrawerState}
        />
        <ConfirmDrawer
          modeTwoButtons
          bodySecondaryText="Voulez-vous continuer?"
          Image={<ClearImage />}
          titleText="Réinitialiser filtre"
          onSubmit={confirmDrawerState.onSubmit}
          onSubmitText="Réinitialiser"
          handleCloseOpenDrawer={resetStateConfirmDrawer}
          bodyPrimaryText={
            <Typography>Tous vos filtres vont être remis à zéro.</Typography>
          }
          isOpenDrawer={
            confirmDrawerState.open &&
            confirmDrawerState.type === TYPE_CONFIRM_DRAWER_CLEAR
          }
        />
      </>
    )
  }
}

export default withDrawer
