import React from 'react'
import { Outlet } from 'react-router-dom'

import FullMainLayout from '../../components/Layout/FullMain'
import { GetAggs } from './hoc/getAggs'

const CorePage = () => {
  const fieldsAggs = [
    'categoryOrganization',
    'categoryPerson',
    'journey',
    'expertise',
  ]
  fieldsAggs.forEach((value) => GetAggs(value))

  return (
    <FullMainLayout description="MedTrucks core application" title="MedTrucks">
      <Outlet />
    </FullMainLayout>
  )
}

export default CorePage
