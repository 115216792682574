import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
  Tooltip,
} from '@mui/material'
import { xorBy } from 'lodash'
import * as React from 'react'

import type { selectOptions } from './type'

import SelectTabs from './Tabs'

export const IconCheckboxBlank = <CheckBoxOutlineBlankIcon fontSize="small" />
export const IconCheckbox = <CheckBoxIcon fontSize="small" />

const nbItemsLoadMoreDefault = 10

const SelectMultiple = ({
  helperText,
  label,
  loadMore,
  nbItemsLoadMore = nbItemsLoadMoreDefault,
  onChangeSelect,
  options,
  optionsSelected,
  placeholder,
  totalRecords,
  updateOptionsWanted,
  ...props
}: {
  helperText?: string
  label: string
  loadMore?: any
  nbItemsLoadMore?: number
  onChangeSelect: any
  options: selectOptions[]
  optionsSelected: selectOptions[]
  placeholder: string
  totalRecords: number
  updateOptionsWanted?: any
} & SelectProps<selectOptions[]>) => {
  const onChangeResultSelect = (option: selectOptions) => {
    const newSelectedOptions = [...optionsSelected, option]
    onChangeSelect(newSelectedOptions)
  }
  const onChangeSelectionSelect = (
    options: selectOptions[],
    unselectAll?: boolean,
  ) => {
    const newSelectedOptions = xorBy(optionsSelected, options, 'value')
    onChangeSelect(newSelectedOptions)
    if (unselectAll && loadMore) {
      loadMore({ morePageSize: nbItemsLoadMore, reset: true })
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="search-select-label" variant="filled">
        {label}
      </InputLabel>

      <Select
        id="search-select"
        labelId="search-select-label"
        placeholder={placeholder}
        value={optionsSelected}
        variant="filled"
        // Disables auto focus on MenuItems and allows TextField to be in focus
        MenuProps={{
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          autoFocus: false,
          sx: { maxHeight: '800px', maxWidth: '0' }, // set le menu a la valeur minWidth
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
        }}
        renderValue={(optionsValue) => {
          const value = `${optionsValue?.[0].label} ${
            optionsValue.length !== 1 ? `+ ${optionsValue.length - 1}` : ''
          }`
          return (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          )
        }}
        {...props}
      >
        <SelectTabs
          loadMore={loadMore}
          nbItemsLoadMore={nbItemsLoadMore}
          options={options}
          optionsSelected={optionsSelected}
          totalRecords={totalRecords}
          updateOptionsWanted={updateOptionsWanted}
          onChangeResultSelect={onChangeResultSelect}
          onChangeSelectionSelect={onChangeSelectionSelect}
        ></SelectTabs>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
export default SelectMultiple
