import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, TextField } from '@mui/material'
import DrawerStandard from 'components-ui/Drawer'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

export const validationSchema = z.object({
  name: z.string({ required_error: 'Nom obligatoire' }).min(1),
})

export type ValidationSchema = z.infer<typeof validationSchema>

const CreateDrawerProject = ({
  handleCloseOpenDrawer,
  isOpenDrawer,
  onSubmit,
  onSubmitText,
  titleText,
}: {
  handleCloseOpenDrawer: any
  isOpenDrawer: boolean
  onSubmit: any
  onSubmitText: string
  titleText: string
}) => {
  const { control, formState, handleSubmit } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  })

  return (
    <DrawerStandard
      isOpenDrawer={isOpenDrawer}
      titleText={titleText}
      handleCloseOpenDrawer={handleCloseOpenDrawer}
    >
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <TextField
              focused
              id="standard-name-project-input"
              inputProps={{ autoFocus: true }}
              label="Nom du projet"
              placeholder="ex: Nominus_94"
              sx={{ m: 2 }}
              variant="filled"
              {...field}
              inputRef={field.ref}
            />
          )}
        />
        <Button
          disabled={!formState.isValid}
          sx={{ borderRadius: 0, height: '50px', marginTop: 'auto' }}
          type="submit"
          variant="contained"
        >
          {onSubmitText}
        </Button>
      </Box>
    </DrawerStandard>
  )
}

export default CreateDrawerProject
