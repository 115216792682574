import PhotoCameraIcon from '@mui/icons-material/PhotoCameraOutlined'
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { now, round } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { Bar } from 'react-chartjs-2'
import { useRecoilValue } from 'recoil'
import { fecthAxios } from 'services/axios.service'
import neutral from 'themes/colors/neutral'

import { myDiagnosticState } from '../../atoms'
import { optionsBarVertical } from './options'

Chart.register(ChartDataLabels)

const labels = ['Ma sélection', 'Nationale']

const formattedValue = (value: number) => {
  return `${round(value, 2)} %`
}

const SocialFragilityDynamic = ({ onPrint }: { onPrint: any }) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const myDiagnostic = useRecoilValue(myDiagnosticState)
  const {
    options: { cityCode, countyCode, regionCode },
  } = myDiagnostic

  const { data, refetch } = useQuery({
    initialData: { datasets: [], labels },
    queryFn: async () => {
      const result = await fecthAxios(
        `/geoh-api/v0/diagnostics/territorial/social-fragility-dynamic`,
        {
          data: {
            cities: cityCode.map((o) => o.value),
            counties: countyCode.map((o) => o.value),
            regions: regionCode.map((o) => o.value),
          },
          method: 'POST',
        },
      )
      const datasets = result.reduce((acc: any, curr: any) => {
        const { backgroundColor, data, label } = curr
        return [
          ...acc,
          {
            backgroundColor,
            barThickness: 60,
            borderRadius: 10,
            data,
            label,
          },
        ]
      }, [])
      return { datasets, labels }
    },
    queryKey: ['analitics-social-fragility-dynamic'],
  })

  useEffect(() => {
    refetch()
  }, [myDiagnostic])

  return (
    <Paper ref={chartRef}>
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        flexDirection="row"
        sx={{ backgroundColor: neutral[50], mb: 2, p: 2 }}
      >
        <Stack>
          <Typography fontWeight={700} variant="h6">
            Dynamique de fragilité sociale
          </Typography>
          <Typography>Comparaison des taux d’activités</Typography>
        </Stack>
        <IconButton
          className="remove-from-print"
          sx={{ marginLeft: 'auto' }}
          onClick={() =>
            onPrint(chartRef, `Dynamique de fragilité sociale ${now()}`)
          }
        >
          <PhotoCameraIcon />
        </IconButton>
      </Box>
      <Stack sx={{ p: 2 }}>
        <Bar
          redraw
          data={data}
          options={optionsBarVertical(formattedValue)}
          plugins={[ChartDataLabels]}
        />

        {/* <BoxLegend
            color={COLOR_MY_TERRITORY}
            label="Paris : +5% par rapport à sa région | +6% par rapport à son département"
          />
          <BoxLegend
            color={COLOR_MY_TERRITORY}
            label="Lyon :-5% par rapport à sa région | +6% par rapport à son département"
          />
          <BoxLegend
            color={COLOR_MY_TERRITORY}
            label="Marseille :+5% par rapport à sa région | -2% par rapport à son département"
          /> */}
      </Stack>
    </Paper>
  )
}

export default SocialFragilityDynamic
