import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

const PageInfo = ({ loading, text }: { loading: boolean; text: string }) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      height={155}
      justifyContent="center"
    >
      <Typography fontWeight={700} sx={{ mb: 3 }} variant="body1">
        {text}
      </Typography>
      {loading && <CircularProgress color="secondary" size={60} />}
    </Box>
  )
}

export default PageInfo
