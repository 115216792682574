import { Box, CircularProgress, Divider, Grid, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Header from 'components/Header'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import authService from 'services/auth.service'
import { UserType } from 'types/user'

import AsideUsersAdminProfile from './Aside'
import TabsUsersAdminProfile from './Tabs'

const UsersAdminProfile = () => {
  const { userId } = useParams()
  const [enabledGetUser, setEnabledGetUser] = useState(false)
  const currentUser = authService.getCurrentUser()

  const { data, isLoading } = useQuery<UserType>({
    enabled: enabledGetUser,
    queryFn: async ({ queryKey: [, userId, currentUserId] }) => {
      const url = `/users/${userId || currentUserId}?${new URLSearchParams({
        crudQuery: JSON.stringify({
          joins: ['creator', 'role'],
        }),
      })}`
      const { data } = await axios.get(url)
      return data
    },

    queryKey: [`admin-user-get`, userId, currentUser?.id],
  })

  useEffect(() => {
    if (userId || currentUser) {
      setEnabledGetUser(true)
    }
  }, [userId, currentUser])

  return (
    <>
      {!data || isLoading ? (
        <Box
          alignItems="center"
          display="flex"
          height={155}
          justifyContent="center"
        >
          <CircularProgress size={80} />
        </Box>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Header
                arrowBack
                rowLeftTitle={`${data?.firstname} ${data?.name}`}
              />
            </Grid>
          </Grid>
          <Stack direction="row" display="flex" flexGrow={1}>
            <AsideUsersAdminProfile user={data} />
            <Divider orientation="vertical" />
            <TabsUsersAdminProfile data={data} />
          </Stack>
        </>
      )}
    </>
  )
}

export default UsersAdminProfile
