import { FormControlLabel, Stack, Switch, Typography } from '@mui/material'
import SelectMultiple from 'components-ui/SelectMultiple'
import { selectOptions } from 'components-ui/SelectMultiple/type'
import {
  LABEL_CATEGORY_ORGANIZATION,
  LABEL_CATEGORY_PERSON,
  LABEL_EXPERTISE,
  LABEL_JOURNEY,
} from 'constants/label'
import * as React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { myProjectState } from '../../atoms'
import { formatAggsToSelectOptions } from '../../utils'
import { focusType } from '../type'
import { maxTotalItem, useQueriesCustom } from './queries'

const SelectionFocusInProgressProject = () => {
  const setMyProject = useSetRecoilState(myProjectState)
  const myProject = useRecoilValue(myProjectState)

  const {
    responseCategoryOrganization,
    responseCategoryPerson,
    responseExpertise,
    responseJourney,
  } = useQueriesCustom(myProject)

  const listPropsOfSelect = [
    {
      key: 'expertise' as focusType,
      label: LABEL_EXPERTISE,
      options: formatAggsToSelectOptions(
        responseExpertise?.data?.buckets || [],
      ),
      placeholder: LABEL_EXPERTISE,
      totalRecords: responseExpertise?.totalValue,
    },
    {
      key: 'journey' as focusType,
      label: LABEL_JOURNEY,
      options: formatAggsToSelectOptions(responseJourney?.data?.buckets || []),
      placeholder: LABEL_JOURNEY,
      totalRecords: responseJourney?.totalValue,
    },
    {
      key: 'categoryOrganization' as focusType,
      label: LABEL_CATEGORY_ORGANIZATION,
      options: formatAggsToSelectOptions(
        responseCategoryOrganization?.data?.buckets || [],
      ),
      placeholder: LABEL_CATEGORY_ORGANIZATION,
      totalRecords: responseCategoryOrganization?.totalValue,
    },
    {
      key: 'categoryPerson' as focusType,
      label: LABEL_CATEGORY_PERSON,
      options: formatAggsToSelectOptions(
        responseCategoryPerson?.data?.buckets || [],
      ),
      placeholder: LABEL_CATEGORY_PERSON,
      totalRecords: responseCategoryPerson?.totalValue,
    },
  ]

  const onChangeSelect = (
    newSelectedOptions: selectOptions[],
    key: focusType,
  ) => {
    setMyProject((curr) => ({
      ...curr,
      options: {
        ...curr.options,
        [key]: newSelectedOptions,
      },
    }))
  }

  return (
    <Stack p={2} spacing={1}>
      <Typography fontWeight={700}>Choisissiez votre ciblage</Typography>
      {listPropsOfSelect.map((props) => {
        const totalValue = props.options.length
        return (
          <Stack direction="row" key={props.label}>
            <SelectMultiple
              label={props.label}
              options={props.options}
              optionsSelected={myProject.options[props.key]}
              placeholder={props.placeholder}
              totalRecords={props.totalRecords}
              onChangeSelect={(newSelectedOptions: selectOptions[]) =>
                onChangeSelect(newSelectedOptions, props.key)
              }
            />
            <FormControlLabel
              disabled={totalValue === 0 || totalValue > maxTotalItem}
              label="Tous"
              labelPlacement="end"
              sx={{ m: 1 }}
              control={
                <Switch
                  onChange={(event) => {
                    setMyProject((curr) => ({
                      ...curr,
                      options: {
                        ...curr.options,
                        [props.key]: event.target.checked ? props.options : [],
                      },
                    }))
                  }}
                  checked={
                    totalValue !== 0 &&
                    totalValue === myProject.options[props.key].length
                  }
                />
              }
            />
          </Stack>
        )
      })}
    </Stack>
  )
}
export default SelectionFocusInProgressProject
