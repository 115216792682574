import FormatListIcon from '@mui/icons-material/FormatListBulletedRounded'
import MapIcon from '@mui/icons-material/MapOutlined'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import * as React from 'react'

import MapInProgressProject from '../../Map'
import { STATE_OVERVIEW } from '../constants'
import OverviewAreaInProgressProject from './Area'
import OverviewKPIInProgressProject from './KPI'
import OverviewOrganizationInProgressProject from './Organization'
import OverviewPersonInProgressProject from './Person'
import OverviewSpecialityInProgressProject from './Speciality'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, index, value } = props

  return <>{value === index && children}</>
}

const OverviewInProgressProject = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ height: '100%', width: '100%' }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container direction="column">
          <Grid item pl={3}>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                aria-controls="simple-tabpanel-0"
                icon={<FormatListIcon />}
                iconPosition="start"
                id="simple-tab-0"
                label="TABLEAU"
              />
              <Tab
                aria-controls="simple-tabpanel-1"
                icon={<MapIcon />}
                iconPosition="start"
                id="simple-tab-1"
                label="CARTE"
              />
            </Tabs>
          </Grid>
        </Grid>
      </Box>
      <CustomTabPanel index={0} value={value}>
        <Grid container direction="column">
          <Grid item p={3}>
            <OverviewKPIInProgressProject />
          </Grid>
          <Grid item p={3}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <OverviewAreaInProgressProject />
              </Grid>
              <Grid item>
                <OverviewSpecialityInProgressProject />
              </Grid>
              <Grid item>
                <OverviewOrganizationInProgressProject />
              </Grid>
              <Grid item>
                <OverviewPersonInProgressProject />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel index={1} value={value}>
        <MapInProgressProject currentState={STATE_OVERVIEW} />
      </CustomTabPanel>
    </Box>
  )
}
export default OverviewInProgressProject
