import PageInfo from 'components-ui/PageInfo'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAxios } from 'services/axios.service'
import permissionsService, {
  ALLOWED_ACTION_READ_QUESTIONNAIRE,
} from 'services/permissions.service'

import { tabState, tabStateSelector } from '../Questionnaire/atoms'
import HeaderParty from './Header'
import Tabs from './Tabs'

const PartyPage = () => {
  const { partyId } = useParams()

  const allowedReadQuestionnaire = permissionsService.getPermission(
    ALLOWED_ACTION_READ_QUESTIONNAIRE,
  )

  const setTabState = useSetRecoilState(tabState)
  const selectedTabState = useRecoilValue(tabStateSelector)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabState(newValue)
  }

  const { error, loading, refresh, response } = useAxios({
    noFetchInit: true,
    url: `/parties?crudQuery={"joins": ["address", "organization", "person"], "where": {"id": "${partyId}"}}`,
  })

  useEffect(() => {
    refresh()
  }, [partyId])

  const party = response?.data?.[0]

  if (!response || loading) {
    return <PageInfo loading={true} text="Recherche en cours ..." />
  }

  if ((error && error.statusCode === 404) || !party) {
    return (
      <PageInfo loading={false} text="Cet établissement ou PS n'existe pas" />
    )
  }

  return (
    <>
      <HeaderParty
        allowedQuestionnaire={allowedReadQuestionnaire}
        party={party}
        tabsValue={selectedTabState}
        handleChange={handleChange}
      />
      <Tabs
        allowedQuestionnaire={allowedReadQuestionnaire}
        party={party}
        tabsValue={selectedTabState}
      ></Tabs>
    </>
  )
}

export default PartyPage
