import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  width: '100vw',
}))

export const StyledBox = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
}))
