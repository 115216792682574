import PersonIcon from '@mui/icons-material/AccountBoxOutlined'
import CrisisIcon from '@mui/icons-material/CrisisAlertOutlined'
import DomainIcon from '@mui/icons-material/DomainRounded'
import ExploreIcon from '@mui/icons-material/ExploreOutlined'
import StarIcon from '@mui/icons-material/StarRateOutlined'
import { Stack, Typography } from '@mui/material'
import { PartyTypeLabel } from 'constants/enum'
import {
  LABEL_CATEGORY_ORGANIZATION,
  LABEL_CATEGORY_PERSON,
  LABEL_CITY,
  LABEL_COUNTY,
  LABEL_CPTS,
  LABEL_DAC,
  LABEL_EPCI,
  LABEL_EXPERTISE,
  LABEL_JOURNEY,
  LABEL_REGION,
  LABEL_SPECIALITY,
  LABEL_TVS,
} from 'constants/label'
import * as React from 'react'
import { useRecoilValue } from 'recoil'
import neutral from 'themes/colors/neutral'

import type { currentStateType } from '../type'

import { myProjectState } from '../../atoms'
import {
  STATE_AREA,
  STATE_FOCUS,
  STATE_PARTY,
  STATE_SPECIALITY,
} from '../constants'
import CardSummaryInprogressProject from './Card'

const SummaryInProgressProject = ({
  currentState,
  setCurrentState,
}: {
  currentState: currentStateType
  setCurrentState: any
}) => {
  const myProject = useRecoilValue(myProjectState)
  const { options, party, speciality } = myProject

  const listOfResume = [
    {
      icon: <ExploreIcon />,
      isEditabled: currentState !== STATE_AREA,
      label: LABEL_REGION,
      onEdit: () => setCurrentState(STATE_AREA),
      value: options.regionCode?.length,
    },
    {
      icon: <ExploreIcon />,
      isEditabled: currentState !== STATE_AREA,
      label: LABEL_COUNTY,
      onEdit: () => setCurrentState(STATE_AREA),
      value: options.countyCode?.length,
    },
    {
      icon: <ExploreIcon />,
      isEditabled: currentState !== STATE_AREA,
      label: LABEL_CITY,
      onEdit: () => setCurrentState(STATE_AREA),
      value: options.cityCode?.length,
    },
    {
      icon: <ExploreIcon />,
      isEditabled: currentState !== STATE_AREA,
      label: LABEL_CPTS,
      onEdit: () => setCurrentState(STATE_AREA),
      value: options.cptsCode?.length,
    },
    {
      icon: <ExploreIcon />,
      isEditabled: currentState !== STATE_AREA,
      label: LABEL_TVS,
      onEdit: () => setCurrentState(STATE_AREA),
      value: options.tvsCode?.length,
    },
    {
      icon: <ExploreIcon />,
      isEditabled: currentState !== STATE_AREA,
      label: LABEL_EPCI,
      onEdit: () => setCurrentState(STATE_AREA),
      value: options.epciCode?.length,
    },
    {
      icon: <ExploreIcon />,
      isEditabled: currentState !== STATE_AREA,
      label: LABEL_DAC,
      onEdit: () => setCurrentState(STATE_AREA),
      value: options.dacCode?.length,
    },
    {
      icon: <StarIcon />,
      isEditabled: currentState !== STATE_SPECIALITY,
      label: LABEL_SPECIALITY,
      onEdit: () => setCurrentState(STATE_SPECIALITY),
      value: speciality.label,
    },
    {
      icon: <CrisisIcon />,
      isEditabled: currentState !== STATE_FOCUS,
      label: LABEL_JOURNEY,
      onEdit: () => setCurrentState(STATE_FOCUS),
      value: options.journey?.length,
    },
    {
      icon: <CrisisIcon />,
      isEditabled: currentState !== STATE_FOCUS,
      label: LABEL_EXPERTISE,
      onEdit: () => setCurrentState(STATE_FOCUS),
      value: options.expertise?.length,
    },
    {
      icon: <CrisisIcon />,
      isEditabled: currentState !== STATE_FOCUS,
      label: LABEL_CATEGORY_ORGANIZATION,
      onEdit: () => setCurrentState(STATE_FOCUS),
      value: options.categoryOrganization?.length,
    },
    {
      icon: <CrisisIcon />,
      isEditabled: currentState !== STATE_FOCUS,
      label: LABEL_CATEGORY_PERSON,
      onEdit: () => setCurrentState(STATE_FOCUS),
      value: options.categoryPerson?.length,
    },
    {
      icon: <DomainIcon />,
      isEditabled: currentState !== STATE_PARTY,
      label: PartyTypeLabel.ORGANIZATION,
      onEdit: () => setCurrentState(STATE_PARTY),
      value: party.organization?.length,
    },
    {
      icon: <PersonIcon />,
      isEditable: currentState !== STATE_PARTY,
      label: PartyTypeLabel.PERSON,
      onEdit: () => setCurrentState(STATE_PARTY),
      value: party.person?.length,
    },
  ]

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        p={1}
        sx={{ backgroundColor: neutral[100] }}
      >
        <Typography fontWeight={700} variant="body2">
          Mon Projet : {myProject.name}
        </Typography>
      </Stack>
      <Stack>
        {listOfResume.map(
          (resume) =>
            !!resume.value && (
              <CardSummaryInprogressProject
                icon={resume.icon}
                isEditabled={resume.isEditabled || false}
                key={resume.label}
                label={resume.label}
                value={resume.value}
                onEdit={resume.onEdit}
              />
            ),
        )}
      </Stack>
    </>
  )
}
export default SummaryInProgressProject
