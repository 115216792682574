import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material'
import { ArcElement, Chart as ChartJS } from 'chart.js'
import 'chart.js/auto'
import PageInfo from 'components-ui/PageInfo'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import neutral from 'themes/colors/neutral'
import primary from 'themes/colors/primary'
import { Questionnaire } from 'types/questionnaire'

import elementsByStatusQuestionnaire from './getElementProgress'
ChartJS.register(ArcElement)

const centerText = {
  afterDatasetsDraw: function (chart: ChartJS) {
    const { ctx, data, height } = chart

    if (data && !isEmpty(data)) {
      const text = data?.labels ? `${data?.labels}%` : '0%'
      ctx.save()

      const x = chart.getDatasetMeta(0)?.data[0]?.x
      const y = chart.getDatasetMeta(0)?.data[0]?.y - 13
      const fontSize = (height / 100).toFixed(2)
      ctx.font = 'bold ' + fontSize + 'em Nunito'
      ctx.textAlign = 'center'
      ctx.fillStyle = primary[500]
      ctx.textBaseline = 'top'

      ctx.fillText(text, x, y)
    }
  },
  id: 'center-text',
}

const Progress = ({
  currQuestionnaireId,
  handleChangeQuestionnaire,
  infos,
  partyId,
  questionnaires,
}: {
  currQuestionnaireId: number | undefined
  handleChangeQuestionnaire: any
  infos: any
  partyId: string
  questionnaires: Questionnaire[]
}) => {
  const navigate = useNavigate()
  const [data, setData] = React.useState({ datasets: [] as any } as any)
  const chartRef = React.useRef<ChartJS<'doughnut', number[], string>>(null)

  React.useEffect(() => {
    setData({
      datasets: [
        {
          backgroundColor: [primary[500], neutral[500]],
          data: [infos?.progressValue, 100 - infos?.progressValue],
        },
      ],
      labels: infos?.progressValue,
    })

    chartRef?.current?.update('none')
  }, [infos?.progressValue])

  const actionQuestionnaire = React.useCallback(
    () => navigate(`/party/${partyId}/questionnaire/${currQuestionnaireId}`),
    [currQuestionnaireId, partyId],
  )
  const [blocProgress, setBlocProgress] = React.useState(
    elementsByStatusQuestionnaire(actionQuestionnaire, infos).empty,
  )

  React.useEffect(() => {
    if (infos) {
      if (+infos?.progressValue === 0) {
        setBlocProgress(
          elementsByStatusQuestionnaire(actionQuestionnaire, infos).empty,
        )
      } else if (+infos?.progressValue === 100) {
        setBlocProgress(
          elementsByStatusQuestionnaire(actionQuestionnaire, infos).completed,
        )
      } else {
        setBlocProgress(
          elementsByStatusQuestionnaire(actionQuestionnaire, infos).inProgress,
        )
      }
    }
  }, [actionQuestionnaire, infos])

  if (!questionnaires) {
    return <PageInfo loading={false} text="Pas de questionnaires" />
  }

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="SelectQuestionnaire">Questionnaire</InputLabel>
            <Select
              id="SelectQuestionnaire-standard"
              label="Questionnaire"
              labelId="SelectQuestionnaire"
              value={currQuestionnaireId}
              onChange={handleChangeQuestionnaire}
            >
              {questionnaires.map((questionnaire: Questionnaire) => {
                return (
                  <MenuItem key={questionnaire.id} value={questionnaire.id}>
                    {questionnaire.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        {+infos?.progressValue !== 100 && (
          <Grid
            item
            display="flex"
            height={250}
            justifyContent="center"
            xs={12}
          >
            <Doughnut
              data={data}
              height={200}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              plugins={[centerText]}
              ref={chartRef}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              width={200}
              options={{
                cutout: 90,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: false,
                  },
                  tooltip: {
                    enabled: false,
                  },
                },
              }}
            />
          </Grid>
        )}
        {blocProgress.body}
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 1 }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              spacing={1}
            >
              {blocProgress.footer}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Progress
