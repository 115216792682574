export default {
  50: '#E8EFFC',
  100: '#C6D8F8',
  200: '#90B0F0',
  300: '#557CD5',
  400: '#2A4EAB',
  500: '#001D73',
  600: '#001662',
  700: '#001052',
  800: '#000B42',
  900: '#000837',
}
