import { Typography } from '@mui/material'
import 'mapbox-gl/dist/mapbox-gl.css'
import React from 'react'
import { Popup } from 'react-map-gl'

const MapPopupApi = ({
  closeOnClick,
  coordinates,
  properties,
  specialityCode,
}: {
  closeOnClick?: boolean
  coordinates: number[]
  properties: any
  specialityCode: string
}) => {
  if (!properties) {
    return <></>
  }

  const { PopTot } = properties
  return (
    <Popup
      className="county-info"
      closeButton={false}
      closeOnClick={closeOnClick}
      latitude={coordinates[1]}
      longitude={coordinates[0]}
      offset={20}
    >
      <Typography noWrap alignItems="center" display="flex" fontWeight={700}>
        APL : {properties?.[`apl_${specialityCode}`]?.toLocaleString()}
      </Typography>
      <Typography noWrap alignItems="center" display="flex" fontWeight={700}>
        Population totale : {PopTot?.toLocaleString()}
      </Typography>
    </Popup>
  )
}

export default MapPopupApi
