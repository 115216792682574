import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { myProjectState } from '../../atoms'

const OverviewAreaInProgressProject = () => {
  const { speciality } = useRecoilValue(myProjectState)

  return (
    <>
      <Typography color="primary" mb={2} variant="h3">
        Spécialité
      </Typography>
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={speciality.value}>
              <TableCell>{speciality.label}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default OverviewAreaInProgressProject
