import type { UserType } from 'types/user'

import { ListItemIcon, MenuItem, Typography } from '@mui/material'
import { compact, map } from 'lodash'
import { PencilIcon } from 'lucide-react'
import {
  type MRT_PaginationState,
  type MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import authService from 'services/auth.service'
import primary from 'themes/colors/primary'

import withDrawer from '../../hoc-drawer'
import { useMutationsCustom } from '../../mutations'
import { getActionsDelete, getActionsStatus } from './actions'
import { columns } from './column'

const TableList = ({
  data,
  loading,
  onPaginationChange,
  pageSize,
  refetch,
  resetStateConfirmDrawer,
  rowCount,
  setConfirmDrawerState,
}: {
  data: any
  loading: boolean
  onPaginationChange: any
  pageSize: number
  refetch: any
  resetStateConfirmDrawer: any
  rowCount: number
  setConfirmDrawerState: any
}) => {
  const navigate = useNavigate()
  const currentUser = authService.getCurrentUser()

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize,
  })

  useEffect(() => {
    onPaginationChange(pagination)
  }, [pagination])

  const { isPendingDelete, isPendingUpdate, mutateDelete, mutateUpdate } =
    useMutationsCustom({ successCallback: refetch })

  const getActionMenuItems = (original: UserType) => [
    {
      Icon: PencilIcon,
      color: primary[500],
      getLabel: () => 'Modifier',
      onClick: ({ id }: { id?: number }) => {
        navigate(`/admin/users/${id}`)
      },
    },
    ...getActionsStatus({
      currentUser,
      mutateUpdate,
      original,
      resetStateConfirmDrawer,
      setConfirmDrawerState,
    }),
    ...getActionsDelete({
      currentUser,
      mutateDelete,
      original,
      resetStateConfirmDrawer,
      setConfirmDrawerState,
    }),
  ]

  const onClickRow = (id: number) => {
    navigate(`/admin/users/profile/${id}`)
  }

  const renderRowActionMenuItems = ({
    closeMenu,
    row: { original },
  }: {
    closeMenu: () => void
    row: MRT_Row<UserType>
  }) =>
    map(
      compact(getActionMenuItems(original)),
      ({ Icon, color, getLabel, onClick }, key) => (
        <MenuItem
          key={`RowAction-${key}`}
          sx={{ m: 0 }}
          onClick={() => {
            onClick(original)
            closeMenu()
          }}
        >
          <ListItemIcon>{<Icon color={color} />}</ListItemIcon>
          <Typography color={color}>{getLabel()}</Typography>
        </MenuItem>
      ),
    )

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    enableColumnActions: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableHiding: false,
    enablePagination: true,
    enableRowActions: true,
    enableRowSelection: false,
    // enableRowSelection: true,
    enableSorting: false,
    enableStickyHeader: true,
    getRowId: (row) => row?.id?.toString(),
    initialState: {
      pagination: { pageIndex: 0, pageSize },
    },
    localization: MRT_Localization_FR,
    manualPagination: true,
    muiPaginationProps: {
      rowsPerPageOptions: [pageSize],
      variant: 'outlined',
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (!isPendingDelete && !isPendingUpdate) onClickRow(row.original.id)
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    muiTableContainerProps: {
      sx: {
        height: 'inherit',
        minHeight: '500px',
        width: 'inherit',
      },
    },
    muiTablePaperProps: {
      sx: {
        height: 'inherit',
        width: 'inherit',
      },
    },
    onPaginationChange: setPagination,
    paginationDisplayMode: 'pages',
    positionActionsColumn: 'last',
    positionToolbarAlertBanner: 'top',
    renderRowActionMenuItems,
    // renderToolbarInternalActions,
    rowCount,
    state: {
      isLoading: loading || isPendingDelete || isPendingUpdate,
      pagination,
    },
  })

  return <MaterialReactTable table={table} />
}

export default withDrawer(TableList)
