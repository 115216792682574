import { getBaseURLTilesServeur } from 'constants/config'
const baseURLTilesServeur = getBaseURLTilesServeur(window.location.origin)

export default {
  cities: {
    maxzoom: 11, //maxzoom of the tile build
    tiles: [`${baseURLTilesServeur}/data/cities/{z}/{x}/{y}.pbf`],
    type: 'vector',
  },
  counties: {
    maxzoom: 11, //maxzoom of the tile build
    tiles: [`${baseURLTilesServeur}/data/counties/{z}/{x}/{y}.pbf`],
    type: 'vector',
  },
  cpts: {
    maxzoom: 11, //maxzoom of the tile build
    tiles: [`${baseURLTilesServeur}/data/cpts/{z}/{x}/{y}.pbf`],
    type: 'vector',
  },
  dac: {
    maxzoom: 11, //maxzoom of the tile build
    tiles: [`${baseURLTilesServeur}/data/dac/{z}/{x}/{y}.pbf`],
    type: 'vector',
  },
  epci: {
    maxzoom: 11, //maxzoom of the tile build
    tiles: [`${baseURLTilesServeur}/data/epci/{z}/{x}/{y}.pbf`],
    type: 'vector',
  },
  mapbox: {
    type: 'vector',
    url: 'mapbox://mapbox.mapbox-streets-v7',
  },
  regions: {
    maxzoom: 11, //maxzoom of the tile build
    tiles: [`${baseURLTilesServeur}/data/regions/{z}/{x}/{y}.pbf`],
    type: 'vector',
  },
  tvs: {
    maxzoom: 11, //maxzoom of the tile build
    tiles: [`${baseURLTilesServeur}/data/tvs/{z}/{x}/{y}.pbf`],
    type: 'vector',
  },
}
