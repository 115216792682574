import { Box, Divider, Stack } from '@mui/material'
import Header from 'components/Header'
import React from 'react'

const HeaderTwoRows = ({
  arrowBack,
  row1LeftDescription,
  row1LeftTitle,
  row1Right,
  row2,
}: {
  arrowBack?: boolean
  row1LeftDescription?: string
  row1LeftTitle: string
  row1Right?: React.ReactNode
  row2: React.ReactNode
}) => {
  return (
    <Stack>
      <Header
        arrowBack={arrowBack}
        rowLeftDescription={row1LeftDescription}
        rowLeftTitle={row1LeftTitle}
        rowRight={row1Right}
      />
      <Divider />
      {row2 && (
        <>
          <Box>{row2}</Box>
          <Divider />
        </>
      )}
    </Stack>
  )
}

export default HeaderTwoRows
