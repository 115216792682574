import { Grid, Paper, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { myProjectState } from '../../atoms'
import { getAreaInfos } from '../helper'

const OverviewKPIInProgressProject = () => {
  const myProject = useRecoilValue(myProjectState)
  const { areaInfosLength } = getAreaInfos(myProject)

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Paper elevation={2}>
          <Stack alignItems="center" p={8} spacing={1}>
            <Typography color="primary" variant="h3">
              {areaInfosLength}
            </Typography>
            <Typography color="primary" variant="h5">
              Territoire(s)
            </Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper elevation={2}>
          <Stack alignItems="center" p={8} spacing={1}>
            <Typography color="primary" variant="h3">
              {myProject.party.organization.length}
            </Typography>
            <Typography color="primary" variant="h5">
              Etablissement(s)
            </Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper elevation={2}>
          <Stack alignItems="center" p={8} spacing={1}>
            <Typography color="primary" variant="h3">
              {myProject.party.person.length}
            </Typography>
            <Typography color="primary" variant="h5">
              Professionnel(s) de santé
            </Typography>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default OverviewKPIInProgressProject
