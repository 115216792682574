import { Typography } from '@mui/material'
import 'mapbox-gl/dist/mapbox-gl.css'
import React from 'react'
import { Popup } from 'react-map-gl'
import { GeopointOsProperties } from 'types/party'

const MapPopupSample = ({
  closeOnClick,
  coordinates,
  properties,
}: {
  closeOnClick?: boolean
  coordinates: number[]
  properties: GeopointOsProperties | undefined
}) => {
  if (!properties) {
    return <></>
  }

  const { name } = properties
  return (
    <Popup
      className="county-info"
      closeButton={false}
      closeOnClick={closeOnClick}
      latitude={coordinates[1]}
      longitude={coordinates[0]}
      offset={20}
    >
      <Typography noWrap alignItems="center" display="flex">
        {name}
      </Typography>
    </Popup>
  )
}

export default MapPopupSample
