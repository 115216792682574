import { Button } from '@mui/material'
import { isEqual } from 'lodash'
import React from 'react'
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { mySearchType } from 'types/recoils/parties-search'

import withDrawer, { TYPE_CONFIRM_DRAWER_CLEAR } from './hoc-drawer'

const Clear = ({
  defaultMyFiltersState,
  myFiltersState,
  nextMyFiltersState,
  refetchSubmit,
  resetStateConfirmDrawer,
  setConfirmDrawerState,
}: {
  defaultMyFiltersState: mySearchType
  myFiltersState: RecoilState<mySearchType>
  nextMyFiltersState: RecoilState<mySearchType>
  refetchSubmit?: any
  resetStateConfirmDrawer: any
  setConfirmDrawerState: any
}) => {
  const setFiltersParams = useSetRecoilState(myFiltersState)
  const setNextFilters = useSetRecoilState(nextMyFiltersState)
  const nextFilters = useRecoilValue(myFiltersState)

  return (
    <Button
      fullWidth
      color="secondary"
      disabled={isEqual(nextFilters.options, defaultMyFiltersState.options)}
      sx={{ borderRadius: 0 }}
      variant="contained"
      onClick={() => {
        setConfirmDrawerState({
          onSubmit: async () => {
            setNextFilters(defaultMyFiltersState)
            setFiltersParams(defaultMyFiltersState)
            refetchSubmit()
            resetStateConfirmDrawer()
          },
          open: true,
          type: TYPE_CONFIRM_DRAWER_CLEAR,
        })
      }}
    >
      Tout effacer
    </Button>
  )
}

export default withDrawer(Clear)
