import type { MapRef } from 'react-map-gl'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import MapPopupParty from 'components/Map/Popup/Party'
import { List, flatten, isEmpty, isEqual, map, uniqWith } from 'lodash'
import 'mapbox-gl/dist/mapbox-gl.css'
import LegendControl, { LegendControlOptions } from 'mapboxgl-legend'
import React, { useEffect, useMemo, useRef } from 'react'
import MapGL, { Layer, Source } from 'react-map-gl'
import primary from 'themes/colors/primary'

const urlGeohealthAnalytics = process.env.REACT_APP_GEOHEALTH_ANALYTICS_API_URI

const legendOptions = {
  layers: {
    inflence: {
      attributes: ['fill-color'],
    },
  },
} as LegendControlOptions

const MapInformation = ({
  cityCode,
  partyId,
}: {
  cityCode: null | string
  partyId: string
}) => {
  const mapRef = useRef<MapRef>(null)
  let legendFill: List<any> | null | undefined = []
  const { data: geopointParty } = useQuery({
    queryFn: async ({ queryKey: [, partyId] }) => {
      const { data } = await axios.post(`/opensearch/search/party`, {
        myFilters: {
          partyId: [partyId],
        },
        onlyFields: ['geopoint'],
        size: 1,
      })
      return data
    },
    queryKey: [`party-information-geopointParty`, partyId],
  })

  const { data: geoJsonInfluence } = useQuery({
    enabled: !!cityCode,
    queryFn: async () => {
      const { data } = await axios.post(`${urlGeohealthAnalytics}/v0/zone`, {
        city: [cityCode],
      })

      return data
    },
    queryKey: [
      `party-information-geoJsonInfluence`,
      urlGeohealthAnalytics,
      cityCode,
    ],
  })

  useEffect(() => {
    const legend = new LegendControl(legendOptions)
    mapRef.current?.getMap().addControl(legend, 'bottom-right')
  }, [geoJsonInfluence])

  const geoJsonParty = useMemo(
    () => geopointParty?.data?.[0]?.data?.geopoint,
    [geopointParty],
  )

  if (isEmpty(geoJsonParty)) {
    return <></>
  }

  if (geoJsonInfluence) {
    legendFill = uniqWith(
      map(geoJsonInfluence?.features, (o: any) => Object.values(o.properties)),
      isEqual,
    )
  }

  const coordinates = geoJsonParty?.features?.[0]?.geometry?.coordinates
  const properties = geoJsonParty?.features?.[0]?.properties

  if (!coordinates) {
    return <></>
  }

  return (
    <MapGL
      mapboxAccessToken="pk.eyJ1IjoiYnNvZ2hpZ2lhbiIsImEiOiJjazBhOTUxam4wMDNtM2RvNXJzbjQ5aGV6In0.eL8NJ0-ikx_5Dl49994bGw"
      mapStyle="mapbox://styles/mapbox/light-v9"
      ref={mapRef}
      initialViewState={{
        latitude: coordinates[1],
        longitude: coordinates[0],
        zoom: 7,
      }}
    >
      <Source data={geoJsonParty} id="parties" type="geojson">
        <Layer
          id="dot-highlighted-parties"
          source="parties"
          type="circle"
          paint={{
            'circle-color': primary[800],
            'circle-radius': 10,
          }}
        />
      </Source>
      {geoJsonInfluence && (
        <Source data={geoJsonInfluence} id="inflence" type="geojson">
          <Layer
            id="inflence"
            metadata={{ labels: { other: '' }, name: 'Influence' }}
            source="inflence"
            type="fill"
            paint={{
              'fill-color': [
                'match',
                ['get', 'label'],
                ...flatten(legendFill),
                '#FFFFFF',
              ],
            }}
          />
        </Source>
      )}
      <MapPopupParty
        closeOnClick={false}
        coordinates={coordinates}
        properties={properties}
      />
    </MapGL>
  )
}

export default MapInformation
