import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { IconButton, InputAdornment, Stack, Typography } from '@mui/material'
import { Box } from '@mui/material'
import { ReactComponent as LogoFullDark } from 'assets/images/logoFullDark.svg'
import SplitScreenLayout from 'components/Layout/SplitScreen'
import PasswordCongrat from 'components/PasswordCongrat'
import InputStartIcon from 'components-ui/InputStartIcon'
import { flatten, map } from 'lodash'
import { CheckCircle2, Eye, EyeOff, KeyRound } from 'lucide-react'
import { useSnackbar } from 'notistack'
import { useQueriesPassword } from 'queries/password/password.service'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import alert from 'themes/colors/alert'
import success from 'themes/colors/success'
import { sleep } from 'utils/sleep'

import {
  ValidationSchema,
  schemaDisplayErrors,
  validationSchema,
} from './schema'

const PARAM_RESET = 'reset'

const ResetPasswordPage = () => {
  const [showPassword, setShowPassword] = React.useState(false)
  const { mode, resetPasswordToken } = useParams()

  const {
    isErrorMutateResetPassword,
    isPendingMutateResetPassword,
    mutateResetPassword,
  } = useQueriesPassword()

  const navigate = useNavigate()
  const rightElementFinished = (
    <PasswordCongrat
      text={`Votre mot de passe a été ${mode === PARAM_RESET ? 'réinitialisé' : 'créé'}`}
    />
  )

  const { enqueueSnackbar } = useSnackbar()

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const { control, formState, handleSubmit, trigger } =
    useForm<ValidationSchema>({
      criteriaMode: 'all',
      mode: 'all',
      resolver: zodResolver(validationSchema),
    })

  useEffect(() => {
    trigger('password', { shouldFocus: true })
  }, [trigger])

  if (!resetPasswordToken) {
    navigate('/')
    return <></>
  }

  const checkElements = () => {
    const { errors, isDirty } = formState
    const errorPassword =
      flatten([
        errors.password?.message,
        errors.password?.types?.invalid_string,
      ]) || []

    return (
      <Stack>
        {map(schemaDisplayErrors, ({ key, label }) => {
          return (
            <Stack alignItems="center" direction="row" key={key} spacing={1}>
              <CheckCircle2
                color={
                  !isDirty || errorPassword?.includes(key)
                    ? alert[500]
                    : success[500]
                }
              />
              <Typography>{label}</Typography>
            </Stack>
          )
        })}
      </Stack>
    )
  }

  const onSubmit = async (data: ValidationSchema) => {
    try {
      await sleep(700)
      const { password, passwordConfirm } = data

      await mutateResetPassword({
        password,
        passwordConfirm,
        resetPasswordToken,
      })
    } catch (err: any) {
      enqueueSnackbar(err?.message, {
        variant: 'error',
      })
    }
  }

  const rightElement = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={4}
        sx={{
          width: 430,
        }}
      >
        <Box sx={{ margin: 'auto!important', width: 230 }}>
          <LogoFullDark />
          <Typography
            align="center"
            color="primary"
            sx={{ fontWeight: 700 }}
            variant="body1"
          >
            Lutter contre les déserts médicaux
          </Typography>
        </Box>
        <Typography fontWeight={700} variant="subtitle1">
          {mode === PARAM_RESET ? 'Réinitialisation' : 'Création'} de mot de
          passe
        </Typography>

        <Stack spacing={2}>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <InputStartIcon
                fullWidth
                Icon={KeyRound}
                label={'Mot de passe'}
                type={showPassword ? 'text' : 'password'}
                variant="filled"
                error={
                  !!formState.errors.password &&
                  formState.touchedFields.password
                }
                {...field}
                inputRef={field.ref}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        color="primary"
                        edge="end"
                        size="small"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {checkElements()}
          <Controller
            control={control}
            name="passwordConfirm"
            render={({ field }) => (
              <InputStartIcon
                fullWidth
                error={!!formState.errors.passwordConfirm}
                Icon={KeyRound}
                label={'Confimation du mot de passe'}
                type={showPassword ? 'text' : 'password'}
                variant="filled"
                helperText={
                  <ErrorMessage
                    errors={formState.errors}
                    name="passwordConfirm"
                  />
                }
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        color="primary"
                        edge="end"
                        size="small"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <LoadingButton
          fullWidth
          disabled={!formState.isValid || formState.isSubmitting}
          loading={formState.isSubmitting}
          type="submit"
          variant="contained"
        >
          {mode === PARAM_RESET ? 'Réinitialisation' : 'Créer'}
        </LoadingButton>
      </Stack>
    </form>
  )

  return (
    <SplitScreenLayout
      description="Login"
      title="MedTrucks"
      rightElement={
        formState.isSubmitted &&
        !isErrorMutateResetPassword &&
        !isPendingMutateResetPassword
          ? rightElementFinished
          : rightElement
      }
    ></SplitScreenLayout>
  )
}

export default ResetPasswordPage
