import type { MapRef, ViewState } from 'react-map-gl'

import LegendControl from 'mapboxgl-legend'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import MapGL from 'react-map-gl'
import { getHeadersAuth } from 'services/axios.service'

import { STATE_AREA } from '../InProgress/constants'
import { currentStateType } from '../InProgress/type'
import ControlPanel from './ControlPanel'
import mapStyleDefault from './mapStyle'
import MAP_STYLE from './mapStyle'
import './style.css'

const initialViewState = {
  latitude: 46.75,
  longitude: 2.85,
  zoom: 5,
} as ViewState

const MapInProgressProject = ({
  currentState,
  ifAreaSelected,
}: {
  currentState: currentStateType
  ifAreaSelected?: boolean
}) => {
  const [viewState, setViewState] = useState(initialViewState)
  const mapRef = useRef<MapRef>(null)
  const [hoverInfo, setHoverInfo] = useState<any>({})
  const [mapStyle, setMapStyle] = React.useReducer(
    (state: any, updates: any) => ({ ...state, ...updates }),
    MAP_STYLE,
  )

  const legend = new LegendControl({
    layers: {
      apl: {
        attributes: ['fill-color'],
        highlight: true,
        toggler: true,
      },
    },
  })

  useEffect(() => {
    if (!ifAreaSelected) {
      setViewState(initialViewState)
    }
  }, [ifAreaSelected])

  useEffect(() => {
    setMapStyle(mapStyleDefault)
  }, [])

  const onMouseMove = useCallback((event: any) => {
    const geo = event.features && event.features[0]

    if (geo?.layer?.id) {
      const { lat, lng } = event.lngLat
      setHoverInfo({
        coordinates: [lng, lat],
        layer: geo?.layer?.id,
        properties: geo?.properties,
      })
    }
  }, [])

  const onMouseLeave = useCallback(() => {
    setHoverInfo({})
  }, [])

  const interactiveLayerIds =
    currentState == STATE_AREA
      ? ['regions', 'counties', 'cities']
      : ['parties-selected', 'apl']

  return (
    <MapGL
      {...viewState}
      doubleClickZoom
      attributionControl={false}
      cursor="auto"
      interactiveLayerIds={interactiveLayerIds}
      mapboxAccessToken="pk.eyJ1IjoiYnNvZ2hpZ2lhbiIsImEiOiJjazBhOTUxam4wMDNtM2RvNXJzbjQ5aGV6In0.eL8NJ0-ikx_5Dl49994bGw"
      mapStyle={mapStyle || undefined}
      minZoom={3}
      ref={mapRef}
      style={{ height: '100%', width: '100%' }}
      onLoad={() => {
        mapRef.current?.getMap().addControl(legend, 'bottom-right')
      }}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onMove={(evt) => setViewState(evt.viewState)}
      transformRequest={(url, resourceType) => {
        if (
          resourceType === 'Tile' &&
          !url.startsWith('https://api.mapbox.com')
        ) {
          return {
            headers: getHeadersAuth(),
            url: url,
          }
        }
        return { url }
      }}
    >
      <ControlPanel
        currentState={currentState}
        hoverInfo={hoverInfo}
        mapRef={mapRef}
        setMapStyle={setMapStyle}
      />
    </MapGL>
  )
}

export default MapInProgressProject
