import Box from '@mui/material/Box'
import * as React from 'react'

import type { LayoutProps } from '../types'

import HelmetCustom from '../Helmet'
import AppBar from './AppBar'
import DrawerFeedback from './Feedback'
import Menu from './Menu'
import { DrawerStyled } from './styles'

export const drawerWidth = 250

export default function ResponsiveDrawer({
  children,
  description,
  ogImage,
  title,
  url,
  window,
}: LayoutProps) {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isOpenDrawerFeedback, setIsOpenDrawerFeedback] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const openDrawerFeedback = (open: boolean) => setIsOpenDrawerFeedback(open)

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <HelmetCustom
        description={description}
        ogImage={ogImage}
        title={title}
        url={url}
      />
      <AppBar handleDrawerToggle={handleDrawerToggle} />
      <Box
        aria-label="mailbox folders"
        component="nav"
        sx={{
          flexShrink: { sm: 0 },
          width: { sm: drawerWidth },
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <DrawerStyled
          container={container}
          open={mobileOpen}
          sx={{ display: { sm: 'none', xs: 'block' } }}
          variant="temporary"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Menu openDrawerFeedback={openDrawerFeedback} />
        </DrawerStyled>
        <DrawerStyled
          open
          sx={{ display: { sm: 'block', xs: 'none' } }}
          variant="permanent"
        >
          <Menu openDrawerFeedback={openDrawerFeedback} />
        </DrawerStyled>
      </Box>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          maxWidth: {
            sm: `calc(100vw - ${drawerWidth}px)`,
          },
          width: {
            sm: `calc(100vw - ${drawerWidth}px)`,
          },
        }}
      >
        {children}
      </Box>
      <DrawerFeedback
        isOpenDrawer={isOpenDrawerFeedback}
        handleCloseOpenDrawer={() => openDrawerFeedback(false)}
      />
    </Box>
  )
}
