import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useAxios } from 'services/axios.service'
import { SpecialityType } from 'types/speciality'

import { myDiagnosticExtraState } from '../atoms'

const defaultValueApl = '1'

const HeaderTypeDiagnostic = () => {
  const setMyDiagnosticExtra = useSetRecoilState(myDiagnosticExtraState)
  const myDiagnosticExtra = useRecoilValue(myDiagnosticExtraState)

  const [typeDiagnistics, setTypeDiagnostics] = useState<number>(
    myDiagnosticExtra.speciality.value ? 1 : 0,
  )

  const paramsUrl = new URLSearchParams({
    crudQuery: JSON.stringify({
      page: 1,
      pageSize: 150,
    }),
  })

  const { response: specialities } = useAxios({
    url: `/speciality?${paramsUrl}`,
  })

  const specialityExists = specialities?.data?.[0]?.code

  const optionsType = [
    {
      label: 'Territorial',
      value: 0,
    },
  ]

  if (specialityExists) {
    optionsType.push({
      label: 'Apl',
      value: 1,
    })
  }

  const onChangeSelectType = (event: any) => {
    const {
      target: { value },
    } = event

    setTypeDiagnostics(value)
    // On remove la specialité en mode territorial / on set 1 en mode spécialité
    let spe = {
      label: '',
      value: '',
    }
    if (value === 1) {
      //if mode spe activated
      const speciality = specialities?.data.find(
        (speciality: any) => speciality.code === defaultValueApl,
      )
      spe = {
        label: speciality.name,
        value: speciality.code,
      }
    }
    setMyDiagnosticExtra((current) => ({
      ...current,
      speciality: spe,
    }))
  }
  const onChangeSelectSpeciality = (event: any) => {
    const {
      target: { value },
    } = event
    const speciality = specialities?.data.find(
      (speciality: any) => speciality.code === value,
    )

    setMyDiagnosticExtra((current) => ({
      ...current,
      speciality: {
        label: speciality.name,
        value: speciality.code,
      },
    }))
  }

  return (
    <>
      <FormControl sx={{ width: '200px' }} variant="filled">
        <InputLabel id="SelectDiagnoticsLabel">Type</InputLabel>
        <Select
          id="SelectDiagnotics-standard"
          labelId="SelectDiagnoticsLabel"
          value={typeDiagnistics}
          onChange={(event: any) => onChangeSelectType(event)}
        >
          {optionsType.map((type) => (
            <MenuItem key={type.label} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {specialityExists && typeDiagnistics === 1 && (
        <FormControl sx={{ width: '200px' }} variant="filled">
          <InputLabel id="SelectSpecialityLabel">Spécialité</InputLabel>
          <Select
            defaultValue={specialities.data[0].code}
            id="SelectSpeciality-standard"
            labelId="SelectSpecialityLabel"
            value={myDiagnosticExtra.speciality.value}
            onChange={(event: any) => onChangeSelectSpeciality(event)}
          >
            {specialities.data.map((speciality: SpecialityType) => (
              <MenuItem key={speciality.name} value={speciality.code}>
                {speciality.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  )
}

export default HeaderTypeDiagnostic
