import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import { Divider, Grid, IconButton, Paper, Typography } from '@mui/material'
import { heightHeader } from 'constants/ui'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Header = ({
  arrowBack,
  rowLeftDescription,
  rowLeftTitle,
  rowRight,
}: {
  arrowBack?: boolean
  rowLeftDescription?: string
  rowLeftTitle: string
  rowRight?: React.ReactNode
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Paper
        sx={{
          borderRadius: 0,
          position: 'relative',
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Grid
          container
          alignItems="center"
          height={`${heightHeader}px`}
          pl={2}
          pr={2}
        >
          {arrowBack && (
            <IconButton
              aria-label="delete"
              size="medium"
              sx={{ mr: 2 }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Grid item flexGrow={1}>
            <Typography color="primary" variant="h4">
              {rowLeftTitle}
            </Typography>
            <Typography>{rowLeftDescription}</Typography>
          </Grid>
          {rowRight && (
            <Grid item alignItems="center" display="flex">
              {rowRight}
            </Grid>
          )}
        </Grid>
      </Paper>
      <Divider />
    </>
  )
}

export default Header
