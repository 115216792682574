// import HistoryIcon from '@mui/icons-material/HistoryRounded'
// import ReadMoreIcon from '@mui/icons-material/ReadMoreRounded'
import { Grid, alpha } from '@mui/material'
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js'
import 'chart.js/auto'
// import { useNavigate } from 'react-router-dom'
import Alert from 'components-ui/Alert'
import { map } from 'lodash'
import * as React from 'react'
import { Radar } from 'react-chartjs-2'
import { useAxios } from 'services/axios.service'
import neutral from 'themes/colors/neutral'
import primary from 'themes/colors/primary'
import secondary from 'themes/colors/secondary'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

const Chart = ({
  currQuestionnaireId,
  infos,
  partyId,
}: {
  currQuestionnaireId: number | undefined
  infos: any
  partyId: string
}) => {
  // const navigate = useNavigate()
  const [data, setData] = React.useState({ datasets: [] as any } as any)
  const chartRef = React.useRef<ChartJS<'radar', number[], string>>(null)

  const {
    loading: loadingObjectives,
    refresh: refreshObjectives,
    response: objectives,
  } = useAxios({
    noFetchInit: true,
    url: `questionnaires/${currQuestionnaireId}/objectives`,
  })
  const {
    loading: loadingNotes,
    refresh: refreshNotes,
    response: notes,
  } = useAxios({
    noFetchInit: true,
    url: `questionnaires/${currQuestionnaireId}/parties/${partyId}/notes`,
  })

  React.useEffect(() => {
    if (currQuestionnaireId) {
      refreshObjectives()
      refreshNotes()
    }
  }, [currQuestionnaireId])

  React.useEffect(() => {
    if (objectives && !loadingObjectives && !loadingNotes) {
      const labels = map(objectives, (o: { label: string }) => o.label)
      const newDataObjectives = map(
        objectives,
        (o: { objective: number }) => o.objective,
      )
      const newDataNotes = map(notes, (o: { note: number }) => o.note)
      const datasets = [] as any
      if (newDataNotes.length) {
        datasets.push({
          backgroundColor: alpha(primary[500], 0.2),
          borderColor: primary[500],
          borderWidth: 2,
          data: newDataNotes,
          label: 'Mon établissement',
          pointRadius: 2,
        })
      }
      datasets.push({
        backgroundColor: alpha(secondary[500], 0.2),
        borderColor: secondary[500],
        borderWidth: 2,
        data: newDataObjectives,
        label: 'Objectif projet',
        pointRadius: 2,
      })

      setData({
        datasets,
        labels,
      })
      chartRef?.current?.update('none')
    }
  }, [loadingNotes, loadingObjectives])

  const getBlocAlert = React.useCallback(() => {
    if (infos.answersCount === 0) {
      return (
        <Grid item textAlign="center" xs={12}>
          <Alert
            text={`Aucune information pour une analyse complète. Veuillez commencer le
            questionnaire.`}
          />
        </Grid>
      )
    } else if (infos.progressValue !== 100) {
      return (
        <Grid item textAlign="center" xs={12}>
          <Alert
            text={`Manque d’informations pour une analyse complète. Veuillez terminer le
        questionnaire.`}
          />
        </Grid>
      )
    }
  }, [infos])

  return (
    <Grid container direction="column" spacing={5}>
      {infos && getBlocAlert()}
      <Grid
        item
        display="flex"
        height={480}
        justifyContent="center"
        minWidth={400}
        sx={{ margin: 'auto' }}
        width={650}
      >
        <Radar
          data={data}
          ref={chartRef}
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: false,
              },
              legend: {
                labels: {
                  color: neutral[800],
                  font: {
                    family: 'Nunito',
                    size: 10,
                    weight: 'bold',
                  },
                },
              },
            },
            responsive: true,
            scales: {
              r: {
                beginAtZero: true,
                max: 5,
                min: 0,
                pointLabels: {
                  color: neutral[900],
                  font: {
                    family: 'Nunito',
                    size: 14,
                    weight: 'bold',
                  },
                  padding: 10,
                },
                ticks: {
                  display: false,
                  major: {
                    enabled: true,
                  },
                  stepSize: 1,
                },
              },
            },
          }}
        />
      </Grid>
      {/* <Grid item textAlign="right" xs={12}>
        <Button
          variant="outlined"
          startIcon={<ReadMoreIcon />}
          onClick={() => navigate('/party/94650/questionnaire/6789')}
        >
          Voir détail
        </Button>
        <Button
          disabled
          variant="outlined"
          startIcon={<HistoryIcon />}
          sx={{ ml: 2 }}
        >
          Historique
        </Button>
      </Grid> */}
    </Grid>
  )
}

export default Chart
