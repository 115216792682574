import { stateOfInProgressInfosType } from './type'

export const drawerWidth = 250

export const STATE_AREA = 'AREA'
export const STATE_SPECIALITY = 'SPECIALITY'
export const STATE_FOCUS = 'FOCUS'
export const STATE_PARTY = 'PARTY'
export const STATE_OVERVIEW = 'OVERVIEW'

export const CONST_COUNTRY = 'countryCode'
export const CONST_REGIONS = 'regionCode'
export const CONST_COUNTIES = 'countyCode'
export const CONST_CITIES = 'cityCode'
export const CONST_CPTS = 'cptsCode'
export const CONST_DAC = 'dacCode'
export const CONST_TVS = 'tvsCode'
export const CONST_EPCI = 'epciCode'

export const CONST_CATEGORY = 'category'
export const CONST_CATEGORY_PERSON = 'categoryPerson'
export const CONST_CATEGORY_ORGANIZATION = 'categoryOrganization'
export const CONST_JOURNEY = 'journey'
export const CONST_EXPERTISE = 'expertise'
export const CONST_PARTTYPE = 'partyType'
export const CONST_SEARCH = 'search'
export const CONST_NAME = 'name'

export const CONST_ORGANIZATION = 'organization'
export const CONST_PERSON = 'person'

export const labelChipsFilter = {
  [CONST_CATEGORY]: 'Catégorie',
  [CONST_CATEGORY_ORGANIZATION]: "Catégorie d'établissement",
  [CONST_CATEGORY_PERSON]: 'Profession',
  [CONST_CITIES]: 'Localisation',
  [CONST_COUNTIES]: 'Localisation',
  [CONST_CPTS]: 'CPTS',
  [CONST_DAC]: 'DAC',
  [CONST_EPCI]: 'EPCI',
  [CONST_EXPERTISE]: 'Savoir faire',
  [CONST_JOURNEY]: 'Parcours',
  [CONST_NAME]: 'Nom des établissements et PS',
  [CONST_PARTTYPE]: 'Type',
  [CONST_REGIONS]: 'Localisation',
  [CONST_SEARCH]: 'Recherche',
  [CONST_TVS]: 'TVS',
}

export const listOfArea = [
  CONST_COUNTRY,
  CONST_REGIONS,
  CONST_COUNTIES,
  CONST_CITIES,
  CONST_CPTS,
  CONST_DAC,
  CONST_TVS,
  CONST_EPCI,
] as const
export const listOfFocus = [
  CONST_CATEGORY_PERSON,
  CONST_CATEGORY_ORGANIZATION,
  CONST_JOURNEY,
  CONST_EXPERTISE,
] as const
export const listOfParty = [CONST_ORGANIZATION, CONST_PERSON] as const

export const stateOfInProgress = [
  STATE_AREA,
  STATE_SPECIALITY,
  STATE_FOCUS,
  STATE_PARTY,
  STATE_OVERVIEW,
] as const

export const stateOfInProgressInfos = {
  [STATE_AREA]: {
    description: 'Definissez le territoire de votre projet',
    progressBarStatus: 25,
    title: 'Territoire',
  },
  [STATE_FOCUS]: {
    description: 'Ciblez au mieux votre projet',
    progressBarStatus: 50,
    title: 'Focus',
  },
  [STATE_OVERVIEW]: {
    description: 'Récapitulatif de votre projet',
    progressBarStatus: 95,
    title: 'Synthèse',
  },
  [STATE_PARTY]: {
    description: 'Précisez les établissements et PS de votre projet',
    progressBarStatus: 75,
    title: 'Etablissement & Personnel de santé',
  },
  [STATE_SPECIALITY]: {
    description: 'Quelle est la thématique de votre projet',
    progressBarStatus: 35,
    title: 'Spécialité',
  },
} as stateOfInProgressInfosType
