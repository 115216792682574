import { Divider, Grid, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ReactComponent as NoResultIcon } from 'assets/images/noResult.svg'
import { ReactComponent as SearchNoResultIcon } from 'assets/images/searchNoResult.svg'
import axios from 'axios'
import { type MRT_PaginationState } from 'material-react-table'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import HeaderList from './Header'
import TableList from './Table'

const pageSize = 50

const UsersAdmin = () => {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const { data, isLoading, refetch } = useQuery({
    queryFn: async ({ queryKey: [, page, search] }) => {
      const tsquerySpecialChars = /[()|&:*!]/g
      const searchTrim = search
        .toString()
        .trim()
        .replace(tsquerySpecialChars, ' ')
        .split(/\s+/)
        .join(' | ')
      const url = `/users?${new URLSearchParams({
        crudQuery: JSON.stringify({
          page: page,
          pageSize,
          where: {
            ...(search
              ? {
                  OR: [
                    {
                      email: { search: searchTrim },
                    },
                    {
                      email: {
                        contains: search,
                        mode: 'insensitive',
                      },
                    },
                    {
                      firstname: { search: searchTrim },
                    },
                    {
                      firstname: {
                        contains: search,
                        mode: 'insensitive',
                      },
                    },
                    {
                      name: { search: searchTrim },
                    },
                    {
                      name: {
                        contains: search,
                        mode: 'insensitive',
                      },
                    },
                  ],
                }
              : {}),
          },
        }),
      })}`
      const { data } = await axios.get(url)
      return data
    },
    queryKey: [`admin-users-list`, page, search],
  })

  const onPaginationChange = (pagination: MRT_PaginationState) => {
    setPage(pagination.pageIndex + 1)
  }

  const onSubmitFetch = ({ search }: { search: string }) => {
    const page = 1
    setPage(page)
    setSearch(search)
  }

  const Header = (
    <>
      <Grid container>
        <Grid item xs={12}>
          <HeaderList
            openCreateProjectModal={() => navigate('/admin/users/create')}
            onSubmitFetch={onSubmitFetch}
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  )

  if (data?.length === 0) {
    return (
      <>
        {Header}
        <Stack
          alignItems="center"
          display="flex"
          flexGrow={1}
          justifyContent="center"
        >
          <NoResultIcon height={200} />
          <Typography mt={2}>Aucun utilisateur</Typography>
        </Stack>
      </>
    )
  }

  return (
    <>
      {Header}
      <Grid container p={2}>
        <Grid item xs={12}>
          <Typography fontWeight={700}>
            {data?.totalRecords || 0} Utilisateur(s)
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        {(isLoading || data?.totalRecords > 0) && (
          <TableList
            data={data?.data}
            loading={isLoading}
            pageSize={pageSize}
            refetch={refetch}
            rowCount={data?.totalRecords}
            onPaginationChange={onPaginationChange}
          />
        )}
        {data?.totalRecords === 0 && (
          <Stack margin="30px auto">
            <SearchNoResultIcon height={100} width={100} />
            <Typography>Pas d'utilisateurs trouvés</Typography>
          </Stack>
        )}
      </Grid>
    </>
  )
}

export default UsersAdmin
