import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import { aggsListState } from '../atoms'

export const GetAggs = (field: string) => {
  const setAggsListValues = useSetRecoilState(aggsListState)

  const { data: response, isLoading } = useQuery({
    queryFn: async ({ queryKey: [, field] }) => {
      if (field) {
        const { data } = await axios.get(
          `/opensearch/aggs/party?params={"field":"${field}.keyword"}`,
        )
        return data
      }
    },
    queryKey: [`get-aggs-party-${field}`, field],
  })

  useEffect(() => {
    if (!isLoading && response?.data?.buckets?.length > 0)
      setAggsListValues((oldValues) => ({
        ...oldValues,
        [field]: response.data.buckets,
      }))
  }, [response])

  return
}
