import { TextField, styled } from '@mui/material'

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    paddingLeft: 0,
  },
  '& fieldset': {
    display: 'none',
  },
  boxShadow: 'none',
  display: 'flex',
  flexGrow: 1,
  padding: 4,
}))
