import { Box } from '@mui/material'
import AreaFilterComponent from 'components/Filters/AsideFilters/GeoFilters/AreaFilters'
import useConfirmChangeAreaFilter from 'components/Filters/AsideFilters/GeoFilters/AreaFilters/ConfirmChange'
import { aggsListState } from 'pages/Core/atoms'
import { getIfAreaSelected } from 'pages/Core/helper'
import React from 'react'
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { mySearchType } from 'types/recoils/parties-search'

const GeoFilters = ({
  defaultMyFiltersState,
  myFiltersState,
  nextMyFiltersState,
  refetchSubmit,
}: {
  defaultMyFiltersState: mySearchType
  myFiltersState: RecoilState<mySearchType>
  nextMyFiltersState: RecoilState<mySearchType>
  refetchSubmit: any
}) => {
  const aggs = useRecoilValue(aggsListState)
  const setNextFilters = useSetRecoilState(nextMyFiltersState)
  const nextFilters = useRecoilValue(nextMyFiltersState)
  const filters = useRecoilValue(myFiltersState)
  const setFiltersParams = useSetRecoilState(myFiltersState)
  const [getConfirmation, Confirmation] = useConfirmChangeAreaFilter()

  const ifAreaSelected = getIfAreaSelected(filters?.options)

  const onChangeRadioEvent = async () => {
    if (ifAreaSelected) {
      await getConfirmation()

      setNextFilters(defaultMyFiltersState)
      setFiltersParams(defaultMyFiltersState)
      refetchSubmit()

      return defaultMyFiltersState
    }
    return nextFilters
  }

  if (!aggs) {
    return <></>
  }

  return (
    <Box m={2}>
      <AreaFilterComponent
        myData={nextFilters}
        setMyData={setNextFilters}
        onChangeRadioEvent={onChangeRadioEvent}
      />
      <Confirmation />
    </Box>
  )
}

export default GeoFilters
