import { AbilityContext } from 'components/Ability/Can'
import React from 'react'

//CREATE
export const ALLOWED_ACTION_CREATE_PROJECT = 'CREATE_PROJECT'
//MA?AGE
export const ALLOWED_ACTION_MANAGE_ROLE = 'MANAGE_ROLE'
export const ALLOWED_ACTION_MANAGE_USER = 'MANAGE_USER'
//READ
export const ALLOWED_ACTION_READ_PROJECT = 'READ_PROJECT'
export const ALLOWED_ACTION_READ_DIAGNOSTIC = 'READ_DIAGNOSTIC'
export const ALLOWED_ACTION_READ_QUESTIONNAIRE = 'READ_QUESTIONNAIRE'
export const ALLOWED_ACTION_READ_USER = 'READ_USER'
//UPDATE
export const ALLOWED_ACTION_UPDATE_USER = 'UPDATE_USER'

const ALLOWED_CONFIG = {
  [ALLOWED_ACTION_CREATE_PROJECT]: ['create', 'Project'],
  [ALLOWED_ACTION_MANAGE_ROLE]: ['manage', 'Role'],
  [ALLOWED_ACTION_MANAGE_USER]: ['manage', 'User'],
  [ALLOWED_ACTION_READ_DIAGNOSTIC]: ['read', 'Diagnostic'],
  [ALLOWED_ACTION_READ_PROJECT]: ['read', 'Project'],
  [ALLOWED_ACTION_READ_QUESTIONNAIRE]: ['read', 'Questionnaire'],
  [ALLOWED_ACTION_READ_USER]: ['read', 'User'],
  [ALLOWED_ACTION_UPDATE_USER]: ['update', 'User'],
}

export type AllowedActionType = keyof typeof ALLOWED_CONFIG
export type AllowedConfigType =
  (typeof ALLOWED_CONFIG)[keyof typeof ALLOWED_CONFIG]

class PermissionsService {
  getPermission = (action: AllowedActionType) => {
    const configAction = ALLOWED_CONFIG[action]

    const ability = React.useContext(AbilityContext)

    return ability.can(configAction[0], configAction[1])
  }
}

export default new PermissionsService()
