import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { map } from 'lodash'
import React from 'react'
import { Control, Controller, FormState } from 'react-hook-form'

import { ValidationSchema } from '.'
import { useQueriesRoles } from '../../Roles/queries'

const UsersAdminCreateEditSelectRole = ({
  control,
  formState,
}: {
  control: Control<ValidationSchema>
  formState: FormState<ValidationSchema>
}) => {
  const { data: roleData } = useQueriesRoles({})

  return (
    <Controller
      control={control}
      name="roleId"
      render={({ field }) => (
        <FormControl fullWidth sx={{ m: 2 }} variant="filled" {...field}>
          <InputLabel
            color="primary"
            error={!!formState.errors.roleId}
            id="role-select-user-label"
          >
            Rôle
          </InputLabel>
          <Select
            error={!!formState.errors.roleId}
            id="standard-role-user-input"
            inputRef={field.ref}
            labelId="role-select-user-label"
            placeholder="Selectionner votre type de retour"
            {...field}
            value={field.value || ''}
          >
            {map(roleData?.data, (role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            <ErrorMessage errors={formState.errors} name="roleId" />
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default UsersAdminCreateEditSelectRole
