import type { MapRef, MapStyleDataEvent } from 'react-map-gl'

import bbox from '@turf/bbox'
import MapPopupApi from 'components/Map/Popup/Apl'
import MapPopupParty from 'components/Map/Popup/Party'
import MapPopupSample from 'components/Map/Popup/Sample'
import { cloneDeep, compact, get, isEmpty, set } from 'lodash'
import { formattedFilterParams, getIfAreaSelected } from 'pages/Core/helper'
import { useEffect } from 'react'
import * as React from 'react'
import { useRecoilValue } from 'recoil'
import {
  myDiagnosticExtraType,
  myDiagnosticType,
} from 'types/recoils/diagnostics'

import { myDiagnosticExtraState, myDiagnosticState } from '../../atoms'
import { getStylePaintApl } from './helpers'
import MAP_STYLE from './mapStyle'

const defaultMapStyle = cloneDeep(MAP_STYLE)
const defaultLayers = defaultMapStyle.layers

const schemaLayerSelectedByAreaType = {
  cityCode: { id: 'cities', source: 'cities' },
  countryCode: { id: 'countries', source: 'countries' },
  countyCode: { id: 'counties', source: 'counties' },
  cptsCode: { id: 'cpts', source: 'cpts' },
  dacCode: { id: 'dac', source: 'dac' },
  epciCode: { id: 'epci', source: 'epci' },
  regionCode: { id: 'regions', source: 'regions' },
  tvsCode: { id: 'tvs', source: 'tvs' },
}

const layersTerritory = [
  'regions',
  'counties',
  'cities',
  'cpts',
  'dac',
  'tvs',
  'epci',
]
const layersSpeciality = ['apl']
const layersParties = ['parties-selected']

const layersToBeFilteredSelectedInfos = {
  apl: {
    matchOptionsWithProperties: {
      cityCode: 'cityCode',
      countyCode: 'countyCode',
      cptsCode: 'cptsCode',
      dacCode: 'dacCode',
      epciCode: 'epciCode',
      regionCode: 'regionCode',
      tvsCode: 'tvsCode',
    },
    nameLayer: 'apl',
  },
  'cities-selected': {
    matchOptionsWithProperties: { cityCode: 'code' },
    nameLayer: 'cities-selected',
  },
  'counties-selected': {
    matchOptionsWithProperties: { countyCode: 'code' },
    nameLayer: 'counties-selected',
  },
  'cpts-selected': {
    matchOptionsWithProperties: { cptsCode: 'code' },
    nameLayer: 'cpts-selected',
  },
  'dac-selected': {
    matchOptionsWithProperties: { dacCode: 'code' },
    nameLayer: 'dac-selected',
  },
  'epci-selected': {
    matchOptionsWithProperties: { epciCode: 'code' },
    nameLayer: 'epci-selected',
  },
  'parties-selected': {
    matchOptionsWithProperties: {
      category: 'category',
      cityCode: 'cityCode',
      countyCode: 'countyCode',
      cptsCode: 'cptsCode',
      dacCode: 'dacCode',
      epciCode: 'epciCode',
      expertise: 'expertise',
      journey: 'journey',
      name: 'name',
      party: 'partyId',
      partyType: 'partyType',
      regionCode: 'regionCode',
      tvsCode: 'tvsCode',
    },
    nameLayer: 'parties-selected',
  },
  'regions-selected': {
    matchOptionsWithProperties: { regionCode: 'code' },
    nameLayer: 'regions-selected',
  },
  'tvs-selected': {
    matchOptionsWithProperties: { tvsCode: 'code' },
    nameLayer: 'tvs-selected',
  },
} as any

const getMapStyle = ({
  myDiagnostic,
  myDiagnosticExtra,
}: {
  myDiagnostic: myDiagnosticType
  myDiagnosticExtra: myDiagnosticExtraType
}) => {
  const areaType = myDiagnostic.areaType
  const specialityCode = myDiagnosticExtra.speciality.value

  const filterApplied = {
    specialityCode: [myDiagnosticExtra.speciality.value],
    ...formattedFilterParams(myDiagnostic.options),
  } as any

  const layers = compact(
    defaultLayers
      .map((layer: any) => {
        const id = get(layer, 'id')
        const metadata = get(layer, 'metadata')

        if (layersSpeciality.includes(id)) {
          if (!specialityCode) return
          // on affiche vient colorer les polygone apl en fct de specialityCode
          return { ...layer, paint: getStylePaintApl(specialityCode) }
        }

        // on affiche uniquement le layer geo en fonction du select areaType
        if (
          !layersTerritory.includes(id) ||
          (layersTerritory.includes(id) && areaType === metadata)
        ) {
          return layer
        }
      })
      .map((layer: any) => {
        if (!layer) return
        const id = get(layer, 'id')

        //on veut display les features de la liste layersToBeFilteredSelectedInfos
        const layerToFiltered = Object.keys(layersToBeFilteredSelectedInfos)
        if (layerToFiltered && layerToFiltered.includes(id)) {
          const layersInfos = layersToBeFilteredSelectedInfos[id]

          if (layersInfos.nameLayer === id) {
            const { matchOptionsWithProperties } = layersInfos
            const options = Object.keys(matchOptionsWithProperties)

            const matches = options.reduce((acc: any, option: string) => {
              const propertie = matchOptionsWithProperties[option]
              const value = filterApplied[option]

              if (!isEmpty(value))
                return [...acc, ['any', ['in', propertie, ...value]]]
              return acc
            }, [])

            if (isEmpty(matches)) return layer

            return {
              ...layer,
              filter: ['all', ...matches],
            }
          }
        }
        return layer
      }),
  )

  const newLayers = set(defaultMapStyle, 'layers', layers)

  return newLayers
}

const zoomOnFeaturesAreaSelected = ({
  hasAreaSelected,
  mapRef,
  myDiagnostic,
}: {
  hasAreaSelected: boolean
  mapRef: React.RefObject<MapRef> | null
  myDiagnostic: myDiagnosticType
}) => {
  const zoom = (event: MapStyleDataEvent & any) => {
    const layerAreaSelected =
      schemaLayerSelectedByAreaType[myDiagnostic.areaType]
    const filter = event.target.getFilter(`${layerAreaSelected.id}-selected`)

    const featuresAreaSelected =
      event.target.querySourceFeatures(layerAreaSelected.id, {
        filter,
        sourceLayer: layerAreaSelected.source,
      }) || []

    if (
      hasAreaSelected &&
      featuresAreaSelected &&
      featuresAreaSelected?.length > 0
    ) {
      const poly = bbox({
        features: featuresAreaSelected?.map((feature: any) => feature) || [],
        type: 'FeatureCollection',
      }) as [number, number, number, number]
      setTimeout(() => {
        event.target?.fitBounds(poly, { maxZoom: 13 })
      }, 300)
    }
  }

  mapRef?.current?.on('styledata', zoom)
  mapRef?.current?.on('load', zoom)
}

const ControlPanelMapInProgressProject = ({
  hoverInfo,
  mapRef,
  setMapStyle,
}: {
  hoverInfo: any
  mapRef: React.RefObject<MapRef> | null
  setMapStyle: any
}) => {
  const myDiagnostic = useRecoilValue(myDiagnosticState)
  const myDiagnosticExtra = useRecoilValue(myDiagnosticExtraState)
  const hasAreaSelected = getIfAreaSelected(myDiagnostic.options)

  useEffect(() => {
    const newStyle = getMapStyle({
      myDiagnostic,
      myDiagnosticExtra,
    })

    setMapStyle(newStyle)
    zoomOnFeaturesAreaSelected({
      hasAreaSelected,
      mapRef,
      myDiagnostic,
    })
  }, [myDiagnostic, myDiagnosticExtra])

  return (
    <>
      {layersTerritory.includes(hoverInfo.layer) && (
        <MapPopupSample
          coordinates={hoverInfo.coordinates}
          properties={hoverInfo.properties}
        />
      )}

      {layersSpeciality.includes(hoverInfo.layer) && (
        <MapPopupApi
          coordinates={hoverInfo.coordinates}
          properties={hoverInfo.properties}
          specialityCode={myDiagnosticExtra.speciality.value}
        />
      )}

      {layersParties.includes(hoverInfo.layer) && (
        <MapPopupParty
          coordinates={hoverInfo.coordinates}
          properties={hoverInfo.properties}
        />
      )}
    </>
  )
}

export default ControlPanelMapInProgressProject
