import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import ConfirmDrawer from 'components/ConfirmDrawer'
import { PartyType } from 'constants/enum'
import { KanbanSquare, Trash } from 'lucide-react'
import {
  type MRT_ColumnDef,
  type MRT_PaginationState,
  type MRT_Row,
  type MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import { useSnackbar } from 'notistack'
import {
  myDiagnosticExtraState,
  myDiagnosticState,
  nextMyDiagnosticState,
} from 'pages/Core/Diagnostics/atoms'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { ProjectType } from 'types/project'

import {
  formatAreasToSelectOptions,
  formatPartiesToSelectOptions,
  getAreaType,
} from '../../utils'

const columns: MRT_ColumnDef<ProjectType>[] = [
  {
    accessorKey: 'id',
    header: 'Id',
    size: 80,
  },
  {
    accessorKey: 'name',
    header: 'Nom',
  },
  {
    accessorFn: (row) => row.parties.length,
    header: 'Etablissement(s) /PS',
  },
  {
    accessorKey: 'speciality.name',
    header: 'Specialité',
  },
  {
    accessorFn: (row) => `${row.user.firstname} ${row.user.name}`,
    header: 'Utilisateur',
  },
]

const initialStateConfirmDrawer = {
  onSubmit: null as any,
  open: false,
}

const TableList = ({
  data,
  loading,
  onPaginationChange,
  pageSize,
  rowCount,
}: {
  data: any
  loading: boolean
  onPaginationChange: any
  pageSize: number
  rowCount: number
}) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const setMyDiagnostic = useSetRecoilState(myDiagnosticState)
  const setMyDiagnosticExtra = useSetRecoilState(myDiagnosticExtraState)
  const setNextMyDiagnostic = useSetRecoilState(nextMyDiagnosticState)
  const [confirmDrawerState, setConfirmDrawerState] = React.useState(
    initialStateConfirmDrawer,
  )
  const resetStateConfirmDrawer = () =>
    setConfirmDrawerState(initialStateConfirmDrawer)

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize,
  })

  useEffect(() => {
    onPaginationChange(pagination)
  }, [pagination])

  const { isPending: isPendingDelete, mutate: mutateDelete } = useMutation({
    mutationFn: async ({ id, ids }: { id?: number; ids?: Array<number> }) => {
      if (id) return axios(`/projects/${id}`, { method: 'DELETE' })
      if (ids) return axios(`/projects`, { data: { ids }, method: 'DELETE' })
    },
    mutationKey: ['projects-list-delete'],
    onError: (error, { id, ids }) => {
      const errorMessage = `Erreur lors de la suppression de(s) projet(s) n° ${id || ids}`
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    },
    onSuccess: (data, { id, ids }) => {
      table.setRowSelection({})
      const successMessage = `Suppression : projet(s) n° ${id || ids} réussi`
      enqueueSnackbar(successMessage, {
        variant: 'success',
      })
      return onPaginationChange(pagination)
    },
  })

  const handleDeleteOneRow = (event: any, row: MRT_Row<ProjectType>) => {
    event.stopPropagation()
    setConfirmDrawerState({
      onSubmit: async () => {
        await mutateDelete({ id: row.original.id })
        resetStateConfirmDrawer()
      },
      open: true,
    })
  }

  const handleGoDiagnostic = (event: any, row: MRT_Row<ProjectType>) => {
    event.stopPropagation()
    const project = row.original

    const organizationList = project.parties.filter(
      (project: any) => project.partyType === PartyType.ORGANIZATION,
    )
    const personList = project.parties.filter((project: any) => {
      return project.partyType === PartyType.PERSON
    })
    const newDiagnosticExtra = {
      name: project.name,
      speciality: {
        label: project.speciality.name,
        value: project.speciality.code.toString(),
      },
    }
    const newDiagnostic = {
      areaType: getAreaType(project),
      options: {
        category: [],
        categoryOrganization: [],
        categoryPerson: [],
        cityCode: formatAreasToSelectOptions(project.cities),
        countryCode: [],
        countyCode: formatAreasToSelectOptions(project.counties),
        cptsCode: formatAreasToSelectOptions(project.cpts),
        dacCode: formatAreasToSelectOptions(project.dac),
        epciCode: formatAreasToSelectOptions(project.epci),
        expertise: [],
        journey: [],
        partyType: [],
        regionCode: formatAreasToSelectOptions(project.regions),
        search: [],
        tvsCode: formatAreasToSelectOptions(project.tvs),
      },
      party: {
        organization: formatPartiesToSelectOptions(organizationList),
        person: formatPartiesToSelectOptions(personList),
      },
    }
    setMyDiagnosticExtra(newDiagnosticExtra)
    setMyDiagnostic(newDiagnostic)
    setNextMyDiagnostic(newDiagnostic)
    navigate('/diagnostics')
  }

  const onClickRow = (id: number) => {
    navigate(`/projects/${id}`)
  }

  const renderRowActions = ({ row }: { row: MRT_Row<ProjectType> }) => (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <Tooltip title="Récupérer la configuration">
        <IconButton
          color="primary"
          onClick={(event) => handleGoDiagnostic(event, row)}
        >
          <KanbanSquare />
        </IconButton>
      </Tooltip>
      <Tooltip title="Supprimer">
        <IconButton
          color="error"
          disabled={isPendingDelete}
          onClick={(event) => handleDeleteOneRow(event, row)}
        >
          {isPendingDelete ? (
            <CircularProgress color="error" size={20} />
          ) : (
            <Trash />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  )

  const renderToolbarInternalActions = ({
    table,
  }: {
    table: MRT_TableInstance<ProjectType>
  }) => (
    <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
      <Button
        color="error"
        disabled={table.getSelectedRowModel().rows.length === 0}
        startIcon={<Trash />}
        variant="text"
        onClick={() =>
          setConfirmDrawerState({
            onSubmit: async () => {
              await mutateDelete({
                ids: table.getSelectedRowModel().rows.map((o) => o.original.id),
              })
              resetStateConfirmDrawer()
            },
            open: true,
          })
        }
      >
        Supprimer
      </Button>
    </Box>
  )

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enablePagination: true,
    enableRowActions: true,
    enableRowSelection: true,
    enableSorting: false,
    enableStickyHeader: true,
    getRowId: (row) => row?.id?.toString(),
    initialState: {
      pagination: { pageIndex: 0, pageSize },
    },
    localization: MRT_Localization_FR,
    manualPagination: true,
    muiPaginationProps: {
      rowsPerPageOptions: [pageSize],
      variant: 'outlined',
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (!isPendingDelete) onClickRow(row.original.id)
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    muiTableContainerProps: {
      sx: {
        height: 'inherit',
        minHeight: '500px',
        width: 'inherit',
      },
    },
    muiTablePaperProps: {
      sx: {
        height: 'inherit',
        width: 'inherit',
      },
    },
    onPaginationChange: setPagination,
    paginationDisplayMode: 'pages',
    positionActionsColumn: 'last',
    positionToolbarAlertBanner: 'top',
    renderRowActions,
    renderToolbarInternalActions,
    rowCount,
    state: {
      isLoading: loading || isPendingDelete,
      pagination,
    },
  })

  return (
    <>
      <MaterialReactTable table={table} />
      <ConfirmDrawer
        bodyPrimaryText="Etes-vous sur de vouloir supprimer ?"
        isOpenDrawer={confirmDrawerState.open}
        titleText="Suppression"
        onSubmit={confirmDrawerState.onSubmit}
        onSubmitColor="error"
        onSubmitText="Supprimer"
        handleCloseOpenDrawer={() => resetStateConfirmDrawer()}
      />
    </>
  )
}

export default TableList
