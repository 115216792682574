import { Button, Stack, Typography } from '@mui/material'
import { KeyRoundIcon } from 'lucide-react'
import { useSnackbar } from 'notistack'
import React from 'react'
import passwordService from 'services/password.service'
import { UserType } from 'types/user'

import withDrawer, {
  TYPE_CONFIRM_DRAWER_SEND_RESET_PASSWORD,
} from '../../../hoc-drawer'

const SecurityTabUsersAdminProfile = ({
  data,
  resetStateConfirmDrawer,
  setConfirmDrawerState,
}: {
  data: UserType
  resetStateConfirmDrawer: any
  setConfirmDrawerState: any
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const handleSendResetPassord = async () => {
    try {
      await passwordService.forgotPassword(data.email)
      setConfirmDrawerState({
        email: data?.email,
        onSubmit: () => {
          resetStateConfirmDrawer()
        },
        open: true,
        type: TYPE_CONFIRM_DRAWER_SEND_RESET_PASSWORD,
      })
    } catch (err: any) {
      enqueueSnackbar(err?.message, {
        variant: 'error',
      })
    }
  }
  return (
    <Stack p={6} spacing={2}>
      <Typography color="primary" fontWeight={700} variant="subtitle2">
        Mot de passe
      </Typography>
      <Button
        startIcon={<KeyRoundIcon />}
        sx={{ width: '300px' }}
        variant="outlined"
        onClick={handleSendResetPassord}
      >
        Changer mon mot de passe
      </Button>
    </Stack>
  )
}

export default withDrawer(SecurityTabUsersAdminProfile)
