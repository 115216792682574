import {
  Box,
  Button,
  type ButtonOwnProps,
  Stack,
  Typography,
} from '@mui/material'
import DrawerStandard from 'components-ui/Drawer'
import React, { ReactNode } from 'react'
import neutral from 'themes/colors/neutral'

const ConfirmDrawer = ({
  Image,
  bodyPrimaryText,
  bodySecondaryColor,
  bodySecondaryText,
  handleCloseOpenDrawer,
  isOpenDrawer,
  modeTwoButtons,
  onSubmit,
  onSubmitColor,
  onSubmitText,
  titleText,
}: {
  Image?: ReactNode
  bodyPrimaryText?: ReactNode | string
  bodySecondaryColor?: string
  bodySecondaryText?: string | undefined
  handleCloseOpenDrawer: any
  isOpenDrawer: boolean
  modeTwoButtons?: boolean
  onSubmit: any
  onSubmitColor?: ButtonOwnProps['color']
  onSubmitText: string
  titleText: string
}) => {
  return (
    <DrawerStandard
      isOpenDrawer={isOpenDrawer}
      titleText={titleText}
      handleCloseOpenDrawer={handleCloseOpenDrawer}
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Stack spacing={2} sx={{ m: 2 }}>
          {bodyPrimaryText}
          <Typography
            color={onSubmitColor || bodySecondaryColor || 'primary'}
            fontWeight={700}
          >
            {bodySecondaryText}
          </Typography>
        </Stack>
        <Box height={120} m="auto" mb={2} mt={2} width={215}>
          {Image}
        </Box>
        {!modeTwoButtons ? (
          <Button
            color={onSubmitColor || 'primary'}
            sx={{ borderRadius: 0, height: '50px', marginTop: 'auto' }}
            variant="contained"
            onClick={onSubmit}
          >
            {onSubmitText}
          </Button>
        ) : (
          <Stack
            alignItems="center"
            direction="row"
            display="flex"
            justifyContent="center"
            spacing={2}
            sx={{
              backgroundColor: neutral[100],
              height: '50px',
              marginTop: 'auto',
              width: '100%',
            }}
          >
            <Button
              sx={{ height: '35px' }}
              variant="text"
              onClick={handleCloseOpenDrawer}
            >
              Annuler
            </Button>
            <Button
              color={onSubmitColor || 'primary'}
              sx={{ height: '35px' }}
              variant="contained"
              onClick={onSubmit}
            >
              {onSubmitText}
            </Button>
          </Stack>
        )}
      </Box>
    </DrawerStandard>
  )
}

export default ConfirmDrawer
