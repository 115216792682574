import { Divider, Grid, Stack, Tab, Tabs } from '@mui/material'
import React from 'react'
import { UserType } from 'types/user'

import ProjectTabUsersAdminProfile from './Project'
import SecurityTabUsersAdminProfile from './Security'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, index, value } = props

  return <>{value === index && children}</>
}

const TabsUsersAdminProfile = ({ data }: { data?: UserType }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Stack sx={{ display: 'flex', flexGrow: 1 }}>
      <Grid container direction="column">
        <Grid item>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              aria-controls="simple-tabpanel-0"
              iconPosition="start"
              id="simple-tab-0"
              label="SECURITÉ"
            />
            <Tab
              disabled
              aria-controls="simple-tabpanel-1"
              iconPosition="start"
              id="simple-tab-1"
              label="PROJET"
            />
          </Tabs>
        </Grid>
      </Grid>
      <Divider />
      <CustomTabPanel index={0} value={value}>
        <SecurityTabUsersAdminProfile data={data} />
      </CustomTabPanel>
      <CustomTabPanel index={1} value={value}>
        <ProjectTabUsersAdminProfile />
      </CustomTabPanel>
    </Stack>
  )
}

export default TabsUsersAdminProfile
