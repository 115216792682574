import type { MapboxStyle } from 'react-map-gl'

import layersBaseMap from 'components/Map/layersBaseMap'
import sourcesBaseMap from 'components/Map/sourcesBaseMap'
import { getBaseURLTilesServeur } from 'constants/config'
import primary from 'themes/colors/primary'

const baseURLTilesServeur = getBaseURLTilesServeur(window.location.origin)

const mapStyle = {
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  layers: [
    ...layersBaseMap,
    {
      id: 'dac',
      metadata: 'dacCode',
      paint: {
        'fill-color': '#ffffff',
        'fill-opacity': 0.6,
        'fill-outline-color': primary[700],
      },
      source: 'dac',
      'source-layer': 'dac',
      type: 'fill',
    },
    {
      id: 'cpts',
      metadata: 'cptsCode',
      paint: {
        'fill-color': '#ffffff',
        'fill-opacity': 0.6,
        'fill-outline-color': primary[700],
      },
      source: 'cpts',
      'source-layer': 'cpts',
      type: 'fill',
    },
    {
      id: 'tvs',
      metadata: 'tvsCode',
      paint: {
        'fill-color': '#ffffff',
        'fill-opacity': 0.6,
        'fill-outline-color': primary[700],
      },
      source: 'tvs',
      'source-layer': 'tvs',
      type: 'fill',
    },
    {
      id: 'epci',
      metadata: 'epciCode',
      paint: {
        'fill-color': '#ffffff',
        'fill-opacity': 0.6,
        'fill-outline-color': primary[700],
      },
      source: 'epci',
      'source-layer': 'epci',
      type: 'fill',
    },
    {
      id: 'regions',
      metadata: 'regionCode',
      paint: {
        'fill-color': '#ffffff',
        'fill-opacity': 0.6,
        'fill-outline-color': primary[700],
      },
      source: 'regions',
      'source-layer': 'regions',
      type: 'fill',
    },
    {
      id: 'counties',
      metadata: 'countyCode',
      paint: {
        'fill-color': '#ffffff',
        'fill-opacity': 0.6,
        'fill-outline-color': primary[200],
      },
      source: 'counties',
      'source-layer': 'counties',
      type: 'fill',
    },
    {
      id: 'cities',
      metadata: 'cityCode',
      paint: {
        'fill-color': '#ffffff',
        'fill-opacity': 0.6,
        'fill-outline-color': primary[200],
      },
      source: 'cities',
      'source-layer': 'cities',
      type: 'fill',
    },
    {
      filter: ['in', 'COUNTY', ''],
      id: 'dac-selected',
      paint: {
        'fill-color': primary[50],
        'fill-opacity': 0.7,
        'fill-outline-color': primary[700],
      },
      source: 'dac',
      'source-layer': 'dac',
      type: 'fill',
    },
    {
      filter: ['in', 'COUNTY', ''],
      id: 'cpts-selected',
      paint: {
        'fill-color': primary[50],
        'fill-opacity': 0.7,
        'fill-outline-color': primary[700],
      },
      source: 'cpts',
      'source-layer': 'cpts',
      type: 'fill',
    },
    {
      filter: ['in', 'COUNTY', ''],
      id: 'epci-selected',
      paint: {
        'fill-color': primary[50],
        'fill-opacity': 0.7,
        'fill-outline-color': primary[700],
      },
      source: 'epci',
      'source-layer': 'epci',
      type: 'fill',
    },
    {
      filter: ['in', 'COUNTY', ''],
      id: 'tvs-selected',
      paint: {
        'fill-color': primary[50],
        'fill-opacity': 0.7,
        'fill-outline-color': primary[700],
      },
      source: 'tvs',
      'source-layer': 'tvs',
      type: 'fill',
    },
    {
      filter: ['in', 'COUNTY', ''],
      id: 'regions-selected',
      paint: {
        'fill-color': primary[50],
        'fill-opacity': 0.7,
        'fill-outline-color': primary[700],
      },
      source: 'regions',
      'source-layer': 'regions',
      type: 'fill',
    },
    {
      filter: ['in', 'COUNTY', ''],
      id: 'counties-selected',
      paint: {
        'fill-color': primary[50],
        'fill-opacity': 0.7,
        'fill-outline-color': primary[700],
      },
      source: 'counties',
      'source-layer': 'counties',
      type: 'fill',
    },
    {
      filter: ['in', 'COUNTY', ''],
      id: 'cities-selected',
      paint: {
        'fill-color': primary[50],
        'fill-opacity': 0.7,
        'fill-outline-color': primary[700],
      },
      source: 'cities',
      'source-layer': 'cities',
      type: 'fill',
    },

    {
      filter: ['in', 'COUNTY', ''],
      id: 'apl',
      paint: {
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'apl_1'],
          0,
          '#FFFFFF',
          1,
          '#eae3ec',
          2,
          '#d1c2d6',
          3,
          '#bda7c4',
          4,
          '#a98db2',
          5,
          '#9977a4',
          6,
          '#896195',
          7,
          '#794b87',
          8,
          '#6b387a',
          9,
          '#5c256d',
          10,
          '#4c0f5f',
          11,
          '#410155',
        ],
        'fill-opacity': 0.6,
      },
      source: 'apl',
      'source-layer': 'apl',
      type: 'fill',
    },
    {
      filter: ['in', 'COUNTY', ''],
      id: 'parties-selected',
      paint: {
        'circle-color': primary[500],
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          2.5,
          14,
          5,
          18,
          8,
        ],
      },
      source: 'parties',
      'source-layer': 'parties',
      type: 'circle',
    },
  ],
  metadata: {
    'mapbox:autocomposite': true,
  },

  name: 'Basic',
  sources: {
    ...sourcesBaseMap,
    apl: {
      maxzoom: 9, //maxzoom of the tile build
      tiles: [`${baseURLTilesServeur}/data/apl/{z}/{x}/{y}.pbf`],
      type: 'vector',
    },
    parties: {
      maxzoom: 11, //maxzoom of the tile build
      tiles: [`${baseURLTilesServeur}/data/parties/{z}/{x}/{y}.pbf`],
      type: 'vector',
    },
  },
  sprite: 'mapbox://sprites/mapbox/basic-v8',
  version: 8,
} as MapboxStyle

export default mapStyle
