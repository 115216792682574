import { IconButton, styled } from '@mui/material'

export const IconButtonOutlined = styled(IconButton)(({ color, theme }) => ({
  '&.MuiButtonBase-root': {
    '&.Mui-disabled': {
      border: 0,
    },
    barderRadius: 25,
    border: '2px solid',
    color: color || 'inherit',
    margin: theme.spacing(0.5),
    padding: 16,
  },
}))
