import type { Questionnaire } from 'types/questionnaire'

import { Typography } from '@mui/material'
import Header from 'components/Header'
import React from 'react'
import { Party } from 'types/party'

const HeaderQuestionnaire = ({
  party,
  questionnaire,
}: {
  party: Party
  questionnaire: Questionnaire
}) => {
  const rowRight = (
    <Typography fontWeight={700} variant="body1">
      {questionnaire?.name}
    </Typography>
  )

  return (
    <Header
      arrowBack
      rowLeftTitle={`Retour à la fiche de ${party?.name}`}
      rowRight={rowRight}
    />
  )
}

export default HeaderQuestionnaire
