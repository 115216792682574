import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Chip } from '@mui/material'
import React from 'react'

const ChipFilter = ({
  getTagProps,
  index,
  onDelete,
  sx,
  title,
}: {
  getTagProps?: any
  index?: number
  onDelete?: any
  sx?: any
  title: React.ReactNode | number | string
}) => (
  <Chip
    label={title}
    size="small"
    {...(getTagProps ? getTagProps({ index }) : {})}
    {...(onDelete ? { onDelete } : {})}
    deleteIcon={<HighlightOffIcon />}
    sx={{ ...sx, p: 2 }}
  />
)

export default ChipFilter
