import { Error } from '@mui/icons-material'
import { SnackbarProvider } from 'notistack'
import React from 'react'
// import { makeStyles } from 'tss-react/mui'

// const useStyles = makeStyles()((theme) => ({
//   variantError: {
//     display: 'flex',
//     backgroundColor: 'white!important',
//     color: `${theme.palette.primary.dark}!important`,
//     fontWeight: 'bold!important',
//     '& svg': {
//       marginRight: '0.4rem',
//       color: `${theme.palette.error.main}!important`,
//     },
//   },
// }))

const Snackbar = ({ children }: { children: React.ReactNode }) => {
  // const { classes } = useStyles()

  return (
    <SnackbarProvider
      preventDuplicate
      autoHideDuration={3000}
      iconVariant={{ error: <Error /> }}
      maxSnack={3}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      // classes={{ variantError: classes.variantError }}
    >
      {children}
    </SnackbarProvider>
  )
}
export default Snackbar
