import { Stack } from '@mui/material'
import SelectMultiple from 'components-ui/SelectMultiple'
import { selectOptions } from 'components-ui/SelectMultiple/type'
import {
  LABEL_CATEGORY_ORGANIZATION,
  LABEL_CATEGORY_PERSON,
  LABEL_EXPERTISE,
  LABEL_JOURNEY,
} from 'constants/label'
import { focusType } from 'pages/Core/Projects/InProgress/type'
import { formatAggsToSelectOptions } from 'pages/Core/Projects/utils'
import { aggsListState } from 'pages/Core/atoms'
import React from 'react'
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { mySearchType } from 'types/recoils/parties-search'

const OtherFilters = ({
  nextMyFiltersState,
}: {
  nextMyFiltersState: RecoilState<mySearchType>
}) => {
  const aggs = useRecoilValue(aggsListState)
  const nextFilters = useRecoilValue(nextMyFiltersState)
  const setMyNextFilters = useSetRecoilState(nextMyFiltersState)

  const listPropsOfSelect = [
    {
      key: 'journey' as focusType,
      label: LABEL_JOURNEY,
      options: formatAggsToSelectOptions(aggs?.journey),
      placeholder: LABEL_JOURNEY,
      totalRecords: aggs?.journey?.length,
    },
    {
      key: 'categoryOrganization' as focusType,
      label: LABEL_CATEGORY_ORGANIZATION,
      options: formatAggsToSelectOptions(aggs?.categoryOrganization),
      placeholder: LABEL_CATEGORY_ORGANIZATION,
      totalRecords: aggs?.categoryOrganization?.length,
    },
    {
      key: 'categoryPerson' as focusType,
      label: LABEL_CATEGORY_PERSON,
      options: formatAggsToSelectOptions(aggs?.categoryPerson),
      placeholder: LABEL_CATEGORY_PERSON,
      totalRecords: aggs?.categoryPerson?.length,
    },
    {
      key: 'expertise' as focusType,
      label: LABEL_EXPERTISE,
      options: formatAggsToSelectOptions(aggs?.expertise),
      placeholder: LABEL_EXPERTISE,
      totalRecords: aggs?.expertise?.length,
    },
  ]

  const onChangeSelect = (
    newSelectedOptions: selectOptions[],
    key: focusType,
  ) => {
    setMyNextFilters((curr) => ({
      ...curr,
      options: {
        ...curr.options,
        [key]: newSelectedOptions,
      },
    }))
  }

  return (
    <Stack p={2} spacing={2}>
      {listPropsOfSelect.map((props) => {
        return (
          <SelectMultiple
            label={props.label}
            options={props.options}
            optionsSelected={nextFilters.options[props.key]}
            placeholder={props.placeholder}
            totalRecords={props.totalRecords}
            onChangeSelect={(newSelectedOptions: selectOptions[]) =>
              onChangeSelect(newSelectedOptions, props.key)
            }
          />
        )
      })}
    </Stack>
  )
}

export default OtherFilters
