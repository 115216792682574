import { Grid, Paper, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as React from 'react'

const BlockInformation = ({
  data,
  title,
}: {
  data: { label: null | string; value: any }[]
  title: string
}) => {
  return (
    <Paper elevation={2} sx={{ height: '100%', p: 2 }}>
      <Grid container direction="column" spacing={2}>
        <Grid item display="flex">
          <Typography fontWeight={600} variant="subtitle1">
            {title}
          </Typography>
        </Grid>

        {data.map(
          ({ label, value }, index) =>
            value && (
              <Grid item display="flex" key={index}>
                <Stack spacing={0.1}>
                  <Typography fontWeight={700} variant="body1">
                    {label}
                  </Typography>
                  <Typography variant="body1">{value}</Typography>
                </Stack>
              </Grid>
            ),
        )}
      </Grid>
    </Paper>
  )
}

export default BlockInformation
