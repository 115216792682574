import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { RoleType } from 'types/role'

export const maxTotalItem = 150

export const useMutationsRole = ({
  successCallback,
}: {
  successCallback?: any
}) => {
  const { isPending: isPendingCreate, mutate: mutateCreate } = useMutation({
    mutationFn: ({ data }: { data: Partial<RoleType> }) =>
      axios(`/roles`, { data, method: 'POST' }),
    mutationKey: ['roles-create'],
    onError: (error: any, { data }) => {
      let errorMessage
      if (error?.statusCode === 409) {
        errorMessage = 'Un role existe déjà avec ce nom ou ces permissions'
      } else {
        errorMessage = `Erreur lors de la création du rôle ${data?.name}`
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
      return error
    },
    onSuccess: (success, { data }) => {
      const successMessage = `Rôle ${data.name} à été créé`
      enqueueSnackbar(successMessage, {
        variant: 'success',
      })
      if (successCallback) successCallback()
      return success
    },
  })

  const { isPending: isPendingUpdate, mutate: mutateUpdate } = useMutation({
    mutationFn: ({ data, id }: { data: Partial<RoleType>; id: number }) =>
      axios(`/roles/${id}/permissions`, { data, method: 'PATCH' }),
    mutationKey: ['roles-patch'],
    onError: (error, { id }) => {
      const errorMessage = `Erreur lors de la mise a jour du rôle ${id}`
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    },
    onSuccess: (data, { id }) => {
      const successMessage = `Rôle n° ${id} mis à jour`
      enqueueSnackbar(successMessage, {
        variant: 'success',
      })
      if (successCallback) successCallback()
    },
  })

  const { isPending: isPendingDelete, mutate: mutateDelete } = useMutation({
    mutationFn: async ({ id }: { id?: number }) =>
      axios(`/roles/${id}`, { method: 'DELETE' }),
    mutationKey: ['roles-delete'],
    onError: (error, { id }) => {
      const errorMessage = `Erreur lors de la suppression du rôle n° ${id}`
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    },
    onSuccess: (data, { id }) => {
      const successMessage = `Suppression : rôle n° ${id} réussi`
      enqueueSnackbar(successMessage, {
        variant: 'success',
      })
      if (successCallback) successCallback()
    },
  })

  return {
    isPendingCreate,
    isPendingDelete,
    isPendingUpdate,
    mutateCreate,
    mutateDelete,
    mutateUpdate,
  }
}
