import { isEmpty, map, reduce } from 'lodash'
import { myDiagnosticOptionsType } from 'types/recoils/diagnostics'
import {
  mySearchOptionsType,
  mySearchPartyType,
} from 'types/recoils/parties-search'
import { myProjectsOptionsType } from 'types/recoils/projects'

export const getIfAreaSelected = (
  myOptions:
    | myDiagnosticOptionsType
    | myProjectsOptionsType
    | mySearchOptionsType,
) =>
  !isEmpty(myOptions.dacCode) ||
  !isEmpty(myOptions.cptsCode) ||
  !isEmpty(myOptions.regionCode) ||
  !isEmpty(myOptions.countyCode) ||
  !isEmpty(myOptions.tvsCode) ||
  !isEmpty(myOptions.epciCode) ||
  !isEmpty(myOptions.cityCode)

export const formattedFilterParams = (
  myOptions:
    | Partial<myProjectsOptionsType>
    | myDiagnosticOptionsType
    | mySearchOptionsType
    | mySearchPartyType,
) =>
  reduce(
    myOptions || {},
    (res: any, curr, index) => {
      let key = index
      let value = map(curr, (filter: any) => filter.value)
      // On veut filtrer par un champs category sur la map regroupant categoryOrganization et categoryPerson
      if (['categoryOrganization', 'categoryPerson'].includes(index)) {
        key = 'category'
        const oldValues = res?.[key] ? res[key] : []
        value = [...oldValues, ...value]
      }
      // On veut filtrer par un champs party sur la map regroupant person et organization
      if (['organization', 'person'].includes(index)) {
        key = 'party'
        const oldValues = res?.[key] ? res[key] : []
        value = [...oldValues, ...value]
      }

      return { ...res, [key]: value }
    },
    {},
  )
