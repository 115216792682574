import neutral from 'themes/colors/neutral'

export const optionsBarVertical = (formattedValue: any) => ({
  // animation: false,
  plugins: {
    datalabels: {
      align: 'top' as const,
      anchor: 'end' as const,
      // and if you need to format how the value is displayed...
      formatter: function (value: any) {
        return value ? `${formattedValue(value)}` : null
      },
      labels: {
        title: {
          font: {
            weight: 700,
          },
        },
        value: {
          color: 'black',
        },
      },
    },
    legend: {
      labels: {
        borderRadius: 4,
        color: neutral[800],
        font: {
          family: 'Nunito',
          size: 12,
        },
        useBorderRadius: true,
      },
      position: 'top' as const,
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      bodyColor: '#000000',
      bodyFont: { family: 'Nunito', size: 12 },
      borderColor: '#000000',
      borderWidth: 0.5,
      // bodyFontSize: '50',
      callbacks: {
        label: function (context: any) {
          const label = context.dataset.label || ''
          const value = context.raw || 0
          return value ? `${label} : ${formattedValue(value)}` : ''
        },
        title: function () {
          return ''
        },
      },
      padding: 12,
    },
  },
  // bezierCurve: false,
  scales: {
    x: {
      display: false,
      offset: true,
    },
    y: {
      display: false,
      offset: true,
    },
  },
  skipNull: true,
})

export const optionsBarHorizontal = (formattedValue: any) => ({
  // bezierCurve: false,
  indexAxis: 'y' as const,
  // animation: false,
  plugins: {
    datalabels: {
      align: 'end' as const,
      anchor: 'end' as const,
      // and if you need to format how the value is displayed...
      formatter: function (value: any) {
        return value ? formattedValue(value) : null
      },
      labels: {
        title: {
          font: {
            weight: 700,
          },
        },
        value: {
          color: 'black',
        },
      },
    },
    legend: {
      labels: {
        borderRadius: 4,
        color: neutral[800],
        font: {
          family: 'Nunito',
          size: 12,
        },
        useBorderRadius: true,
      },
      position: 'top' as const,
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      bodyColor: '#000000',
      bodyFont: { family: 'Nunito', size: 12 },
      borderColor: '#000000',
      borderWidth: 0.5,
      // bodyFontSize: '50',
      callbacks: {
        label: function (context: any) {
          const label = context.dataset.label || ''
          const value = context.raw || ''
          return value ? `${label} : ${formattedValue(value)}` : ''
        },
        title: function () {
          return ''
        },
      },
      padding: 12,
    },
  },
  scales: {
    x: {
      display: false,
      offset: true,
    },
    y: {
      display: false,
      offset: true,
    },
  },
})
