import { Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import CreateDrawerProject from 'components/CreateDrawerProject'
import { heightHeader } from 'constants/ui'
import { myProjectState } from 'pages/Core/Projects/atoms'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import permissionsService, {
  ALLOWED_ACTION_CREATE_PROJECT,
} from 'services/permissions.service'

import { myDiagnosticExtraState, myDiagnosticState } from '../atoms'
import HeaderTypeDiagnostic from './TypeDiagnostic'

const HeaderDiagnostics = () => {
  const navigate = useNavigate()
  const setMyProject = useSetRecoilState(myProjectState)
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false)
  const myDiagnostic = useRecoilValue(myDiagnosticState)
  const myDiagnosticExtra = useRecoilValue(myDiagnosticExtraState)

  const allowedCreateProject = permissionsService.getPermission(
    ALLOWED_ACTION_CREATE_PROJECT,
  )

  const nbPartyToProject =
    myDiagnostic.party.organization.length + myDiagnostic.party.person.length

  const openDrawer = (open: boolean) => setIsOpenDrawer(open)

  const turnIntoProject = ({ name }: { name: string }) => {
    setMyProject({ ...myDiagnostic, ...myDiagnosticExtra, name })
    navigate('/projects/create')
    return
  }

  return (
    <>
      <Paper
        sx={{
          borderRadius: 0,
          position: 'relative',
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Grid
          container
          alignItems="center"
          height={`${heightHeader}px`}
          pl={2}
          pr={2}
        >
          <Grid item flexGrow={1}>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography color="primary" variant="h4">
                Diagnostic
              </Typography>
              <HeaderTypeDiagnostic />
            </Stack>
          </Grid>
          <Divider
            flexItem
            orientation="vertical"
            sx={{ marginRight: '16px' }}
          />
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                disabled={nbPartyToProject === 0 || !allowedCreateProject}
                variant="outlined"
                onClick={() => openDrawer(true)}
              >
                Convertir en projet ({nbPartyToProject || ''})
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      <Divider flexItem />
      {allowedCreateProject && (
        <CreateDrawerProject
          isOpenDrawer={isOpenDrawer}
          titleText="Convertir en projet"
          onSubmit={turnIntoProject}
          onSubmitText="Commencer"
          handleCloseOpenDrawer={() => openDrawer(false)}
        />
      )}
    </>
  )
}

export default HeaderDiagnostics
