import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { PermissionType } from 'types/permission'
import { z } from 'zod'

import { useMutationsRole } from '../mutations'
import { useQueriesPermission, useQueriesRoleSelected } from '../queries'
import HeaderOne from './Header'
import RolesAdminCreateEditTable from './Table'

export const validationSchema = z.object({
  name: z.string({
    required_error: "L'information générale est obligatoire",
  }),
  permissions: z.array(z.number()),
})

export type ValidationSchema = z.infer<typeof validationSchema>

const RolesAdminCreateEdit = () => {
  const navigate = useNavigate()
  const { roleId } = useParams()
  const isModeEdit = !!roleId

  const { mutateUpdate } = useMutationsRole({
    successCallback: () => {
      navigate('/admin/roles')
    },
  })
  const { mutateCreate } = useMutationsRole({
    successCallback: () => {
      navigate('/admin/roles')
    },
  })

  const { dataPermissionsByRoleId } = useQueriesPermission({
    roleId: roleId ? +roleId : undefined,
  })

  const { dataRoleSelected: data, isLoadingRoleSelected: isLoading } =
    useQueriesRoleSelected({
      roleId: roleId ? +roleId : undefined,
    })

  const { control, formState, getValues, handleSubmit, setValue } =
    useForm<ValidationSchema>({
      criteriaMode: 'all',
      mode: 'all',
      resolver: zodResolver(validationSchema),
    })

  useEffect(() => {
    setValue('name', data?.name, { shouldValidate: true })
  }, [data])

  useEffect(() => {
    setValue(
      'permissions',
      dataPermissionsByRoleId?.data?.map((p: PermissionType) => p.id),
      { shouldValidate: true },
    )
  }, [dataPermissionsByRoleId])

  const onSubmitFetch = async (data: ValidationSchema) => {
    const dataBulk = {
      ...data,
      permissions: data.permissions.map((id: number) => ({ id })),
    }
    if (isModeEdit) {
      mutateUpdate({ data: dataBulk, id: +roleId })
    } else {
      mutateCreate({ data: dataBulk })
    }
  }

  const onInvalid = (errors: any) => console.error(errors)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <HeaderOne
            isDirty={formState.isDirty}
            isEdit={!!data}
            onSubmitFetch={handleSubmit(onSubmitFetch, onInvalid)}
          />
        </Grid>
      </Grid>
      {isLoading && (
        <Box
          alignItems="center"
          display="flex"
          height={155}
          justifyContent="center"
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <Stack display="flex" sx={{ flexGrow: 1, pt: 4 }}>
        <Stack>
          <Typography
            color="primary"
            fontWeight={700}
            ml={2}
            variant="subtitle2"
          >
            Information générale
          </Typography>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                error={!!formState.errors.name}
                focused={!!field.value || formState.touchedFields.name}
                id="standard-name-role-input"
                label="Nom"
                sx={{ m: 2 }}
                variant="filled"
                helperText={
                  <ErrorMessage errors={formState.errors} name="name" />
                }
                {...field}
                inputRef={field.ref}
              />
            )}
          />
        </Stack>
        <Stack m={2} spacing={2}>
          <Typography
            color="primary"
            fontWeight={700}
            ml={2}
            variant="subtitle2"
          >
            Droits
          </Typography>
          <RolesAdminCreateEditTable
            permissionsSelected={getValues('permissions')}
            roleId={roleId}
            setValue={setValue}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default RolesAdminCreateEdit
