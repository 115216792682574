import { Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'
import primary from 'themes/colors/primary'

import { drawerWidth } from '.'

export const DrawerStyled = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    backgroundColor: primary[500],
    boxSizing: 'border-box',
    color: 'white',
    height: '100vh',
    width: drawerWidth,
  },
}))
