import { Box, Stack, Switch, lighten } from '@mui/material'
import { xor } from 'lodash'
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import React from 'react'
import { UseFormSetValue } from 'react-hook-form'

import { ValidationSchema } from '..'
import { useQueriesPermission } from '../../queries'

const UsersAdminCreateEditTable = ({
  permissionsSelected,
  roleId,
  setValue,
}: {
  permissionsSelected: ValidationSchema['permissions']
  roleId: string | undefined
  setValue: UseFormSetValue<ValidationSchema>
}) => {
  const { data } = useQueriesPermission({
    roleId: roleId ? +roleId : undefined,
  })

  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'moduleName',
      filterVariant: 'autocomplete',
      header: 'Module',
      size: 150,
    },
    {
      accessorKey: 'name',
      header: 'Droits',
      size: 600,
    },
    {
      Cell: ({ cell }) => {
        const value = cell.getValue<number>()
        const hasPermission = permissionsSelected?.includes(value)
        const newPermissions = xor(permissionsSelected, [value])
        return (
          <Switch
            checked={hasPermission}
            onChange={() => {
              setValue('permissions', newPermissions, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              })
            }}
          />
        )
      },
      accessorKey: 'id',
      header: 'Permission',
      size: 50,
    },
  ]

  const table = useMaterialReactTable({
    columns,
    data: data?.data || [],
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnResizing: false,
    enableDensityToggle: false,
    enableFacetedValues: true,
    enableFullScreenToggle: false,
    enableGrouping: true,
    enableHiding: false,
    enablePagination: false,
    groupedColumnMode: 'remove',
    initialState: {
      expanded: true, //expand all groups by default
      grouping: ['moduleName'], //an array of columns to group by by default (can be multiple)
      showGlobalFilter: true,
    },

    muiTableBodyCellProps: ({ row }) => ({
      sx: (theme) => ({
        color: row.depth === 0 ? theme.palette.primary.main : undefined,
        fontWeight: row.depth === 0 ? 700 : undefined,
      }),
    }),
    positionGlobalFilter: 'left', //show the global filter on the left side of the top toolbar
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            justifyContent: 'space-between',
            p: '8px',
          })}
        >
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table} />
          </Box>
        </Box>
      )
    },
  })

  return (
    <Stack sx={{ display: 'flex', flexGrow: 1 }}>
      <MaterialReactTable table={table} />
    </Stack>
  )
}

export default UsersAdminCreateEditTable
