import { Box, CircularProgress, Divider, Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RoleType } from 'types/role'

import { useQueriesRolesCountOwnUsers } from '../queries'
import AsideRolesAdminList from './Aside'
import Header from './Header'
import TableRolesAdminList from './Table'

export type CountRole = { _count: { permissions: number; users: number } }

const RolesAdminList = () => {
  const {
    dataRolesCountOwnUsers: dataRoles,
    isLoadingRolesCountOwnUsers,
    refetchRolesCountOwnUsers,
  } = useQueriesRolesCountOwnUsers()

  const [roleSelected, setRoleSelected] = useState<
    (RoleType & CountRole) | undefined
  >(undefined)

  useEffect(() => {
    if (dataRoles?.data?.length > 0) {
      setRoleSelected(dataRoles?.data?.[0])
    }
  }, [dataRoles])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header />
        </Grid>
      </Grid>
      {isLoadingRolesCountOwnUsers ? (
        <Box
          alignItems="center"
          display="flex"
          height={155}
          justifyContent="center"
        >
          <CircularProgress size={80} />
        </Box>
      ) : (
        <Stack direction="row" display="flex" flexGrow={1}>
          <AsideRolesAdminList
            refetchRoles={refetchRolesCountOwnUsers}
            roles={dataRoles?.data}
            roleSelected={roleSelected}
            setRoleSelected={setRoleSelected}
          />
          <Divider orientation="vertical" />
          <TableRolesAdminList roleSelected={roleSelected} />
        </Stack>
      )}
    </>
  )
}

export default RolesAdminList
