import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

export const maxTotalItem = 150

export const useQueriesPermission = ({
  roleId,
}: {
  roleId?: number | undefined
}) => {
  const { data: data, isLoading: isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await axios.get(`permissions`)
      return data
    },
    queryKey: [`admin-permissions-list`],
  })
  const {
    data: dataPermissionsByRoleId,
    isLoading: isLoadingPermissionsByRoleId,
  } = useQuery({
    queryFn: async ({ queryKey: [, roleId] }) => {
      if (roleId) {
        const { data } = await axios.get(`permissions/roles/${roleId}`)
        return data
      }
    },
    queryKey: [`admin-permissions-list-by-role-id`, roleId],
  })

  return {
    data,
    dataPermissionsByRoleId,
    isLoading,
    isLoadingPermissionsByRoleId,
  }
}

export const useQueriesRoleSelected = ({
  enabled,
  roleId,
}: {
  enabled?: boolean
  roleId?: number | string | undefined
}) => {
  const { data: dataRoleSelected, isLoading: isLoadingRoleSelected } = useQuery(
    {
      enabled,
      queryFn: async ({ queryKey: [, roleId] }) => {
        if (roleId) {
          const { data } = await axios.get(
            `roles/${roleId}?${new URLSearchParams({
              crudQuery: JSON.stringify({
                joins: ['permissions'],
              }),
            })}`,
          )
          return data
        }
      },
      queryKey: [`admin-role-list-by-role-id`, roleId],
    },
  )

  return {
    dataRoleSelected,
    isLoadingRoleSelected,
  }
}

export const useQueriesRolesCountOwnUsers = () => {
  const {
    data: dataRolesCountOwnUsers,
    isLoading: isLoadingRolesCountOwnUsers,
    refetch: refetchRolesCountOwnUsers,
  } = useQuery({
    queryFn: async () => axios.get(`roles/count-own-users`),
    queryKey: [`admin-role-list-count-own-users`],
  })

  return {
    dataRolesCountOwnUsers,
    isLoadingRolesCountOwnUsers,
    refetchRolesCountOwnUsers,
  }
}

export const useQueriesRoles = ({ enabled }: { enabled?: boolean }) => {
  const { data: data, isLoading: isLoading } = useQuery({
    enabled,
    queryFn: async () => {
      const { data } = await axios.get(`roles`)
      return data
    },
    queryKey: [`admin-role-list`],
  })

  return {
    data,
    isLoading,
  }
}

export const useQueriesGetRoleMe = ({ enabled }: { enabled?: boolean }) => {
  const { data: dataRoleMe, isLoading: isLoadingRoleMe } = useQuery({
    enabled,
    queryFn: async () => {
      const { data } = await axios.get(
        `roles/my?${new URLSearchParams({
          crudQuery: JSON.stringify({
            joins: ['permissions'],
          }),
        })}`,
      )
      return data
    },
    queryKey: [`role-me-with-permissions`],
  })

  return {
    dataRoleMe,
    isLoadingRoleMe,
  }
}
