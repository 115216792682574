import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { LABEL_CATEGORY_PERSON } from 'constants/label'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useAxios } from 'services/axios.service'
import neutral from 'themes/colors/neutral'
import { OrganizationPerson } from 'types/organisation-person'
import { Party } from 'types/party'

const rowsPerPage = 10

const buildQuery = (page: number, partyId: string) => ({
  url: `/organizations-persons?${new URLSearchParams({
    crudQuery: JSON.stringify({
      joins: ['person', 'person.party'],
      page,
      pageSize: rowsPerPage,
      where: {
        organizationPartyId: partyId,
      },
    }),
  })}`,
})

const PracticeLocations = ({ party }: { party: Party }) => {
  const [pageNumber, setPageNumber] = React.useState(0)

  const { refresh, response } = useAxios(buildQuery(pageNumber + 1, party.id))
  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage)
    refresh(buildQuery(newPage + 1, party.id))
  }

  return (
    <Paper elevation={2} sx={{ height: '100%', p: 2 }}>
      <Grid container direction="column" spacing={2}>
        <Grid item display="flex" mb={2}>
          <Typography fontWeight={600} variant="subtitle1">
            Professionnel de santé affiliés ({response?.totalRecords || 0})
          </Typography>
        </Grid>
        <Grid item>
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Nom - RPPS</TableCell>
                  <TableCell>{LABEL_CATEGORY_PERSON}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response?.data &&
                  response?.data?.map(
                    (personOrganization: OrganizationPerson) => (
                      <TableRow key={personOrganization?.person?.party.id}>
                        <TableCell component="th" scope="row" width={'50%'}>
                          <Stack spacing={0.5}>
                            <Link
                              to={`/party/${personOrganization.person?.party.id}`}
                            >
                              <Typography>
                                {personOrganization.person?.party.name}
                              </Typography>
                            </Link>
                            <Typography color={neutral[700]}>
                              {personOrganization.person?.rpps}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>{personOrganization.profession}</TableCell>
                      </TableRow>
                    ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={response?.totalRecords || 0}
            page={pageNumber}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[-1]}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ count, from, to }) =>
              `${from}–${to} sur ${count !== -1 ? count : `plus que ${to}`}`
            }
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PracticeLocations
