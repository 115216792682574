import { Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import PageInfo from 'components-ui/PageInfo'
import { difference, findIndex, map, reduce } from 'lodash'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAxios } from 'services/axios.service'
import { Question } from 'types/questionnaire'
import { SurveyAnswer } from 'types/survey'

import HeaderQuestionnaire from './Header'
import QuestionsList from './QuestionsList'
import Survey from './Survey'

const QuestionnairePage = () => {
  const { idQuestionnaire, partyId } = useParams()
  const navigate = useNavigate()
  const [currentIndexQuestion, setCurrentIndexQuestion] = React.useState({
    index: 0,
    nextIndex: 1,
    previousIndex: -1,
  })
  const [arrayQuestions, setArrayQuestions] = React.useState<[] | Question[]>(
    [],
  )
  const [currentQuestion, setCurrentQuestion] = React.useState<Question | null>(
    null,
  )
  const [currentAnswer, setCurrentAnswer] = React.useState<SurveyAnswer | null>(
    null,
  )

  const { response: party } = useAxios({
    url: `/parties/${partyId}`,
  })

  const { loading, response: questionsByCategory } = useAxios({
    url: `/questionnaires/${idQuestionnaire}/questions-by-category`,
  })
  const { loading: loadingQuestionnaire, response: questionnaire } = useAxios({
    url: `/questionnaires/${idQuestionnaire}/questions`,
  })

  const {
    data: survey,
    error: errorSurvey,
    isError: isErrorSurvey,
    refetch: refreshSurvey,
  } = useQuery({
    queryFn: async ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryKey: [, { idQuestionnaire, partyId }],
    }) => {
      const { data } = await axios.get(
        `/surveys/questionnaire/${idQuestionnaire}/party/${partyId}/with-answers`,
      )
      return data
    },
    queryKey: [
      `get-questionnaire-id-by-partyId-with-answers`,
      { idQuestionnaire, partyId },
    ],
    retry: 0,
  })

  useEffect(() => {
    if (isErrorSurvey && idQuestionnaire) {
      const error = errorSurvey as any
      if (error?.statusCode === 404) {
        axios
          .post(`/surveys`, {
            partyId: partyId,
            questionnaireId: parseInt(idQuestionnaire, 10),
          })
          .then(() => refreshSurvey())
      }
    }
  }, [isErrorSurvey])

  React.useEffect(() => {
    if (questionnaire) {
      const listQuestions = reduce(
        questionnaire.questionnairesQuestions,
        (acc: any, curr) => [...acc, curr.question],
        [],
      )
      setArrayQuestions(listQuestions)
    }
  }, [questionnaire])

  React.useEffect(() => {
    if (arrayQuestions) {
      setCurrentQuestion(arrayQuestions[0])
      setCurrentAnswer(survey?.answers[arrayQuestions[0]?.id])
    }
  }, [arrayQuestions])

  const handleSelectQuestion = (value: any) => {
    const index = findIndex(
      arrayQuestions || [],
      (o: Question) => o.id === value?.id,
    )

    let nextIndex = arrayQuestions[index + 1] ? index + 1 : -1

    if (nextIndex === -1) {
      // we want search last question with no answer
      const arrayNextIndex = difference(
        map(arrayQuestions, (o: { id: any }) => o.id),
        [
          ...map(survey?.answers, (o: { questionId: any }) => o.questionId),
          value.id,
        ],
      )

      nextIndex = findIndex(
        arrayQuestions || [],
        (o: Question) => o.id === arrayNextIndex[0],
      )
    }
    setCurrentIndexQuestion({
      index,
      nextIndex,
      previousIndex: arrayQuestions[index - 1] ? index - 1 : -1,
    })
    setCurrentQuestion(value)
    setCurrentAnswer(survey?.answers[value?.id])
  }

  const handleAnswerQuestion = async (idAnswer: number) => {
    await axios.put('surveyAnswers', {
      id: currentAnswer?.id,
      questionAnswerId: idAnswer,
      questionId: currentQuestion?.id,
      surveyId: survey?.id,
    })
    await refreshSurvey()
    if (currentIndexQuestion.nextIndex === -1) {
      navigate(`/party/${partyId}`)
    } else {
      handleNextQuestion()
    }
  }
  const handlePreviousQuestion = () => {
    handleSelectQuestion(arrayQuestions[currentIndexQuestion.previousIndex])
  }
  const handleNextQuestion = () => {
    handleSelectQuestion(arrayQuestions[currentIndexQuestion.nextIndex])
  }

  if (loading || loadingQuestionnaire) {
    return <PageInfo loading={true} text="Recherche en cours ..." />
  }
  if (!questionsByCategory) {
    return <PageInfo loading={false} text="Ce questionnaire n'existe pas" />
  }

  return (
    <Grid container spacing={3}>
      <Grid item flexGrow={1}>
        <HeaderQuestionnaire party={party} questionnaire={questionnaire} />
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={3} wrap="nowrap">
          <Grid item sx={{ minWidth: 360, width: 360 }}>
            <QuestionsList
              answers={survey?.answers || []}
              currentQuestion={currentQuestion}
              idQuestionnaire={idQuestionnaire}
              list={questionsByCategory || []}
              partyId={partyId}
              handleSelectQuestion={handleSelectQuestion}
            />
          </Grid>
          <Grid item flexGrow={1} mt={15}>
            <Survey
              currentAnswer={currentAnswer}
              currentQuestion={currentQuestion}
              isFirstQuestion={currentIndexQuestion.previousIndex === -1}
              isLastQuestion={currentIndexQuestion.nextIndex === -1}
              handleAnswerQuestion={handleAnswerQuestion}
              handleNextQuestion={handleNextQuestion}
              handlePreviousQuestion={handlePreviousQuestion}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuestionnairePage
