import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'
import { Box } from '@mui/material'
import { ReactComponent as LogoFullDark } from 'assets/images/logoFullDark.svg'
import SplitScreenLayout from 'components/Layout/SplitScreen'
import InputStartIcon from 'components-ui/InputStartIcon'
import { isEmpty } from 'lodash'
import { AtSign, Eye, EyeOff, KeyRound } from 'lucide-react'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import authService from 'services/auth.service'
import { sleep } from 'utils/sleep'
import { z } from 'zod'

const validationSchema = z.object({
  email: z
    .string()
    .email("L'adresse email est invalide")
    .min(1, "L'adresse email est obligatoire"),
  password: z
    .string()
    .min(8, 'Le mot de passe doit être supérieur à 8 caractère'),
})
type ValidationSchema = z.infer<typeof validationSchema>

const LoginPage = () => {
  const [showPassword, setShowPassword] = React.useState(false)
  const [user, setUser] = React.useState({ firstname: '' })
  const isConnected = isEmpty(user.firstname)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const { control, formState, handleSubmit } = useForm<ValidationSchema>({
    criteriaMode: 'all',
    delayError: 500,
    mode: 'all',
    resolver: zodResolver(validationSchema),
  })

  const onSubmit = async (data: ValidationSchema) => {
    await sleep(1000)
    try {
      const response = await authService.login(data.email, data.password)
      setUser(response)
      await sleep(1700)
      window.location.pathname = '/'
      await sleep(500)
    } catch (err: any) {
      enqueueSnackbar(err?.message || 'Maintenance en cours', {
        variant: 'error',
      })
    }
  }

  const rightElement = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          width: 430,
        }}
      >
        <Box sx={{ margin: 'auto', width: 230 }}>
          <LogoFullDark />
          <Typography
            align="center"
            color="primary"
            sx={{ fontWeight: 700 }}
            variant="body1"
          >
            Lutter contre les déserts médicaux
          </Typography>
        </Box>
        {formState.isSubmitting ? (
          <Box
            alignItems="center"
            display="flex"
            height={155}
            justifyContent="center"
          >
            <CircularProgress
              color={isConnected ? 'inherit' : 'secondary'}
              size={80}
            />
          </Box>
        ) : (
          <Box sx={{ mb: '3rem', mt: '2.5rem', mx: 'auto' }}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <InputStartIcon
                  fullWidth
                  Icon={AtSign}
                  label={'Login'}
                  variant="filled"
                  error={
                    !!formState.errors.email && formState.touchedFields.email
                  }
                  {...field}
                  inputRef={field.ref}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <InputStartIcon
                  fullWidth
                  Icon={KeyRound}
                  label={'Mot de passe'}
                  sxContainer={{ mt: 2 }}
                  type={showPassword ? 'text' : 'password'}
                  variant="filled"
                  error={
                    !!formState.errors.password &&
                    formState.touchedFields.password
                  }
                  {...field}
                  inputRef={field.ref}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          color="primary"
                          edge="end"
                          size="small"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <EyeOff /> : <Eye />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        )}
        {formState.isSubmitting ? (
          <Typography
            align="center"
            color={isConnected ? 'primary' : 'secondary'}
            variant="body1"
            sx={{
              fontWeight: 700,
            }}
          >
            {isConnected
              ? 'Connexion en cours ...'
              : 'Bonjour ' + user?.firstname}
          </Typography>
        ) : (
          <>
            <Button
              fullWidth
              disabled={!formState.isValid || formState.isSubmitting}
              type="submit"
              variant="contained"
            >
              Se connecter
            </Button>
            <Button
              fullWidth
              sx={{ mt: 1 }}
              variant="text"
              onClick={() => {
                navigate('/forgot-password')
              }}
            >
              Mot de passe oublié
            </Button>
          </>
        )}
      </Box>
    </form>
  )

  return (
    <SplitScreenLayout
      description="Login"
      rightElement={rightElement}
      title="MedTrucks"
    ></SplitScreenLayout>
  )
}

export default LoginPage
