import type { TextFieldProps } from '@mui/material/TextField'

import SearchIcon from '@mui/icons-material/SearchRounded'
import { Button } from '@mui/material'
import React from 'react'

import { StyledTextField } from './styles'
const SearchInput = (
  props: TextFieldProps & {
    action?: React.MouseEventHandler<HTMLButtonElement> | undefined
    hiddenEndAdornment?: boolean
  },
) => {
  const { action, hiddenEndAdornment, placeholder, ...propsTextField } = props
  return (
    <StyledTextField
      {...propsTextField}
      id="search-input"
      placeholder={placeholder || 'Je recherche ...'}
      InputProps={{
        endAdornment: !hiddenEndAdornment && (
          <Button variant="contained" onClick={action}>
            Rechercher
          </Button>
        ),
        startAdornment: (
          <SearchIcon
            color="inherit"
            fontSize="small"
            id={props.name}
            name={props.name}
            sx={{ marginRight: '5px' }}
          />
        ),
      }}
    />
  )
}

export default SearchInput
