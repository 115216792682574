import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material'
import ConfirmDrawer from 'components/ConfirmDrawer'
import { CONST_PROJECT_STATUS_PUBLISHED } from 'constants/enum'
import { heightHeader } from 'constants/ui'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { type StatusProjectType } from 'types/enums'

import { myProjectState } from '../../atoms'
import {
  STATE_AREA,
  STATE_FOCUS,
  STATE_OVERVIEW,
  STATE_PARTY,
  STATE_SPECIALITY,
  stateOfInProgress,
  stateOfInProgressInfos,
} from '../constants'
import { getAreaInfos } from '../helper'
import { currentStateType } from '../type'

const HeaderInProgressProject = ({
  currentState,
  setCurrentState,
  submitProject,
}: {
  currentState: currentStateType
  setCurrentState: React.Dispatch<React.SetStateAction<currentStateType>>
  submitProject: any
}) => {
  const myProject = useRecoilValue(myProjectState)
  const resetProject = useResetRecoilState(myProjectState)

  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()
  const [isOpenDrawer, setIsOpenDrawer] = React.useState<boolean>(false)

  const openDrawer = (open: boolean) => setIsOpenDrawer(open)

  const onSubmitProject = async (status: StatusProjectType) => {
    if (status === CONST_PROJECT_STATUS_PUBLISHED) {
      await submitProject(status)
      navigate('/projects')
      enqueueSnackbar('Projet créé avec succès', {
        variant: 'success',
      })
      resetProject()
    }
    return
  }

  const handleDisableNext = () => {
    const { areaInfosLength } = getAreaInfos(myProject)
    if (currentState === STATE_AREA && !areaInfosLength) return true
    const { options, party, speciality } = myProject

    if (currentState === STATE_SPECIALITY && !speciality.value) return true

    if (
      currentState === STATE_FOCUS &&
      !options.expertise.length &&
      !options.journey.length &&
      !options.categoryOrganization.length &&
      !options.categoryPerson.length
    )
      return true
    if (
      currentState === STATE_PARTY &&
      !party.organization.length &&
      !party.person.length
    )
      return true

    return false
  }

  return (
    <>
      <Paper
        sx={{
          borderRadius: 0,
          position: 'relative',
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Grid
          container
          alignItems="center"
          height={`${heightHeader}px`}
          pl={2}
          pr={2}
        >
          <Grid item flexGrow={1}>
            <Typography color="primary" variant="h4">
              {stateOfInProgressInfos[currentState]?.title}
            </Typography>
            <Typography>
              {stateOfInProgressInfos[currentState]?.description}
            </Typography>
          </Grid>
          <Divider
            flexItem
            orientation="vertical"
            sx={{ marginRight: '16px' }}
          />
          <Grid item>
            <Button
              disabled={currentState === STATE_AREA}
              startIcon={<ArrowBackIcon />}
              sx={{ mr: 1 }}
              variant="text"
              onClick={() => {
                const currentIndex = stateOfInProgress.indexOf(currentState)
                setCurrentState(stateOfInProgress[currentIndex - 1])
              }}
            >
              Précédent
            </Button>
            {currentState !== STATE_OVERVIEW && (
              <Button
                disabled={handleDisableNext()}
                startIcon={<ArrowForwardIcon />}
                variant="contained"
                onClick={() => {
                  // onSubmitProject(CONST_DRAFT)
                  const currentIndex = stateOfInProgress.indexOf(currentState)
                  setCurrentState(stateOfInProgress[currentIndex + 1])
                }}
              >
                Suivant
              </Button>
            )}
            {currentState === STATE_OVERVIEW && (
              <Button variant="contained" onClick={() => openDrawer(true)}>
                Valider le projet
              </Button>
            )}
          </Grid>
        </Grid>
        <LinearProgress
          color="secondary"
          value={stateOfInProgressInfos[currentState]?.progressBarStatus}
          variant="determinate"
        />
      </Paper>
      <ConfirmDrawer
        modeTwoButtons
        isOpenDrawer={isOpenDrawer}
        titleText="Nouveau Projet"
        onSubmit={() => onSubmitProject(CONST_PROJECT_STATUS_PUBLISHED)}
        onSubmitText="Valider"
        handleCloseOpenDrawer={() => openDrawer(false)}
        bodyPrimaryText="Une fois le projet créé, il ne sera pas possible de la modifier. Êtes
      vous sur d’avoir bien configurer votre projet ?"
      />
    </>
  )
}

export default HeaderInProgressProject
