import { atom } from 'recoil'
import {
  SuggestionsState,
  myPartyListType,
  mySearchType,
} from 'types/recoils/parties-search'

/*** STATES ***/

export const defaultMySearchState = {
  areaType: 'countryCode',
  options: {
    category: [],
    categoryOrganization: [],
    categoryPerson: [],
    cityCode: [],
    countryCode: [],
    countyCode: [],
    cptsCode: [],
    dacCode: [],
    epciCode: [],
    expertise: [],
    journey: [],
    name: [],
    partyType: [],
    regionCode: [],
    search: [],
    tvsCode: [],
  },
  party: { organization: [], person: [] },
} as mySearchType

export const defaultMyPartyListState = {
  party: [],
} as myPartyListType

export const myPartyListState = atom({
  default: defaultMyPartyListState,
  key: 'myPartyListState',
})

export const mySearchState = atom({
  default: defaultMySearchState,
  key: 'mySearchState',
})

export const nextMySearchState = atom({
  default: defaultMySearchState,
  key: 'nextmySearchState',
})

export const suggestionsState = atom({
  default: {
    categoryOrganization: {},
    categoryPerson: {},
    expertise: {},
    journey: {},
    organization: {},
    person: {},
    searchText: '',
  } as SuggestionsState,
  key: 'suggestionsState',
})
