import { zodResolver } from '@hookform/resolvers/zod'
import { Box, ClickAwayListener } from '@mui/material'
import SearchInput from 'components-ui/SearchInput'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RecoilState, useRecoilValue } from 'recoil'
import { mySearchType } from 'types/recoils/parties-search'
import { z } from 'zod'

import Suggestions from './Suggestions'

export const validationSchema = z.object({
  search: z.string(),
})

export type ValidationSchema = z.infer<typeof validationSchema>

const SearchFilter = ({
  myFiltersState,
  nextMyFiltersState,
  refetchSubmit,
}: {
  myFiltersState: RecoilState<mySearchType>
  nextMyFiltersState: RecoilState<mySearchType>
  refetchSubmit: any
}) => {
  const myFilters = useRecoilValue(myFiltersState)
  const [mySearchText, setMySearchText] = useState('')
  const [openSuggestions, setOpenSuggestions] = React.useState(false)

  const { control } = useForm<ValidationSchema>({
    defaultValues: {
      search: myFilters?.options.search?.[0]?.value,
    },
    resolver: zodResolver(validationSchema),
  })

  const handleChangeSearchText = (event: any) => {
    const { value } = event.currentTarget
    setMySearchText(value)
    setOpenSuggestions(!!value)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenSuggestions(false)}>
      <Box pl={1} pr={1}>
        <Controller
          control={control}
          name="search"
          render={({ field }) => (
            <SearchInput
              fullWidth
              hiddenEndAdornment
              {...field}
              inputRef={field.ref}
              onChange={(e) => {
                field.onChange(e)
                handleChangeSearchText(e)
              }}
              onFocus={() => mySearchText && setOpenSuggestions(true)}
            />
          )}
        />
        <Suggestions
          myFiltersState={myFiltersState}
          mySearchText={mySearchText}
          nextMyFiltersState={nextMyFiltersState}
          open={openSuggestions}
          refetchSubmit={refetchSubmit}
          setOpen={setOpenSuggestions}
        />
      </Box>
    </ClickAwayListener>
  )
}

export default SearchFilter
