import { ToggleButtonGroup, styled } from '@mui/material'
import primary from 'themes/colors/primary'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButton-root': {
      '&.Mui-selected': {
        backgroundColor: primary[500],
      },
      border: 'none',
      borderRadius: 4,
      boxShadow: '0px 0px 25px rgb(0 0 0 / 10%)',
      display: 'flex',
      fontFamily: "'Open Sans'",
      fontSize: 25,
      fontWeight: 700,
      height: 50,
      width: 50,
    },
    '& .MuiToggleButtonGroup-grouped': {
      '&.Mui-disabled': {
        border: 0,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      barderRadius: 4,
      border: 0,
      margin: theme.spacing(2),
    },
    display: 'flex',
    justifyContent: 'center',
  }),
)
