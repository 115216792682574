export default {
  50: '#F8FBFB',
  100: '#EEF4F6',
  200: '#DEE8EE',
  300: '#B8C4CC',
  400: '#87919A',
  500: '#495057',
  600: '#353F4A',
  700: '#242F3E',
  800: '#172032',
  900: '#171B1E',
}
