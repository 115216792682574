import { Stack, Typography } from '@mui/material'
import { CheckCircle2Icon } from 'lucide-react'
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import React from 'react'
import { RoleType } from 'types/role'

import { useQueriesPermission } from '../../queries'

const TableRolesAdminList = ({
  roleSelected,
}: {
  roleSelected: RoleType | undefined
}) => {
  const { data, dataPermissionsByRoleId } = useQueriesPermission({
    roleId: roleSelected?.id,
  })

  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: 'moduleName',
      header: 'Module',
      size: 150,
    },
    {
      accessorKey: 'name',
      header: 'Droits',
      size: 600,
    },
    {
      Cell: ({ cell }) => {
        const hasPermission = dataPermissionsByRoleId?.data?.filter(
          (o: any) => o.id === cell.getValue<number>(),
        )[0]

        return (
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={(theme) => ({
              color: hasPermission
                ? theme.palette.success.main
                : theme.palette.error.main,
            })}
          >
            <CheckCircle2Icon size={15} />
            <Typography fontWeight={700}>
              {hasPermission ? 'Oui' : 'Non'}
            </Typography>
          </Stack>
        )
      },
      accessorKey: 'id',
      header: 'Permission',
      size: 50,
    },
  ]

  //column definitions...
  const table = useMaterialReactTable({
    columns,
    data: data?.data || [],
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnResizing: false,
    enableDensityToggle: false,
    enableFilters: false,
    // enableFacetedValues: true,
    enableFullScreenToggle: false,
    enableGrouping: true,
    enableHiding: false,
    enablePagination: false,
    enableSorting: false,
    enableTopToolbar: false,
    groupedColumnMode: 'remove',
    initialState: {
      expanded: true, //expand all groups by default
      grouping: ['moduleName'], //an array of columns to group by by default (can be multiple)
    },
    muiTableBodyCellProps: ({ row }) => ({
      sx: (theme) => ({
        color: row.depth === 0 ? theme.palette.primary.main : undefined,
        fontWeight: row.depth === 0 ? 700 : undefined,
      }),
    }),
  })

  return (
    <Stack sx={{ display: 'flex', flexGrow: 1 }}>
      <MaterialReactTable table={table} />
    </Stack>
  )
}

export default TableRolesAdminList
