import { Chip, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { map } from 'lodash'
import { labelChipsFilter } from 'pages/Core/Projects/InProgress/constants'
import { formattedFilterParams } from 'pages/Core/helper'
import React from 'react'
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { FieldParam, mySearchType } from 'types/recoils/parties-search'

import { BoxStyled } from './styles'

const Suggestions = ({
  myFiltersState,
  mySearchText,
  nextMyFiltersState,
  open,
  refetchSubmit,
  setOpen,
}: {
  myFiltersState: RecoilState<mySearchType>
  mySearchText: string
  nextMyFiltersState: RecoilState<mySearchType>
  open: boolean
  refetchSubmit: any
  setOpen: any
}) => {
  const setMyFilters = useSetRecoilState(myFiltersState)
  const myFilters = useRecoilValue(myFiltersState)
  const setMyNextFilters = useSetRecoilState(nextMyFiltersState)

  const { data: response } = useQuery({
    enabled: !!mySearchText,
    queryFn: async () => {
      const { data } = await axios.post('/opensearch/suggestions/party', {
        fields: [
          'journey',
          'name',
          'categoryOrganization',
          'categoryPerson',
          'expertise',
        ],
        myFilters: formattedFilterParams(myFilters.options),
        searchText: mySearchText,
        size: 20,
      })
      return data
    },
    queryKey: [
      `opensearch-search-party-suggestion`,
      mySearchText,
      myFilters.options,
    ],
  })

  const onClickChip = (field: FieldParam, value: string) => {
    const newMyFilters = {
      ...myFilters,
      options: {
        ...myFilters.options,
        [field]: [
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...(myFilters.options[field] ? myFilters.options[field] : []),
          { label: value, value },
        ],
      },
    }
    setMyFilters(newMyFilters)
    setMyNextFilters(newMyFilters)
    refetchSubmit()
    setOpen(false)
  }
  // enter

  //empty

  //resultat

  // croix search

  return (
    <BoxStyled sx={{ width: open ? 'calc(100vw - 650px)' : 0 }}>
      <Stack p={4} spacing={4} sx={{ width: 'calc(100vw - 650px)' }}>
        {/* <Typography color="white" fontWeight={700} variant="body1">
          Vous recherchez sur le(s) territoire(s):{' '}
        </Typography> */}
        {map(response?.data, (aggs: any, key: FieldParam) => {
          return (
            aggs?.data?.buckets.length > 0 && (
              <Stack spacing={1}>
                <Typography
                  color="secondary"
                  fontWeight={700}
                  sx={{ textWrap: 'nowrap' }}
                  variant="h6"
                >
                  {labelChipsFilter[key]}
                </Typography>
                <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1}>
                  {map(aggs?.data?.buckets, (bucket) => {
                    return (
                      <Chip
                        color="info"
                        label={bucket.key}
                        sx={{ marginTop: '8px!important' }}
                        onClick={() => onClickChip(key, bucket.key)}
                      />
                    )
                  })}
                </Stack>
              </Stack>
            )
          )
        })}
      </Stack>
    </BoxStyled>
  )
}

export default Suggestions
