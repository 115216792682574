import { ErrorMessage } from '@hookform/error-message'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import authService from 'services/auth.service'
import permissionsService, {
  ALLOWED_ACTION_MANAGE_ROLE,
  ALLOWED_ACTION_MANAGE_USER,
} from 'services/permissions.service'
import { z } from 'zod'

import withDrawer, { TYPE_CONFIRM_DRAWER_CREATED } from '../hoc-drawer'
import { useMutationsCustom } from '../mutations'
import HeaderOne from './Header'
import UsersAdminCreateEditSelectRole from './SelectRole'

export const validationSchema = z.object({
  email: z
    .string({ required_error: "L'adresse email est obligatoire" })
    .email("L'adresse email est invalide"),
  firstname: z
    .string({ required_error: 'Prénom obligatoire' })
    .min(1, { message: 'Prénom obligatoire' }),
  name: z
    .string({ required_error: 'Nom obligatoire' })
    .min(1, { message: 'Nom obligatoire' }),
  roleId: z
    .number({ required_error: 'Rôle obligatoire' })
    .min(1, { message: 'Rôle obligatoire' }),
})

export type ValidationSchema = z.infer<typeof validationSchema>

const UsersAdminCreateEdit = ({
  resetStateConfirmDrawer,
  setConfirmDrawerState,
}: {
  resetStateConfirmDrawer: any
  setConfirmDrawerState: any
}) => {
  const navigate = useNavigate()
  const isAdmin = permissionsService.getPermission(ALLOWED_ACTION_MANAGE_USER)
  const isAdminRole = permissionsService.getPermission(
    ALLOWED_ACTION_MANAGE_ROLE,
  )

  const { userId: userIdUrl } = useParams()
  const userId = isAdmin ? userIdUrl : authService.getCurrentUser()?.id
  const isModeEdit = !!userId
  const [enabledGetUser, setEnabledGetUser] = useState(false)

  const { mutateUpdate } = useMutationsCustom({
    successCallback: () => {
      isAdmin ? navigate('/admin/users') : navigate('/profile')
    },
  })
  const { mutateCreate } = useMutationsCustom({
    successCallback: () => {
      //todo data email a mettre en param
      setConfirmDrawerState({
        email: data?.email,
        onSubmit: () => {
          resetStateConfirmDrawer()
          navigate('/admin/users')
        },
        open: true,
        type: TYPE_CONFIRM_DRAWER_CREATED,
      })
    },
  })
  const { data, isLoading } = useQuery({
    enabled: enabledGetUser,
    queryFn: async ({ queryKey: [, userId] }) => {
      const url = `/users/${userId}`
      const { data } = await axios.get(url)
      return data
    },

    queryKey: [`admin-user-get`, userId],
  })

  const { control, formState, handleSubmit } = useForm<ValidationSchema>({
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
    values: {
      email: data?.email,
      firstname: data?.firstname,
      name: data?.name,
      roleId: data?.role?.id,
    },
  })

  useEffect(() => {
    if (userId) {
      setEnabledGetUser(true)
    }
  }, [userId])

  const onSubmitFetch = (data: any) => {
    if (isModeEdit) {
      mutateUpdate({ data, id: +userId })
    } else {
      mutateCreate({ data })
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <HeaderOne
            isDirty={formState.isDirty}
            isEdit={!!data}
            onSubmitFetch={handleSubmit(onSubmitFetch)}
          />
        </Grid>
      </Grid>
      {isLoading && (
        <Box
          alignItems="center"
          display="flex"
          height={155}
          justifyContent="center"
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <Box display="flex" sx={{ flexGrow: 1, justifyContent: 'center', pt: 4 }}>
        <Stack width={640}>
          <Typography
            color="primary"
            fontWeight={700}
            ml={2}
            variant="subtitle2"
          >
            Identification
          </Typography>
          <Stack direction="row">
            <Controller
              control={control}
              name="firstname"
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={!!formState.errors.firstname}
                  focused={!!field.value || formState.touchedFields.firstname}
                  id="standard-firstname-user-input"
                  label="Prénom"
                  sx={{ m: 2 }}
                  variant="filled"
                  helperText={
                    <ErrorMessage errors={formState.errors} name="firstname" />
                  }
                  {...field}
                  inputRef={field.ref}
                />
              )}
            />
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={!!formState.errors.name}
                  focused={!!field.value || formState.touchedFields.name}
                  id="standard-name-user-input"
                  label="Nom"
                  sx={{ m: 2 }}
                  variant="filled"
                  {...field}
                  inputRef={field.ref}
                  helperText={
                    <ErrorMessage errors={formState.errors} name="name" />
                  }
                />
              )}
            />
          </Stack>
          <Stack direction="row">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  fullWidth
                  error={!!formState.errors.email}
                  focused={!!field.value || formState.touchedFields.email}
                  id="standard-email-user-input"
                  label="Email"
                  sx={{ m: 2 }}
                  variant="filled"
                  helperText={
                    <ErrorMessage errors={formState.errors} name="email" />
                  }
                  {...field}
                  inputRef={field.ref}
                />
              )}
            />

            {isAdminRole && (
              <UsersAdminCreateEditSelectRole
                control={control}
                formState={formState}
              />
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export default withDrawer(UsersAdminCreateEdit)
