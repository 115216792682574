import type { MapStyleDataEvent } from 'react-map-gl'

import bbox from '@turf/bbox'
import MapPopupApi from 'components/Map/Popup/Apl'
import MapPopupParty from 'components/Map/Popup/Party'
import MapPopupSample from 'components/Map/Popup/Sample'
import { cloneDeep, compact, get, isEmpty, set } from 'lodash'
import { formattedFilterParams } from 'pages/Core/helper'
import { useEffect } from 'react'
import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { myProjectType } from 'types/recoils/projects'

import { STATE_OVERVIEW, STATE_SPECIALITY } from '../InProgress/constants'
import { currentStateType } from '../InProgress/type'
import { myProjectState } from '../atoms'
import { getStylePaintApl } from './helpers'
import MAP_STYLE from './mapStyle'

const defaultMapStyle = cloneDeep(MAP_STYLE)
const defaultLayers = defaultMapStyle.layers

const schemaAreaTypeWithLayer = {
  cityCode: { id: 'cities', source: 'cities' },
  countryCode: { id: 'country', source: 'country' },
  countyCode: { id: 'counties', source: 'counties' },
  cptsCode: { id: 'cpts', source: 'cpts' },
  dacCode: { id: 'dac', source: 'dac' },
  epciCode: { id: 'epci', source: 'epci' },
  regionCode: { id: 'regions', source: 'regions' },
  tvsCode: { id: 'tvs', source: 'tvs' },
}

const layersTerritory = [
  'regions',
  'counties',
  'cities',
  'cpts',
  'dac',
  'tvs',
  'epci',
]
const layersSpeciality = ['apl']
const layersParties = ['parties-selected']

const layersToBeFilteredSelectedInfos = {
  apl: {
    matchOptionsWithProperties: {
      cityCode: 'cityCode',
      countyCode: 'countyCode',
      cptsCode: 'cptsCode',
      dacCode: 'dacCode',
      epciCode: 'epciCode',
      regionCode: 'regionCode',
      tvsCode: 'tvsCode',
    },
    nameLayer: 'apl',
  },
  'cities-selected': {
    matchOptionsWithProperties: { cityCode: 'code' },
    nameLayer: 'cities-selected',
  },
  'counties-selected': {
    matchOptionsWithProperties: { countyCode: 'code' },
    nameLayer: 'counties-selected',
  },
  'cpts-selected': {
    matchOptionsWithProperties: { cptsCode: 'code' },
    nameLayer: 'cpts-selected',
  },
  'dac-selected': {
    matchOptionsWithProperties: { dacCode: 'code' },
    nameLayer: 'dac-selected',
  },
  'epci-selected': {
    matchOptionsWithProperties: { epciCode: 'code' },
    nameLayer: 'epci-selected',
  },
  parties: {
    matchOptionsWithProperties: {
      cityCode: 'cityCode',
      countyCode: 'countyCode',
      cptsCode: 'cptsCode',
      dacCode: 'dacCode',
      epciCode: 'epciCode',
      regionCode: 'regionCode',
      tvsCode: 'tvsCode',
    },
    nameLayer: 'parties',
  },
  'parties-selected': {
    matchOptionsWithProperties: {
      category: 'category',
      cityCode: 'cityCode',
      countyCode: 'countyCode',
      cptsCode: 'cptsCode',
      dacCode: 'dacCode',
      epciCode: 'epciCode',
      expertise: 'expertise',
      journey: 'journey',
      party: 'partyId',
      regionCode: 'regionCode',
      tvsCode: 'tvsCode',
    },
    nameLayer: 'parties-selected',
  },
  'regions-selected': {
    matchOptionsWithProperties: { regionCode: 'code' },
    nameLayer: 'regions-selected',
  },
  'tvs-selected': {
    matchOptionsWithProperties: { tvsCode: 'code' },
    nameLayer: 'tvs-selected',
  },
} as any

const getMapStyle = ({
  currentState,
  myProject,
}: {
  currentState: currentStateType
  myProject: myProjectType
}) => {
  const areaType = myProject.areaType
  const specialityCode = myProject.speciality.value

  const filterApplied = {
    specialityCode: [specialityCode],
    ...formattedFilterParams(myProject.options),
    ...formattedFilterParams(myProject.party),
  } as any

  const layers = compact(
    defaultLayers
      .map((layer: any) => {
        const id = get(layer, 'id')
        const metadata = get(layer, 'metadata')

        if (layersSpeciality.includes(id)) {
          // on affiche vient colorer les polygone apl en fct de specialityCode
          if ([STATE_OVERVIEW, STATE_SPECIALITY].includes(currentState)) {
            return { ...layer, paint: getStylePaintApl(specialityCode) }
          }
          return null
        }

        // on affiche uniquement le layer geo en fonction du select areaType
        if (
          !layersTerritory.includes(id) ||
          (layersTerritory.includes(id) && areaType === metadata)
        ) {
          return layer
        }
      })
      .map((layer: any) => {
        if (!layer) return
        const id = get(layer, 'id')

        //on veut display les features de la liste layersToBeFilteredSelectedInfos
        const layerToFiltered = Object.keys(layersToBeFilteredSelectedInfos)
        if (layerToFiltered && layerToFiltered.includes(id)) {
          const layersInfos = layersToBeFilteredSelectedInfos[id]

          if (layersInfos.nameLayer === id) {
            const { matchOptionsWithProperties } = layersInfos
            const options = Object.keys(matchOptionsWithProperties)

            const matches = options.reduce((acc: any, option: string) => {
              const propertie = matchOptionsWithProperties[option]
              const value = filterApplied[option]

              if (!isEmpty(value))
                return [
                  ...acc,
                  [
                    'any',
                    ['!has', propertie],
                    ['in', propertie, ...value],
                    // ['all', ['match', ['get', propertie], value, true, false],]
                  ],
                ]
              return acc
            }, [])

            if (isEmpty(matches)) return layer

            return {
              ...layer,
              filter: ['all', ...matches],
            }
          }
        }
        return layer
      }),
  )

  const newLayers = set(defaultMapStyle, 'layers', layers)

  return newLayers
}

const ControlPanelMapInProgressProject = ({
  currentState,
  hoverInfo,
  mapRef,
  setMapStyle,
}: {
  currentState: currentStateType
  hoverInfo: any
  mapRef: any
  setMapStyle: any
}) => {
  const myProject = useRecoilValue(myProjectState)

  useEffect(() => {
    const newStyle = getMapStyle({
      currentState,
      myProject,
    })

    setMapStyle(newStyle)
  }, [myProject, hoverInfo, currentState])

  useEffect(() => {
    const zoomOnFeatures = (event: MapStyleDataEvent) => {
      const layerTarget = schemaAreaTypeWithLayer[myProject.areaType]
      const filter = event.target.getFilter(`${layerTarget.id}-selected`)

      const featuresAreaSelected = event.target.querySourceFeatures(
        layerTarget.id,
        {
          filter,
          sourceLayer: layerTarget.source,
        },
      )

      if (featuresAreaSelected && featuresAreaSelected?.length > 0) {
        const poly = bbox({
          features: featuresAreaSelected?.map((feature: any) => feature) || [],
          type: 'FeatureCollection',
        }) as [number, number, number, number]

        setTimeout(() => {
          event.target?.fitBounds(poly, { maxZoom: 13 })
        }, 300)
      }
    }
    mapRef.current.on('styledata', (event: MapStyleDataEvent) => {
      zoomOnFeatures(event)
    })
    mapRef.current.on('load', (event: MapStyleDataEvent) =>
      zoomOnFeatures(event),
    )
  }, [myProject, mapRef.current])

  return (
    <>
      {layersTerritory.includes(hoverInfo.layer) && (
        <MapPopupSample
          coordinates={hoverInfo.coordinates}
          properties={hoverInfo.properties}
        />
      )}

      {layersSpeciality.includes(hoverInfo.layer) && (
        <MapPopupApi
          coordinates={hoverInfo.coordinates}
          properties={hoverInfo.properties}
          specialityCode={myProject.speciality.value}
        />
      )}

      {layersParties.includes(hoverInfo.layer) && (
        <MapPopupParty
          coordinates={hoverInfo.coordinates}
          properties={hoverInfo.properties}
        />
      )}
    </>
  )
}

export default ControlPanelMapInProgressProject
