import { Typography } from '@mui/material'
import { ReactComponent as ClearImage } from 'assets/images/clear.svg'
import ConfirmDrawer from 'components/ConfirmDrawer'
import React, { useState } from 'react'

const createPromise = () => {
  let resolver
  return [
    new Promise((resolve) => {
      resolver = resolve
    }),
    resolver,
  ]
}

const useConfirmChangeAreaFilter = () => {
  const [open, setOpen] = useState(false)
  const [resolver, setResolver] = useState({ resolver: undefined } as any)

  const getConfirmation = async () => {
    setOpen(true)
    const [promise, resolve] = await createPromise()
    setResolver({ resolve })
    return promise
  }

  const onClick = async () => {
    setOpen(false)
    resolver.resolve()
  }

  const Confirmation = () => (
    <ConfirmDrawer
      modeTwoButtons
      bodySecondaryText="Voulez-vous continuer ?"
      Image={<ClearImage />}
      isOpenDrawer={open}
      titleText="Changer de type de localisation"
      onSubmit={onClick}
      onSubmitText="Effacer"
      handleCloseOpenDrawer={() => setOpen(false)}
      bodyPrimaryText={
        <>
          <Typography>
            <strong>
              Il n’est pas possible de mélanger les types localisation !
            </strong>
          </Typography>
          <Typography>
            En changeant le type de localisation, vous allez effacer la totalité
            de tous vos précédent filtres.
          </Typography>
        </>
      }
    />
  )

  return [getConfirmation, Confirmation] as [
    () => Promise<unknown>,
    () => JSX.Element,
  ]
}

export default useConfirmChangeAreaFilter
