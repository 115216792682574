import { Grid } from '@mui/material'
import { PartyType } from 'constants/enum'
import { LABEL_EXPERTISE, LABEL_TYPE_EXPERTISE } from 'constants/label'
import * as React from 'react'
import { Party } from 'types/party'

import AffiliatedHP from './AffiliatedHP'
import MapInformation from './Map'
import PracticeLocations from './PracticeLocations'
import BlockInformation from './components/BlockInformation'

const buildGlobalInfos = (party: Party) => {
  const isOrganization = party.partyType === PartyType.ORGANIZATION

  const address = party.address && party.address[0]?.address

  let globalInfos = [
    {
      label: 'Adresse',
      value: address,
    },
  ] as { label: string; value: any }[]

  if (isOrganization) {
    globalInfos = [
      ...globalInfos,
      {
        label: 'Code FINESS',
        value: party?.organization?.finess,
      },
      {
        label: 'Code Technique',
        value: party?.organization?.technicalCode,
      },
      {
        label: 'Mode de fixation des tarifs',
        value: party?.organization?.priceSettingMethod,
      },
    ]
  } else {
    globalInfos = [
      ...globalInfos,
      {
        label: 'Code RPPS',
        value: party?.person?.rpps,
      },
      {
        label: 'Activité',
        value: party?.person?.practice,
      },
      {
        label: LABEL_TYPE_EXPERTISE,
        value: party?.person?.typeExpertise,
      },
      {
        label: LABEL_EXPERTISE,
        value: party?.person?.expertise,
      },
    ]
  }

  return globalInfos
}

const Information = ({ party }: { party: Party }) => {
  const isOrganization = party.partyType === PartyType.ORGANIZATION

  const globalInfos = buildGlobalInfos(party)
  const contactInfos = [
    {
      label: 'Téléphone',
      value:
        isOrganization && party?.organization?.phone
          ? party?.organization?.phone.replace(
              /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
              '$1 $2 $3 $4 $5',
            )
          : null,
    },
  ]

  return (
    <Grid container spacing={2} sx={{ maxWidth: 1200, padding: '24px 16px' }}>
      <Grid item flexGrow={1} sm={6} xs={12}>
        <BlockInformation data={globalInfos} title={'Informations générales'} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <BlockInformation data={contactInfos} title={'Contact'} />
      </Grid>
      <Grid item xs={12}>
        {isOrganization ? (
          <AffiliatedHP party={party} />
        ) : (
          <PracticeLocations party={party} />
        )}
      </Grid>
      <Grid item height="600px" xs={12}>
        <MapInformation
          cityCode={party?.address[0]?.cityCode}
          partyId={party.id}
        />
      </Grid>
    </Grid>
  )
}

export default Information
