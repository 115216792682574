import PersonIcon from '@mui/icons-material/AccountBoxOutlined'
import DomainIcon from '@mui/icons-material/DomainRounded'
import { Typography } from '@mui/material'
import { PartyType } from 'constants/enum'
import 'mapbox-gl/dist/mapbox-gl.css'
import React from 'react'
import { Popup } from 'react-map-gl'
import { GeopointOsProperties } from 'types/party'

const MapPopupParty = ({
  closeOnClick,
  coordinates,
  properties,
}: {
  closeOnClick?: boolean
  coordinates?: number[]
  properties: GeopointOsProperties | undefined
}) => {
  if (!properties || !coordinates) {
    return <></>
  }

  const { category, city, name, numberPerson, partyType, zipcode } = properties
  return (
    <Popup
      className="county-info"
      closeButton={false}
      closeOnClick={closeOnClick}
      latitude={coordinates[1]}
      longitude={coordinates[0]}
      offset={20}
    >
      {partyType && (
        <Typography noWrap alignItems="center" display="flex">
          {partyType === PartyType.ORGANIZATION ? (
            <DomainIcon sx={{ mr: 1 }} />
          ) : (
            <PersonIcon sx={{ mr: 1 }} />
          )}
          {name}
        </Typography>
      )}

      {numberPerson && numberPerson !== 'undefined' && (
        <Typography noWrap>
          Nombre de PS : <b>{numberPerson}</b>
        </Typography>
      )}
      <Typography color="primary">{category}</Typography>
      <Typography noWrap>
        {zipcode} {city}
      </Typography>
    </Popup>
  )
}

export default MapPopupParty
