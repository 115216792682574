import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import axios from 'axios'
import SelectMultiple from 'components-ui/SelectMultiple'
import { selectOptions } from 'components-ui/SelectMultiple/type'
import {
  LABEL_CITY,
  LABEL_COUNTRY,
  LABEL_COUNTY,
  LABEL_CPTS,
  LABEL_DAC,
  LABEL_EPCI,
  LABEL_REGION,
  LABEL_TVS,
} from 'constants/label'
import {
  CONST_CITIES,
  CONST_COUNTIES,
  CONST_COUNTRY,
  CONST_CPTS,
  CONST_DAC,
  CONST_EPCI,
  CONST_REGIONS,
  CONST_TVS,
} from 'pages/Core/Projects/InProgress/constants'
import { areaType } from 'pages/Core/Projects/InProgress/type'
import { formatAreasToSelectOptions } from 'pages/Core/Projects/utils'
import React, { useEffect, useState } from 'react'
import { mySearchType } from 'types/recoils/parties-search'

import { selectConfigAreaType } from './type'

const getParamsUrl = ({
  pageSize,
  searchText,
}: {
  pageSize?: number
  searchText: string
}) => {
  const where = searchText
    ? {
        name: {
          contains: searchText,
          mode: 'insensitive',
        },
      }
    : {}

  return new URLSearchParams({
    crudQuery: JSON.stringify({
      orderBy: [{ name: 'asc' }],
      pageSize: pageSize,
      select: { only: ['code', 'name'] },
      where,
    }),
  })
}

const AreaFilterComponent = ({
  myData,
  onChangeRadioEvent,
  setMyData,
}: {
  myData: mySearchType
  onChangeRadioEvent?: any
  setMyData: any
}) => {
  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 250)
  const [pageSize, setPageSize] = useState(10)

  const configSelectArea = {
    [CONST_CITIES]: {
      fetchUrl: 'cities',
      key: CONST_CITIES as areaType,
      label: LABEL_CITY,
      optionsWanted: [],
      placeholder: 'ex : 69003',
      totalRecords: 0,
    },
    [CONST_COUNTIES]: {
      fetchUrl: 'counties',
      key: CONST_COUNTIES as areaType,
      label: LABEL_COUNTY,
      optionsWanted: [],
      placeholder: 'ex : HAUTES ALPES',
      totalRecords: 0,
    },
    [CONST_COUNTRY]: {
      disabled: true,
      fetchUrl: '',
      key: CONST_COUNTRY as areaType,
      label: LABEL_COUNTRY,
      optionsWanted: [],
      placeholder: '',
      totalRecords: 0,
    },
    [CONST_CPTS]: {
      fetchUrl: 'cpts',
      key: CONST_CPTS as areaType,
      label: LABEL_CPTS,
      optionsWanted: [],
      placeholder: 'ex : ...',
      totalRecords: 0,
    },
    [CONST_DAC]: {
      fetchUrl: 'dac',
      key: CONST_DAC as areaType,
      label: LABEL_DAC,
      optionsWanted: [],
      placeholder: 'ex : ...',
      totalRecords: 0,
    },
    [CONST_EPCI]: {
      fetchUrl: 'epci',
      key: CONST_EPCI as areaType,
      label: LABEL_EPCI,
      optionsWanted: [],
      placeholder: 'ex : ...',
      totalRecords: 0,
    },
    [CONST_REGIONS]: {
      fetchUrl: 'regions',
      key: CONST_REGIONS as areaType,
      label: LABEL_REGION,
      optionsWanted: [],
      placeholder: 'ex : Auvergne-Rhône-Alpes',
      totalRecords: 0,
    },
    [CONST_TVS]: {
      fetchUrl: 'tvs',
      key: CONST_TVS as areaType,
      label: LABEL_TVS,
      optionsWanted: [],
      placeholder: 'ex : ...',
      totalRecords: 0,
    },
  }

  const [selectConfig, setSelectConfig] = React.useState<selectConfigAreaType>(
    configSelectArea[myData.areaType],
  )

  useEffect(() => {
    setSelectConfig(configSelectArea[myData.areaType])
  }, [myData.areaType])

  useQuery({
    enabled: !!selectConfig.fetchUrl,
    queryFn: async ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryKey: [, { debouncedSearchText, pageSize, url }],
    }) => {
      const { data } = await axios.get(
        `/${url}?${getParamsUrl({ pageSize, searchText: debouncedSearchText })}`,
      )

      const dataFormatted = formatAreasToSelectOptions(data?.data) || []
      setSelectConfig((old: any) => ({
        ...old,
        optionsWanted: dataFormatted,
        totalRecords: data?.totalRecords,
      }))
      return data
    },
    queryKey: [
      `area-filters-${selectConfig?.fetchUrl}`,
      { debouncedSearchText, pageSize, url: selectConfig?.fetchUrl },
    ],
  })

  const onChangeRadio = async (event?: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value as areaType
    const myDataNew = onChangeRadioEvent ? await onChangeRadioEvent() : myData
    setSelectConfig(configSelectArea[value])
    setMyData({
      ...myDataNew,
      areaType: value,
      options: myDataNew.options,
    })
  }

  const onChangeSelect = (newSelectedOptions: selectOptions[]) => {
    if (selectConfig?.key) {
      const myDataNew = {
        ...myData,
        options: {
          ...myData.options,
          [selectConfig.key]: newSelectedOptions,
        },
      }
      setMyData(myDataNew)
    }
  }

  const loadMore = ({
    morePageSize,
    reset,
  }: {
    morePageSize: number
    reset: boolean
  }) => {
    const newPageSize = reset ? morePageSize : pageSize + morePageSize
    setPageSize(newPageSize)
  }

  return (
    <Stack width="100%">
      <RadioGroup
        row
        aria-labelledby="zone-geo-group-label"
        name="zone-geo-group-label"
        value={selectConfig ? myData.areaType : null}
        onChange={onChangeRadio}
      >
        <FormControlLabel
          control={<Radio />}
          label={LABEL_REGION}
          value={CONST_REGIONS}
        />
        <FormControlLabel
          control={<Radio />}
          label={LABEL_COUNTY}
          value={CONST_COUNTIES}
        />
        <FormControlLabel
          control={<Radio />}
          label={LABEL_CITY}
          value={CONST_CITIES}
        />
        <FormControlLabel
          control={<Radio />}
          label={LABEL_CPTS}
          value={CONST_CPTS}
        />
        <FormControlLabel
          control={<Radio />}
          label={LABEL_DAC}
          value={CONST_DAC}
        />
        <FormControlLabel
          control={<Radio />}
          label={LABEL_TVS}
          value={CONST_TVS}
        />
        <FormControlLabel
          control={<Radio />}
          label={LABEL_EPCI}
          value={CONST_EPCI}
        />
      </RadioGroup>

      <SelectMultiple
        disabled={selectConfig.disabled}
        label={selectConfig.label}
        loadMore={loadMore}
        options={selectConfig.optionsWanted}
        optionsSelected={myData.options[selectConfig.key]}
        placeholder={selectConfig.placeholder}
        totalRecords={selectConfig.totalRecords}
        updateOptionsWanted={setSearchText}
        onChangeSelect={onChangeSelect}
      />
    </Stack>
  )
}

export default AreaFilterComponent
