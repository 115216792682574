import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LogoContainer = styled(Box)(() => ({
  '& svg': {
    height: 'auto',
  },
  display: 'flex',
  flexDirection: 'column',
  margin: '10px auto',
  width: 162,
}))
