import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  Checkbox,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { deburr, differenceWith, isEqual } from 'lodash'
import * as React from 'react'

import type { selectOptions } from './type'

import { IconCheckbox, IconCheckboxBlank } from '.'

const containsText = (text: string, searchText: string) => {
  return (
    deburr(text).toUpperCase().indexOf(deburr(searchText).toUpperCase()) > -1
  )
}

const SelectResults = ({
  loadMore,
  nbItemsLoadMore,
  onChangeResultSelect,
  options,
  optionsSelected,
  totalRecords,
  updateOptionsWanted,
}: {
  loadMore?: any
  nbItemsLoadMore: number
  onChangeResultSelect: any
  options: selectOptions[]
  optionsSelected: selectOptions[]
  totalRecords: number
  updateOptionsWanted?: any
}) => {
  const [searchText, setSearchText] = React.useState<string>('')
  const [optionsWanted, setOptionsWanted] = React.useState<selectOptions[]>([])
  const [optionsWantedWithoutSelected, setOptionsWantedWithoutSelected] =
    React.useState<selectOptions[]>([])
  const handleUpdateOptionsWanted = async () => {
    if (updateOptionsWanted) {
      await updateOptionsWanted(searchText)
    }
  }
  const handleOptionsWanted = () => {
    const optionsWithSearchText = updateOptionsWanted
      ? options
      : options.filter((option: selectOptions) =>
          containsText(option.label, searchText),
        )

    setOptionsWanted(optionsWithSearchText)
    const optionsWantedDifference = differenceWith(
      optionsWithSearchText,
      optionsSelected,
      isEqual,
    )
    setOptionsWantedWithoutSelected(optionsWantedDifference)
    return
  }

  React.useEffect(() => {
    handleOptionsWanted()
  }, [searchText, optionsSelected, options])

  React.useEffect(() => {
    handleUpdateOptionsWanted()
  }, [searchText])

  return (
    <>
      <ListSubheader sx={{ display: 'flex', p: 1 }}>
        <TextField
          fullWidth
          inputProps={{ autoFocus: true }}
          placeholder="Rechercher..."
          variant="standard"
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation()
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </ListSubheader>

      {searchText !== '' && optionsWanted?.length === 0 && (
        <ListSubheader sx={{ fontWeight: 700 }}>
          Pas de résultat(s)
        </ListSubheader>
      )}
      {optionsWantedWithoutSelected.map((option: selectOptions, i: number) => (
        <MenuItem
          key={option.value + i}
          sx={{ p: 0 }}
          onClick={() => {
            onChangeResultSelect(option)
            if (loadMore) {
              loadMore({ morePageSize: 1 })
            }
          }}
        >
          <Checkbox
            checkedIcon={IconCheckbox}
            icon={IconCheckboxBlank}
            style={{ marginRight: 8 }}
          />
          <Tooltip placement="right-end" title={option.label}>
            <Typography noWrap sx={{ width: '80%' }}>
              {option.label}
            </Typography>
          </Tooltip>
        </MenuItem>
      ))}

      {loadMore && options.length < totalRecords && (
        <Stack m={2}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={() => loadMore({ morePageSize: nbItemsLoadMore })}
          >
            Voir plus
          </Button>
        </Stack>
      )}
    </>
  )
}
export default SelectResults
