import { Box, Grid } from '@mui/material'
import { ReactComponent as MedecineSvg } from 'assets/images/medicne.svg'
import React from 'react'

import type { SplitScreenLayoutProps } from '../types'

import HelmetCustom from '../Helmet'
import { Container, StyledBox } from './styles'

const SplitScreenLayout = ({
  description,
  leftElement,
  ogImage,
  rightElement,
  title,
  url,
}: SplitScreenLayoutProps) => {
  return (
    <Container>
      <HelmetCustom
        description={description}
        ogImage={ogImage}
        title={title}
        url={url}
      />
      <Grid container component="main">
        <Grid item md={6} sm={6} sx={{ height: '100vh' }} xs={12}>
          <StyledBox color="white">
            {leftElement || (
              <Box width="350px">
                <MedecineSvg />
              </Box>
            )}
          </StyledBox>
        </Grid>
        <Grid item md={6} sm={6} sx={{ height: '100vh' }} xs={12}>
          <StyledBox sx={{ backgroundColor: '#FFFFFF' }}>
            {rightElement}
          </StyledBox>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SplitScreenLayout
