import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { pick } from 'lodash'
import { formattedFilterParams } from 'pages/Core/helper'
import { myProjectType } from 'types/recoils/projects'

export const maxTotalItem = 150

export const useQueriesCustom = (myProject: myProjectType) => {
  const myProjectAreaField = pick(myProject.options, [
    'dacCode',
    'cptsCode',
    'tvsCode',
    'cityCode',
    'countyCode',
    'regionCode',
    'epciCode',
  ])

  const filters = formattedFilterParams(myProjectAreaField)

  const { data: responseExpertise } = useQuery({
    queryFn: async ({ queryKey: [, filters] }) => {
      const { data } = await axios.get(
        `/opensearch/aggs/party?params={"field":"expertise.keyword", "filters": ${JSON.stringify(
          filters,
        )}}`,
      )
      return data
    },
    queryKey: [`project-inprogress-selection-focus-expertise`, filters],
  })

  const { data: responseCategoryOrganization } = useQuery({
    queryFn: async ({ queryKey: [, filters] }) => {
      const { data } = await axios.get(
        `/opensearch/aggs/party?params={"field":"categoryOrganization.keyword", "filters": ${JSON.stringify(
          filters,
        )}}`,
      )
      return data
    },
    queryKey: [
      `project-inprogress-selection-focus-categoryOrganization`,
      filters,
    ],
  })

  const { data: responseCategoryPerson } = useQuery({
    queryFn: async ({ queryKey: [, filters] }) => {
      const { data } = await axios.get(
        `/opensearch/aggs/party?params={"field":"categoryPerson.keyword", "filters": ${JSON.stringify(
          filters,
        )}}`,
      )
      return data
    },
    queryKey: [`project-inprogress-selection-focus-categoryPerson`, filters],
  })

  const { data: responseJourney } = useQuery({
    queryFn: async ({ queryKey: [, filters] }) => {
      const { data } = await axios.get(
        `/opensearch/aggs/party?params={"field":"journey.keyword", "filters": ${JSON.stringify(
          filters,
        )}}`,
      )
      return data
    },
    queryKey: [`project-inprogress-selection-focus-responseJourney`, filters],
  })

  return {
    responseCategoryOrganization,
    responseCategoryPerson,
    responseExpertise,
    responseJourney,
  }
}
