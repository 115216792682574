import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { IconButtonOutlined } from 'components-ui/IconButton/styles'
import { find, map } from 'lodash'
import { PencilIcon, TrashIcon } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RoleType } from 'types/role'

import { CountRole } from '..'
import withDrawer, { TYPE_CONFIRM_DRAWER_DELETE } from '../../hoc-drawer'
import { useMutationsRole } from '../../mutations'

const AsideRolesAdminList = ({
  refetchRoles,
  resetStateConfirmDrawer,
  roleSelected,
  roles,
  setConfirmDrawerState,
  setRoleSelected,
}: {
  refetchRoles: any
  resetStateConfirmDrawer: any
  roleSelected: (RoleType & CountRole) | undefined
  roles: RoleType[]
  setConfirmDrawerState: any
  setRoleSelected: any
}) => {
  const navigate = useNavigate()

  const { mutateDelete } = useMutationsRole({ successCallback: refetchRoles })

  const handleUpdateRole = () => {
    navigate(`/admin/roles/${roleSelected?.id}`)
  }

  const handleUserDeleted = () => {
    if (roleSelected?.id) {
      setConfirmDrawerState({
        onSubmit: async () => {
          await mutateDelete({ id: roleSelected?.id })
          resetStateConfirmDrawer()
        },
        open: true,
        type: TYPE_CONFIRM_DRAWER_DELETE,
        userName: roleSelected.name,
      })
    }
  }
  return (
    <Stack
      direction="column"
      display="flex"
      flexGrow={1}
      maxWidth={350}
      p={3}
      width={350}
    >
      {!roleSelected ? (
        <Typography variant="subtitle1">
          Veuillez créer un nouveau role
        </Typography>
      ) : (
        <>
          <Stack spacing={2}>
            <Stack>
              <FormControl fullWidth variant="filled">
                <InputLabel color="primary" id="role-select-user-label">
                  Rôle
                </InputLabel>

                <Select
                  defaultValue={roleSelected?.id}
                  id="standard-role-user-input"
                  labelId="role-select-user-label"
                  value={roleSelected?.id}
                  onChange={({ target }) => {
                    setRoleSelected(find(roles, (o) => o.id === +target?.value))
                  }}
                >
                  {map(roles, (role) => (
                    <MenuItem key={`${role.id} ${role.name}`} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack>
              <Typography>Utilisé par</Typography>
              <Typography variant="subtitle1">
                {roleSelected?._count?.users} utilisateurs
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            mb={2}
            mt="auto"
            spacing={2}
          >
            {!roleSelected.lock && (
              <>
                <IconButtonOutlined color="primary" onClick={handleUpdateRole}>
                  <PencilIcon />
                </IconButtonOutlined>
                <IconButtonOutlined color="error" onClick={handleUserDeleted}>
                  <TrashIcon />
                </IconButtonOutlined>
              </>
            )}
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default withDrawer(AsideRolesAdminList)
