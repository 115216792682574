import { atom, selector } from 'recoil'
import { Questionnaire } from 'types/questionnaire'

/*** STATES ***/
export const tabState = atom({
  default: 0,
  key: 'tabState',
})
export const questionnaireState = atom({
  default: null as Questionnaire | null,
  key: 'questionnaireState',
})

/*** SELECTORS ***/
export const tabStateSelector = selector({
  get: ({ get }) => {
    return get(tabState)
  },
  key: 'getTabState',
})
export const questionnaireStateSelector = selector({
  get: ({ get }) => {
    return get(questionnaireState)
  },
  key: 'getQuestionnaireState',
})
