import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { PartyType } from 'constants/enum'
import { omit } from 'lodash'
import { formattedFilterParams } from 'pages/Core/helper'
import { myProjectType } from 'types/recoils/projects'

export const useQueriesCustom = ({
  myProject,
  searchText,
  size,
}: {
  myProject: myProjectType
  searchText: string
  size: number
}) => {
  const filter = formattedFilterParams(
    omit(myProject.options, ['organization', 'person']),
  )

  const { data: responseOrganization } = useQuery({
    queryFn: async ({ queryKey: [, searchText, size, filter] }) => {
      const { data } = await axios.post(`/opensearch/search/party`, {
        myFilters: {
          ...filter,
          partyType: [PartyType.ORGANIZATION],
          search: [searchText],
        },
        size,
      })
      return data
    },
    queryKey: [
      `project-inprogress-selection-party-organization`,
      searchText,
      size,
      filter,
    ],
  })

  const { data: responsePerson } = useQuery({
    queryFn: async ({ queryKey: [, searchText, size, filter] }) => {
      const { data } = await axios.post(`/opensearch/search/party`, {
        myFilters: {
          ...filter,
          partyType: [PartyType.PERSON],
          search: [searchText],
        },
        size,
      })
      return data
    },
    queryKey: [
      `project-inprogress-selection-party-person`,
      searchText,
      size,
      filter,
    ],
  })

  return {
    responseOrganization,
    responsePerson,
  }
}
