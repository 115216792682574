import { Box, Button, Stack, Typography } from '@mui/material'
import { ReactComponent as LogoFullDark } from 'assets/images/logoFullDark.svg'
import SplitScreenLayout from 'components/Layout/SplitScreen'
import React from 'react'
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom'

export default function ErrorPage() {
  const navigate = useNavigate()
  const error = useRouteError()

  let messageError = ''

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      messageError = "🔍 Cette page n'existe pas"
    }

    if (error.status === 401) {
      messageError = "🔒 Tu n'as pas l'authorisation necessaire pour être la"
    }

    if (error.status === 503) {
      messageError = '⚡ Back-end down'
    }

    if (error.status === 418) {
      messageError = '🫖'
    }
  }

  const rightElement = (
    <Stack spacing={3}>
      <Box sx={{ margin: 'auto', width: 230 }}>
        <LogoFullDark />
      </Box>
      <Stack alignItems="center" flexGrow={1} justifyContent="center">
        <Typography variant="h6">
          {messageError || '🛠 Une erreur est survenue'}
        </Typography>
        <Button onClick={() => navigate('/')}>Retour à l&apos;accueil</Button>
      </Stack>
      <Button variant="outlined" onClick={() => navigate(-1)}>
        &larr; Go back
      </Button>
    </Stack>
  )

  return (
    <SplitScreenLayout
      description="Une erreur est survenue"
      rightElement={rightElement}
      title="MedTrucks"
    ></SplitScreenLayout>
  )
}
