import { Button, Stack } from '@mui/material'
import Header from 'components/Header'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

export const validationSchema = z.object({
  search: z.string(),
})

export type ValidationSchema = z.infer<typeof validationSchema>

const HeaderOne = ({
  isDirty,
  isEdit,
  onSubmitFetch,
}: {
  isDirty: boolean
  isEdit: boolean
  onSubmitFetch: any
}) => {
  const navigate = useNavigate()

  const title = isEdit ? 'Modification utilisateur' : 'Nouveau utilisateur'

  const rowRight = (
    <Stack direction="row" spacing={2}>
      <Button variant="text" onClick={() => navigate(-1)}>
        Annuler
      </Button>
      <Button disabled={!isDirty} variant="contained" onClick={onSubmitFetch}>
        Valider
      </Button>
    </Stack>
  )

  return (
    <Header
      rowLeftDescription="Information générales"
      rowLeftTitle={title}
      rowRight={rowRight}
    />
  )
}
export default HeaderOne
