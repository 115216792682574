import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material'
import { PartyTypeLabel } from 'constants/enum'
import { find, xorBy } from 'lodash'
import React from 'react'
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { mySearchType } from 'types/recoils/parties-search'

const TypeFilters = ({
  nextMyFiltersState,
}: {
  nextMyFiltersState: RecoilState<mySearchType>
}) => {
  const {
    options: { partyType },
  } = useRecoilValue(nextMyFiltersState)
  const setNextFilters = useSetRecoilState(nextMyFiltersState)

  return (
    <FormControl component="fieldset" sx={{ m: 2 }} variant="standard">
      <FormLabel component="legend">Type</FormLabel>
      <FormGroup
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setNextFilters((oldValues) => {
            const newTypeValue = xorBy(
              partyType,
              [
                {
                  label: e.target.labels?.[0].outerText || '',
                  value: e.target.value,
                },
              ],
              'value',
            )
            return {
              ...oldValues,
              options: {
                ...oldValues.options,
                partyType: newTypeValue.length > 0 ? newTypeValue : [],
              },
            }
          })
        }}
      >
        <FormControlLabel
          checked={!!find(partyType, (o) => o.value === 'ORGANIZATION')}
          control={<Checkbox />}
          label={PartyTypeLabel.ORGANIZATION}
          value="ORGANIZATION"
        />
        <FormControlLabel
          checked={!!find(partyType, (o) => o.value === 'PERSON')}
          control={<Checkbox />}
          label={PartyTypeLabel.PERSON}
          value="PERSON"
        />
      </FormGroup>
    </FormControl>
  )
}

export default TypeFilters
