import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/material'
import { ReactComponent as CongratSvg } from 'assets/images/congrat.svg'
import { ReactComponent as LogoFullDark } from 'assets/images/logoFullDark.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const PasswordCongrat = ({ text }: { text: string }) => {
  const navigate = useNavigate()

  return (
    <Stack
      spacing={4}
      sx={{
        width: 430,
      }}
    >
      <Box sx={{ margin: 'auto!important', width: 230 }}>
        <LogoFullDark />
        <Typography
          align="center"
          color="primary"
          sx={{ fontWeight: 700 }}
          variant="body1"
        >
          Lutter contre les déserts médicaux
        </Typography>
      </Box>
      <Stack>
        <Typography fontWeight={700} variant="subtitle1">
          Félicitation
        </Typography>
        <Typography>{text}</Typography>
        <Box alignContent="center" height="180px" mt={3}>
          <CongratSvg />
        </Box>
      </Stack>

      <Stack>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          onClick={() => navigate('/login')}
        >
          Retour à la page de connexion
        </Button>
      </Stack>
    </Stack>
  )
}

export default PasswordCongrat
