import { atom } from 'recoil'
import {
  myDiagnosticExtraType,
  myDiagnosticType,
} from 'types/recoils/diagnostics'

import { defaultMySearchState } from '../Search/atoms'

export const defaultMyDiagnosticState = {
  ...defaultMySearchState,
} as myDiagnosticType

export const defaultMyDiagnosticExtraState = {
  name: '',
  speciality: {
    label: '',
    value: '',
  },
} as myDiagnosticExtraType

export const myDiagnosticState = atom({
  default: defaultMyDiagnosticState,
  key: 'myDiagnosticState',
})

export const myDiagnosticExtraState = atom({
  default: defaultMyDiagnosticExtraState,
  key: 'myDiagnosticExtraState',
})

export const nextMyDiagnosticState = atom({
  default: defaultMyDiagnosticState,
  key: 'nextMyDiagnosticState',
})
