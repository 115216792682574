import { Button, Typography } from '@mui/material'
import { RowSelectionState } from '@tanstack/react-table'
import { PartyType } from 'constants/enum'
import { xorBy } from 'lodash'
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { PartyOS } from 'types/party'

import { pageSize } from '../..'
import { myDiagnosticState } from '../../atoms'

const PartyList = ({
  isLoading,
  pagination,
  response,
  setPagination,
}: {
  isLoading: boolean
  pagination: MRT_PaginationState
  response: any
  setPagination: any
}) => {
  const navigate = useNavigate()
  const myDiagnostic = useRecoilValue(myDiagnosticState)
  const setMyDiagnostic = useSetRecoilState(myDiagnosticState)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({}) //manage your own row selection state

  useEffect(() => {
    return () => {
      setRowSelection({})
      setMyDiagnostic((curr) => ({
        ...curr,
        party: { organization: [], person: [] },
      }))
    }
  }, [])

  const onClickName = (id: string) => {
    navigate(`/party/${id}`)
  }

  const columns: MRT_ColumnDef<{ data: PartyOS }>[] = [
    {
      Cell: ({ row }) => (
        <>
          <Button
            variant="text"
            onClick={() => onClickName(row?.original?.data.partyId)}
          >
            <Typography color="primary" sx={{ textDecoration: 'underline' }}>
              {row?.original.data.name}
            </Typography>
          </Button>
          <Typography>{row?.original.data.partyId}</Typography>
        </>
      ),
      accessorFn: (row) => row.data.name,
      filterVariant: 'autocomplete',
      header: 'Nom / Code',
    },
    {
      accessorFn: (row) => row.data.category,
      header: "Profession ou type d'établissement",
    },
    {
      accessorFn: (row) => row.data.address,
      header: 'Adresse',
    },
  ]

  const onClickRow = (row: MRT_Row<{ data: PartyOS }>) => {
    console.log(row)
    const { data } = row.original
    setRowSelection((prev) => ({
      ...prev,
      [row.id]: !prev[row.id],
    }))

    const key =
      data.partyType === PartyType.ORGANIZATION ? 'organization' : 'person'
    const newParty = {
      ...myDiagnostic.party,
      [key]: xorBy(
        myDiagnostic.party?.[key],
        [
          {
            category: data.category,
            label: data.name,
            value: data.partyId,
          },
        ],
        'value',
      ),
    }
    setMyDiagnostic((curr) => ({
      ...curr,
      party: newParty,
    }))
  }
  const table = useMaterialReactTable({
    columns,
    data: response?.data || [],
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableHiding: false,
    enablePagination: true,
    enableRowSelection: true,
    enableSorting: false,
    enableStickyFooter: true,
    enableStickyHeader: true,
    enableTopToolbar: false,
    getRowId: (row) => row?.data?.partyId,
    initialState: {
      pagination: { pageIndex: 0, pageSize },
    },
    localization: MRT_Localization_FR,
    manualPagination: true,
    muiPaginationProps: {
      showLastButton: false,
      showRowsPerPage: false,
      variant: 'outlined',
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClickCapture: () => onClickRow(row),
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTableContainerProps: { sx: { height: 'calc(100% - 70px)' } },
    muiTablePaperProps: { sx: { height: '100%' } },
    onPaginationChange: setPagination,
    paginationDisplayMode: 'default',
    positionActionsColumn: 'last',
    positionToolbarAlertBanner: 'top',
    rowCount: response?.totalValue,
    selectAllMode: 'all',
    state: {
      isLoading,
      pagination,
      rowSelection,
    },
  })

  return <MaterialReactTable table={table} />
}

export default PartyList
