import { Stack, Typography } from '@mui/material'
import { useContext } from 'react'

import { AbilityContext } from './Can'

export const HOCAllowed = (
  WrappedComponent: React.FC,
  action: string,
  subject: string,
) => {
  const ability = useContext(AbilityContext)
  const allowed = ability.can(action, subject)

  if (ability.rules.length === 0) {
    return (
      <Stack alignItems="center" flex="1" justifyContent="center">
        <Typography variant="h3">Chargement de votre profil...</Typography>
      </Stack>
    )
  }
  if (!allowed) {
    return (
      <Stack alignItems="center" flex="1" justifyContent="center">
        <Typography variant="h3">Zone non autorisé</Typography>
      </Stack>
    )
  }

  return <WrappedComponent />
}
