import type { Party } from 'types/party'

import Box from '@mui/material/Box'
import * as React from 'react'

import Information from './Information'
import Maturity from './Maturity'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, index, value, ...other } = props
  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

const Tabs = ({
  allowedQuestionnaire,
  party,
  tabsValue,
}: {
  allowedQuestionnaire: boolean
  party: Party
  tabsValue: number
}) => {
  return (
    <>
      <TabPanel index={0} value={tabsValue}>
        <Information party={party} />
      </TabPanel>
      {allowedQuestionnaire && (
        <TabPanel index={1} value={tabsValue}>
          <Maturity partyId={party?.id} />
        </TabPanel>
      )}
    </>
  )
}

export default Tabs
