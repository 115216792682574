import { defineAbility } from '@casl/ability'
import { AbilityContext } from 'components/Ability/Can'
import { useQueriesGetRoleMe } from 'pages/Core/Admin/Roles/queries'
import React from 'react'
import authService from 'services/auth.service'
import { PermissionType } from 'types/permission'

const AbilityProvider = (props: any) => {
  const currentUser = authService.getCurrentUser()

  const { dataRoleMe: data } = useQueriesGetRoleMe({
    enabled: !!currentUser,
  })

  if (!currentUser && data?.permissions?.length > 0) {
    return props.children
  }

  const ability = defineAbility((can, cannot) => {
    data?.permissions.forEach(
      ({ action, conditions, inverted, subject }: PermissionType) => {
        let conditionsJson
        if (conditions) {
          const old = JSON.stringify(conditions).replace(
            /"replaceToUserId"/g,
            `${currentUser?.id}`,
          )
          conditionsJson = JSON.parse(old)
        }

        const allowOrNot = inverted ? cannot : can
        allowOrNot(action, subject as any, conditionsJson)
      },
    )
  })

  // Définir les capacités (abilities) dans votre application
  return (
    <AbilityContext.Provider value={ability}>
      {props.children}
    </AbilityContext.Provider>
  )
}

export default AbilityProvider
