import MenuIcon from '@mui/icons-material/MenuOutlined'
import AppBarMui from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { ReactComponent as LogoFullLight } from 'assets/images/logoFullLight.svg'
import * as React from 'react'
import primary from 'themes/colors/primary'

import { LogoContainer } from './styles'

export const drawerWidth = 250

export default function AppBar({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void
}) {
  return (
    <AppBarMui
      position="fixed"
      sx={{
        backgroundColor: primary[900],
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar sx={{ display: { sm: 'none' }, mr: 2 }}>
        <IconButton
          aria-label="open drawer"
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <LogoContainer>
          <LogoFullLight />
          <Typography align="center" fontSize={10} fontWeight={700}>
            Lutter contre les déserts médicaux
          </Typography>
        </LogoContainer>
      </Toolbar>
    </AppBarMui>
  )
}
