import {
  LABEL_USER_STATUS_DISABLED,
  LABEL_USER_STATUS_ENABLED,
  LABEL_USER_STATUS_WAIT_PASSWORD,
} from './label'

export const CONST_PROJECT_STATUS_DRAFT = 'DRAFT'
export const CONST_PROJECT_STATUS_PUBLISHED = 'PUBLISHED'

export const CONST_USER_STATUS_DISABLED = 'DISABLED'
export const CONST_USER_STATUS_ENABLED = 'ENABLED'
export const CONST_USER_STATUS_WAIT_PASSWORD = 'WAIT_PASSWORD'

export const PartyRole = {
  EMPLOYEE: 'employee',
  LIBERAL: 'liberal',
  VOLUNTEER: 'volunteer',
}

export const UserStatus = {
  DISABLED: CONST_USER_STATUS_DISABLED,
  ENABLED: CONST_USER_STATUS_ENABLED,
  WAIT_PASSWORD: CONST_USER_STATUS_WAIT_PASSWORD,
}

export const PartyType = {
  ORGANIZATION: 'ORGANIZATION',
  PERSON: 'PERSON',
}

export const Civility = {
  MADAME: 'MADAME',
  MADEMOISELLE: 'MADEMOISELLE',
  MONSIEUR: 'MONSIEUR',
}

export const PartyTypeLabel = {
  ORGANIZATION: 'Etablissements',
  PERSON: 'Professionnels de santé',
}

export const listOfStatusProject = [
  CONST_PROJECT_STATUS_DRAFT,
  CONST_PROJECT_STATUS_PUBLISHED,
] as const

export const StatusProject = {
  DRAFT: CONST_PROJECT_STATUS_DRAFT,
  PUBLISHED: CONST_PROJECT_STATUS_PUBLISHED,
}

export const UserStatusLabel = {
  [UserStatus.DISABLED]: LABEL_USER_STATUS_DISABLED,
  [UserStatus.ENABLED]: LABEL_USER_STATUS_ENABLED,
  [UserStatus.WAIT_PASSWORD]: LABEL_USER_STATUS_WAIT_PASSWORD,
}

export const FilterParamPartyTypeOrganisation = [
  {
    label: PartyTypeLabel['ORGANIZATION'],
    value: PartyType.ORGANIZATION,
  },
]
export const FilterParamPartyTypePerson = [
  {
    label: PartyTypeLabel['PERSON'],
    value: PartyType.PERSON,
  },
]
