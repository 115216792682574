import { Stack, Typography } from '@mui/material'
import React from 'react'

const ProjectTabUsersAdminProfile = () => {
  return (
    <Stack p={6} spacing={2}>
      <Typography color="primary" fontWeight={700} variant="subtitle2">
        Coming soon !
      </Typography>
    </Stack>
  )
}

export default ProjectTabUsersAdminProfile
