import { Box, Stack, Typography } from '@mui/material'
import { toJpeg } from 'html-to-image'
import React, { useCallback, useEffect, useReducer } from 'react'
import { useRecoilValue } from 'recoil'

import { myDiagnosticState } from '../../atoms'
import DemographicDynamic from './DemographicDynamic'
import EconomicDynamic from './EconomicDynamic'
import SocialFragilityDynamic from './SocialFragilityDynamic'

// const COLOR_MY_TERRITORY = '#4260C9'
const COLOR_REGION = '#C75E23'
const COLOR_COUNTY = '#E9A745'
const COLOR_COUNTRY = '#8767E0'

const BoxLegend = ({ color, label }: { color: string; label: string }) => (
  <Stack direction="row">
    <Box
      borderRadius={1}
      display="inline-block"
      height={12}
      mr={1}
      sx={{ backgroundColor: color }}
      width={40}
    ></Box>
    <Typography>{label}</Typography>
  </Stack>
)

const AnalysesDiagnostic = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const myDiagnostic = useRecoilValue(myDiagnosticState)
  const {
    options: { countyCode, regionCode },
  } = myDiagnostic

  const onPrint = useCallback(async (chartRef: any, filename: string) => {
    if (chartRef.current === null) {
      return
    }

    const filterOnPrint = (node: HTMLElement) => {
      const exclusionClasses = ['remove-from-print']
      return !exclusionClasses.some((classname) =>
        node.classList?.contains(classname),
      )
    }

    const dataUrl = await toJpeg(chartRef.current, {
      filter: filterOnPrint,
    })

    const link = document.createElement('a')
    link.download = filename
    link.href = dataUrl
    link.click()
  }, [])

  useEffect(() => {
    function handleResize() {
      // Update the state or perform any other actions when the
      // browser is resized
      forceUpdate()
    }

    // Attach the event listener to the window object
    window.addEventListener('resize', handleResize)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Stack flex={1} overflow="auto" p={2} spacing={2}>
      <Stack>
        <BoxLegend
          color={COLOR_REGION}
          label={`Région de mon territoire : ${regionCode.map((o) => o.label)}`}
        />
        <BoxLegend
          color={COLOR_COUNTY}
          label={`Departements de mon territoire : ${countyCode.map(
            (o) => o.label,
          )}`}
        />
        <BoxLegend
          color={COLOR_COUNTRY}
          label="Nationale : La France (Hors DOM-TOM)"
        />
      </Stack>
      <EconomicDynamic onPrint={onPrint} />
      <SocialFragilityDynamic onPrint={onPrint} />
      <DemographicDynamic onPrint={onPrint} />
    </Stack>
  )
}

export default AnalysesDiagnostic
