export const getStylePaintApl = (specialityCode: string) => ({
  'fill-color': [
    'interpolate',
    ['linear'],
    ['get', `apl_${specialityCode}`],
    0,
    '#FFFFFF',
    1,
    '#eae3ec',
    2,
    '#d1c2d6',
    3,
    '#bda7c4',
    4,
    '#a98db2',
    5,
    '#9977a4',
    6,
    '#896195',
    7,
    '#794b87',
    8,
    '#6b387a',
    9,
    '#5c256d',
    10,
    '#4c0f5f',
    11,
    '#410155',
  ],
  'fill-opacity': 0.6,
})
