import { UserStatus } from 'constants/enum'
import { CirclePlayIcon, TrashIcon, XCircleIcon } from 'lucide-react'
import alert from 'themes/colors/alert'
import primary from 'themes/colors/primary'
import { UserAccessType, UserType } from 'types/user'

import {
  TYPE_CONFIRM_DRAWER_DELETE,
  TYPE_CONFIRM_DRAWER_STATUS_DISABLED,
  TYPE_CONFIRM_DRAWER_STATUS_ENABLED,
} from '../../hoc-drawer'

export const getActionsStatus = ({
  currentUser,
  mutateUpdate,
  original,
  resetStateConfirmDrawer,
  setConfirmDrawerState,
}: {
  currentUser: UserAccessType | undefined
  mutateUpdate: any
  original: UserType
  resetStateConfirmDrawer: any
  setConfirmDrawerState: any
}) => {
  if (currentUser?.id === original.id) {
    return []
  }
  if (original.status === UserStatus.DISABLED) {
    return [
      {
        Icon: CirclePlayIcon,
        color: primary[500],
        getLabel: () => 'Activé',
        onClick: (original: UserType) => {
          setConfirmDrawerState({
            onSubmit: async () => {
              await mutateUpdate({
                data: { status: UserStatus.ENABLED },
                id: original.id,
              })
              resetStateConfirmDrawer()
            },
            open: true,
            type: TYPE_CONFIRM_DRAWER_STATUS_ENABLED,
            userName: `${original.firstname} ${original.name}`,
          })
        },
      },
    ]
  }
  if (original.status === UserStatus.ENABLED) {
    return [
      {
        Icon: XCircleIcon,
        color: primary[500],
        getLabel: () => 'Désactivé',
        onClick: (original: UserType) => {
          setConfirmDrawerState({
            onSubmit: async () => {
              await mutateUpdate({
                data: { status: UserStatus.DISABLED },
                id: original.id,
              })
              resetStateConfirmDrawer()
            },
            open: true,
            type: TYPE_CONFIRM_DRAWER_STATUS_DISABLED,
            userName: `${original.firstname} ${original.name}`,
          })
        },
      },
    ]
  }
  return []
}

export const getActionsDelete = ({
  currentUser,
  mutateDelete,
  original,
  resetStateConfirmDrawer,
  setConfirmDrawerState,
}: {
  currentUser: UserAccessType | undefined
  mutateDelete: any
  original: UserType
  resetStateConfirmDrawer: any
  setConfirmDrawerState: any
}) => {
  if (original.role.lock || currentUser?.id === original.id) {
    return []
  }
  return [
    {
      Icon: TrashIcon,
      color: alert[500],
      getLabel: () => 'Supprimer',
      onClick: ({ id }: { id?: number }) =>
        setConfirmDrawerState({
          onSubmit: async () => {
            await mutateDelete({ id })
            resetStateConfirmDrawer()
          },
          open: true,
          type: TYPE_CONFIRM_DRAWER_DELETE,
          userName: `${original.firstname} ${original.name}`,
        }),
    },
  ]
}
