import type { StatusProjectType } from 'types/enums'

import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { selectOptions } from 'components-ui/SelectMultiple/type'
import { getIfAreaSelected } from 'pages/Core/helper'
import * as React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authService from 'services/auth.service'
import { useAxios } from 'services/axios.service'
import { myProjectType } from 'types/recoils/projects'

import type { currentStateType } from './type'

import Map from '../Map'
import { myProjectState } from '../atoms'
import Aside from './Aside'
import Header from './Header'
import Overview from './Overview'
import SelectionArea from './SelectionArea'
import SelectionFocus from './SelectionFocus'
import SelectionParty from './SelectionParty'
import SelectionSpeciality from './SelectionSpeciality'
import Summary from './Summary'
import {
  STATE_FOCUS,
  STATE_OVERVIEW,
  STATE_PARTY,
  STATE_SPECIALITY,
  drawerWidth,
} from './constants'
import { STATE_AREA } from './constants'
import { DrawerStyled } from './styles'

const stateWithDisplayMap = [
  STATE_AREA,
  STATE_SPECIALITY,
  STATE_FOCUS,
  STATE_PARTY,
]

const buildQueryData = (
  myProject: myProjectType,
  userId: number | undefined,
  status: StatusProjectType,
) => {
  const { options, party, speciality } = myProject
  const parties = [
    ...party.organization.map((opt: selectOptions) => opt.value),
    ...party.person.map((opt: selectOptions) => opt.value),
  ]

  return {
    cities: options.cityCode.map((opt: selectOptions) => opt.value),
    counties: options.countyCode.map((opt: selectOptions) => opt.value),
    cpts: options.cptsCode.map((opt: selectOptions) => opt.value),
    dac: options.dacCode.map((opt: selectOptions) => opt.value),
    epci: options.epciCode.map((opt: selectOptions) => opt.value),
    name: myProject.name,
    parties,
    regions: options.regionCode.map((opt: selectOptions) => opt.value),
    specialityCode: speciality.value,
    status,
    tvs: options.tvsCode.map((opt: selectOptions) => opt.value),
    userId,
  }
}

const InProgressProject = () => {
  const user = authService.getCurrentUser()
  const [currentState, setCurrentState] =
    React.useState<currentStateType>(STATE_AREA)
  const setMyProject = useSetRecoilState(myProjectState)
  const myProject = useRecoilValue(myProjectState)

  const { refresh } = useAxios({
    noFetchInit: true,
  })

  const ifAreaSelected = getIfAreaSelected(myProject.options)

  const submitProject = async (statusProject: StatusProjectType) => {
    const data = buildQueryData(myProject, user?.id, statusProject)
    const savedProject = await refresh({
      data,
      method: !myProject.id ? 'POST' : 'PATCH',
      url: !myProject.id ? '/projects' : `/projects/${myProject.id}`,
    })

    await setMyProject((current) => ({
      ...current,
      id: savedProject?.response?.id,
    }))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Box
        component="nav"
        sx={{
          flexShrink: { sm: 0 },
          position: 'absolute',
          width: { sm: drawerWidth },
        }}
      >
        <DrawerStyled
          open
          sx={{ display: { sm: 'block', xs: 'none' } }}
          variant="permanent"
        >
          <Aside currentState={currentState} />
        </DrawerStyled>
      </Box>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          maxWidth: {
            sm: `calc(100vw - ${drawerWidth}px)`,
          },
          width: {
            sm: `calc(100vw - ${drawerWidth}px)`,
          },
        }}
      >
        <Header
          currentState={currentState}
          setCurrentState={setCurrentState}
          submitProject={submitProject}
        />
        <Stack direction="row" flexGrow={1}>
          {stateWithDisplayMap.includes(currentState) && (
            <>
              <Box maxWidth="400px" minWidth="400px">
                {currentState === STATE_AREA && <SelectionArea />}
                {currentState === STATE_SPECIALITY && <SelectionSpeciality />}
                {currentState === STATE_FOCUS && <SelectionFocus />}
                {currentState === STATE_PARTY && <SelectionParty />}
                <Summary
                  currentState={currentState}
                  setCurrentState={setCurrentState}
                />
              </Box>
              <Map
                currentState={currentState}
                ifAreaSelected={ifAreaSelected}
              />
            </>
          )}
          {currentState === STATE_OVERVIEW && <Overview />}
        </Stack>
      </Box>
    </Box>
  )
}
export default InProgressProject
