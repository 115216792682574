import { Typography } from '@mui/material'
import { ReactComponent as ActivateImage } from 'assets/images/activate.svg'
import { ReactComponent as CancelImage } from 'assets/images/cancel.svg'
import { ReactComponent as DeleteImage } from 'assets/images/delete.svg'
import { ReactComponent as MailImage } from 'assets/images/mail.svg'
import ConfirmDrawer from 'components/ConfirmDrawer'
import React, { ElementType } from 'react'

export const TYPE_CONFIRM_DRAWER_DELETE = 'DELETE'
export const TYPE_CONFIRM_DRAWER_STATUS_ENABLED = 'STATUS_ENABLED'
export const TYPE_CONFIRM_DRAWER_STATUS_DISABLED = 'STATUS_DISABLED'
export const TYPE_CONFIRM_DRAWER_CREATED = 'CREATED'
export const TYPE_CONFIRM_DRAWER_SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD'

const initialStateConfirmDrawer = {
  email: '' as string | string[],
  onSubmit: null as any,
  open: false,
  type: '',
  userName: '' as string | string[],
}

const withDrawer = (BaseComponent: ElementType) => {
  return function EnhancedComponent(props: any) {
    const [confirmDrawerState, setConfirmDrawerState] = React.useState(
      initialStateConfirmDrawer,
    )
    const resetStateConfirmDrawer = () =>
      setConfirmDrawerState(initialStateConfirmDrawer)

    return (
      <>
        <BaseComponent
          {...props}
          resetStateConfirmDrawer={resetStateConfirmDrawer}
          setConfirmDrawerState={setConfirmDrawerState}
        />
        <ConfirmDrawer
          modeTwoButtons
          bodySecondaryText="Cette action est irréversible !"
          Image={<DeleteImage />}
          titleText="Supprimer utilisateur"
          onSubmit={confirmDrawerState.onSubmit}
          onSubmitColor="error"
          onSubmitText="Supprimer"
          handleCloseOpenDrawer={resetStateConfirmDrawer}
          bodyPrimaryText={
            Array.isArray(confirmDrawerState.userName) ? (
              <>
                <Typography>
                  Les utilisateurs suivant seront supprimés :
                </Typography>
                <Typography>
                  <b>{confirmDrawerState.userName.join(', ')}</b>
                </Typography>
              </>
            ) : (
              <Typography>
                L'utilisateur <b>{confirmDrawerState.userName}</b> sera supprimé
              </Typography>
            )
          }
          isOpenDrawer={
            confirmDrawerState.open &&
            confirmDrawerState.type === TYPE_CONFIRM_DRAWER_DELETE
          }
        />
        {/* Enabled */}
        <ConfirmDrawer
          modeTwoButtons
          bodySecondaryText="Voulez-vous continuer ?"
          Image={<ActivateImage />}
          titleText="Activer utilisateur"
          onSubmit={confirmDrawerState.onSubmit}
          onSubmitColor="primary"
          onSubmitText="Activer"
          handleCloseOpenDrawer={resetStateConfirmDrawer}
          bodyPrimaryText={
            <Typography>
              L'utilisateur <b>{confirmDrawerState.userName}</b> sera activé
            </Typography>
          }
          isOpenDrawer={
            confirmDrawerState.open &&
            confirmDrawerState.type === TYPE_CONFIRM_DRAWER_STATUS_ENABLED
          }
        />
        {/* Disabled */}
        <ConfirmDrawer
          modeTwoButtons
          bodySecondaryText="Voulez-vous continuer ?"
          Image={<CancelImage />}
          titleText="Désactiver utilisateur"
          onSubmit={confirmDrawerState.onSubmit}
          onSubmitColor="primary"
          onSubmitText="Désactiver"
          handleCloseOpenDrawer={resetStateConfirmDrawer}
          bodyPrimaryText={
            <Typography>
              L'utilisateur <b>{confirmDrawerState.userName}</b> sera désactivé
            </Typography>
          }
          isOpenDrawer={
            confirmDrawerState.open &&
            confirmDrawerState.type === TYPE_CONFIRM_DRAWER_STATUS_DISABLED
          }
        />
        {/* Created */}
        <ConfirmDrawer
          bodyPrimaryText="Félicitaion"
          bodySecondaryText={`Un lien de création de mot de passe a été envoyer à l’adresse mail de votre utilisateur : ${confirmDrawerState.email}`}
          Image={<MailImage />}
          titleText="Utilisateur créé"
          onSubmit={confirmDrawerState.onSubmit}
          onSubmitColor="primary"
          onSubmitText="J'ai compris"
          handleCloseOpenDrawer={resetStateConfirmDrawer}
          isOpenDrawer={
            confirmDrawerState.open &&
            confirmDrawerState.type === TYPE_CONFIRM_DRAWER_CREATED
          }
        />
        {/* Reset password */}
        <ConfirmDrawer
          bodyPrimaryText="Un lien de réinitialisation de mot de passe a été envoyer à l’adresse mail de votre utilisateur :"
          bodySecondaryText={confirmDrawerState?.email?.toString()}
          Image={<MailImage />}
          titleText="Changer de mot de passe"
          onSubmit={confirmDrawerState.onSubmit}
          onSubmitColor="primary"
          onSubmitText="J'ai compris"
          handleCloseOpenDrawer={resetStateConfirmDrawer}
          isOpenDrawer={
            confirmDrawerState.open &&
            confirmDrawerState.type === TYPE_CONFIRM_DRAWER_SEND_RESET_PASSWORD
          }
        />
      </>
    )
  }
}

export default withDrawer
