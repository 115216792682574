export const LABEL_CATEGORY_ORGANIZATION = 'Catégorie d’établissement'
export const LABEL_CATEGORY_PERSON = 'Profession'
export const LABEL_JOURNEY = 'Parcours'
export const LABEL_TYPE_EXPERTISE = 'Type de savoir faire'
export const LABEL_EXPERTISE = 'Savoir faire'
export const LABEL_SPECIALITY = 'Spécialité'
export const LABEL_REGION = 'Région'
export const LABEL_COUNTY = 'Département'
export const LABEL_CITY = 'Commune'
export const LABEL_COUNTRY = 'Toute la France'
export const LABEL_CPTS = 'CPTS'
export const LABEL_DAC = 'DAC'
export const LABEL_TVS = 'TVS'
export const LABEL_EPCI = 'EPCI'

export const LABEL_USER_STATUS_DISABLED = 'Désactivé'
export const LABEL_USER_STATUS_ENABLED = 'Activé'
export const LABEL_USER_STATUS_WAIT_PASSWORD = 'Mot de passe en création'
