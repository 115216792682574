/* eslint-disable indent */
import { Divider, Grid, Stack, Typography } from '@mui/material'
import { ReactComponent as SearchNoResultIcon } from 'assets/images/searchNoResult.svg'
import CreateDrawerProject from 'components/CreateDrawerProject'
import { CONST_PROJECT_STATUS_PUBLISHED } from 'constants/enum'
import { heightHeader } from 'constants/ui'
import { type MRT_PaginationState } from 'material-react-table'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { useAxios } from 'services/axios.service'
import permissionsService, {
  ALLOWED_ACTION_CREATE_PROJECT,
} from 'services/permissions.service'

import { defaultMyProjectState, myProjectState } from '../atoms'
import Header from './Header'
import TableList from './Table'

const pageSize = 50

const buildQuery = (page: number, search?: string) => {
  const where = {
    ...(search
      ? {
          name: {
            contains: search,
            mode: 'insensitive',
          },
        }
      : {}),
    status: CONST_PROJECT_STATUS_PUBLISHED,
  }

  return {
    url: `/projects?${new URLSearchParams({
      crudQuery: JSON.stringify({
        joins: [
          'parties',
          'speciality',
          'user',
          'regions',
          'counties',
          'cities',
          'cpts',
          'dac',
          'tvs',
          'epci',
        ],
        page,
        pageSize,
        where,
      }),
    })}`,
  }
}

const ListProject = () => {
  const setMyProject = useSetRecoilState(myProjectState)
  const navigate = useNavigate()
  const [pageNumber, setPageNumber] = React.useState(0)
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false)

  const allowedCreate = permissionsService.getPermission(
    ALLOWED_ACTION_CREATE_PROJECT,
  )

  const { loading, refresh, response } = useAxios(buildQuery(pageNumber + 1))

  const onPaginationChange = (pagination: MRT_PaginationState) => {
    setPageNumber(pagination.pageIndex)
    refresh(buildQuery(pagination.pageIndex + 1))
  }

  const openDrawer = (open: boolean) => setIsOpenDrawer(open)

  const onSubmitCreateProject = ({ name }: { name: string }) => {
    setMyProject(() => ({ ...defaultMyProjectState, name }))
    navigate('/projects/create')
    return
  }

  const onSubmitFetch = ({ search }: { search: string }) => {
    const resetPageNumber = 0
    setPageNumber(resetPageNumber)
    refresh(buildQuery(resetPageNumber + 1, search))
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header
            heightHeader={heightHeader}
            openCreateProjectModal={() => openDrawer(true)}
            onSubmitDisabled={!allowedCreate}
            onSubmitFetch={onSubmitFetch}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container p={2}>
        <Grid item xs={12}>
          <Typography fontWeight={700}>
            {response?.totalRecords || 0} Projets
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        {(loading || response?.totalRecords > 0) && (
          <TableList
            data={response?.data}
            loading={loading}
            pageSize={pageSize}
            rowCount={response?.totalRecords}
            onPaginationChange={onPaginationChange}
          />
        )}
        {response?.totalRecords === 0 && (
          <Stack margin="30px auto">
            <SearchNoResultIcon height={100} width={100} />
            <Typography>Pas de projets trouvés</Typography>
          </Stack>
        )}
      </Grid>
      {allowedCreate && (
        <CreateDrawerProject
          isOpenDrawer={isOpenDrawer}
          titleText="Nouveau Projet"
          onSubmit={onSubmitCreateProject}
          onSubmitText="Commencer"
          handleCloseOpenDrawer={() => openDrawer(false)}
        />
      )}
    </>
  )
}

export default ListProject
